import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Collapse, Form, Input } from 'antd';
import { t } from 'i18n';
import { getCookie } from 'pkg/cookie/helpers';
import React, { useEffect } from 'react';

const AddBlockApp = (props: any) => {
  useEffect(() => {
    onReset();
  }, []);
  const getAuthor = () => {
    return getCookie('author_user');
  };
  const [form] = Form.useForm();
  const { Panel } = Collapse;
  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };
  const tailLayout = {
    wrapperCol: { offset: 0, span: 16 },
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const onReset = () => {
    form.resetFields();
  };

  return (
    <>
      <Collapse
        style={{
          border: '2px solid green',
          maxWidth: '500px',
          marginTop: '20px',
        }}
        defaultActiveKey={'0'}
        accordion
        expandIcon={({ isActive }) => (
          <PlusCircleOutlined
            style={{ fontSize: '120%', color: 'green' }}
            rotate={isActive ? 130 : 0}
          />
        )}
      >
        <Panel header={t('userManagement.blockApp.create.header')} key="1">
          <Form
            form={form}
            {...layout}
            name="basic"
            initialValues={{ remember: true }}
            onFinish={props.AddBlacklistGroup}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label={t('userManagement.blockApp.create.name')}
              name="name"
              className="staff-item"
              rules={[
                { required: true, message: t('notification.input.name') },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item className="staff-item" {...tailLayout}>
              <Button type="primary" htmlType="submit">
                {t('userManagement.blockApp.create.btnCreate')}
              </Button>
            </Form.Item>
          </Form>
        </Panel>
      </Collapse>
    </>
  );
};

export default AddBlockApp;
