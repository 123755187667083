import { Spin } from 'antd';
import ChartContent from 'modules/Agent/ChartContent';
import { promotionClient } from 'pkg/request/clients';
import React, { useEffect, useState } from 'react';

const ChartTimeSite = ({
  handleToDay,
  getTimeZone,
  getAdminId,
  userId,
  dateSelected,
}: any) => {
  const [dataSourceChart, setDataSourceChart] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getApiChart(userId, dateSelected);
  }, [userId, dateSelected]);

  const formatChart = () => {
    const result = dataSourceChart.map((item: any) => {
      item.sales = +item.usage_time;
      item.type = item.app_name;
      return item;
    });
    return result;
  };

  const getApiChart = (user_id: string, dateSelected: number = 0) => {
    setLoading(true);
    promotionClient
      .post(`/app_usage/user_chart`, {
        admin_id: getAdminId(),
        user_id,
        sort: 'desc',
        date: handleToDay(dateSelected),
        top: 50,
        timezone: getTimeZone(),
        page: 1,
      })
      .then((res: any) => {
        setDataSourceChart(res.data.apps);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Spin spinning={loading} tip="Loading...">
      {!!dataSourceChart.length ? (
        <ChartContent data={formatChart()} />
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center' }}>No data</div>
      )}
    </Spin>
  );
};

export default ChartTimeSite;
