import { getCookie } from 'pkg/cookie/helpers';
import { promotionClient } from 'pkg/request/clients';
import React, { useEffect, useState } from 'react';
import { Button, notification, Popconfirm, Switch, Table } from 'antd';
import { t } from 'i18n';
import { DeleteOutlined } from '@ant-design/icons';
import { isDesktop } from 'react-device-detect';

const Test = (props: any) => {
  const [dataSourceAdmin, setDataSourceAdmin] = useState([]);
  useEffect(() => {
    getListStaffOfAgent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.count]);

  const getAdminId = () => {
    return getCookie('current_username');
  };

  const getListStaffOfAgent = () => {
    promotionClient
      .post('staff_agent/admin/list', {
        admin_id: getAdminId(),
        token: '',
      })
      .then((res: any) => {
        setDataSourceAdmin(res.data.staff_agent);
      });
  };

  const handleActiveStaffByAgent = (value: any, myStaff: any) => {
    promotionClient
      .post('staff_agent/admin/enable', {
        admin_id: getAdminId(),
        agent_id: myStaff.agent_id,
        staff_id: myStaff.staff_id,
        enable: !myStaff.enable,
        token: '',
      })
      .then((res: any) => {
        if (res.data.success) {
          notification.success({
            message: t('Success'),
            description: !myStaff.enable
              ? t('notification.activeStaff.success')
              : t('notification.InactiveStaff.success'),
          });
          getListStaffOfAgent();
        } else {
          notification.error({
            message: t('Error'),
            description: res.message,
          });
        }
      })
      .catch((err: any) => {
        notification.error({
          message: t('Error'),
          description: err || 'error',
        });
      });
  };
  const handleDeleteStaffByAgent = (value: any) => {
    promotionClient
      .post('/staff_agent/admin/delete', {
        admin_id: getAdminId(),
        staff_id: value.staff_id,
        agent_id: value.agent_id,
        token: 'string',
      })
      .then((res: any) => {
        if (res.data.success) {
          notification.success({
            message: t('Success'),
            description: t('notification.staff.delete.success'),
          });
          getListStaffOfAgent();
        } else {
          notification.error({
            message: t('Error'),
            description: res.message,
          });
        }
      })
      .catch((err: any) => {
        notification.error({
          message: t('Error'),
          description: err || 'error',
        });
      });
  };

  const handleLogin = (value: any, staff: any) => {
    console.log(staff);
    promotionClient
      .post('/staff_agent/admin/active', {
        admin_id: getAdminId(),
        staff_id: staff.staff_id,
        agent_id: staff.agent_id,
      })
      .then((res: any) => {
        if (res.data.success) {
          notification.success({
            message: t('Success'),
            description: t('notification.staff.login.success', {
              user: staff.staff_name,
            }),
          });
          getListStaffOfAgent();
        } else {
          notification.error({
            message: t('Error'),
            description: res.data.message,
          });
        }
      })
      .catch((err: any) => {
        notification.error({
          message: t('Error'),
          description: 'error',
        });
      });
  };

  const columns3 = [
    {
      title: t('AddUserToAgent_stt'),
      dataIndex: 'id',
      key: 'id',
      width: 45,
      render: (value: any, staff: any, index: any) => <span>{index + 1}</span>,
    },
    {
      title: t('AddUserToAgent_user'),
      dataIndex: 'staff_name',
      key: 'staff_name',
      // with: '40%',
      render: (value: any, staff: any, index: any) => (
        <div className="listStaffByAgent-agent">
          {value ? value : staff.staff_id}{' '}
        </div>
      ),
      sorter: (a: any, b: any) => a.staff_name.length - b.staff_name.length,
    },
    {
      title: t('AddUserToAgent_agentName'),
      dataIndex: 'agent_name',
      key: 'agent_name',
      with: 300,
      sorter: (a: any, b: any) => a.agent_name.length - b.agent_name.length,
      render: (value: any, staff: any, index: any) => (
        <div className="listStaffByAgent">
          <div className="listStaffByAgent-agent">
            {value ? value : staff.agent_id}{' '}
          </div>
          {/*<div className='listStaffByAgent-listStaff'>- {staff.staff_id}</div>*/}
        </div>
      ),
    },

    // {
    //   title: 'Active',
    //   dataIndex: 'enable',
    //   key: 'enable',
    //   render: (value: any, staff: any) => <Switch
    //     onChange={() => handleActiveStaffByAgent(value, staff)} defaultChecked={staff.enable} />
    // },
    {
      title: t('AddUserToAgent_login'),
      dataIndex: 'login',
      key: 'login',
      render: (value: any, staff: any) => (
        <Button onClick={() => handleLogin(value, staff)} type={'primary'}>
          {t('AddUserToAgent_btnlogin')}
        </Button>
      ),
    },
    {
      title: t('AddUserToAgent_delete'),
      dataIndex: 'delete',
      key: 'delete',
      render: (item: any, value: any) => (
        <Popconfirm
          placement="topRight"
          title={t('notification.staff.delete.title')}
          onConfirm={() => handleDeleteStaffByAgent(value)}
          okText={t('notification.delete.yes')}
          cancelText={t('notification.delete.no')}
        >
          {/* {isDesktop ? "haha": <Button type={'primary'} danger> Delete</Button >} */}
          {isDesktop ? (
            <Button danger> {t('AddUserToAgent_delete')}</Button>
          ) : (
            <DeleteOutlined style={{ color: 'red', fontSize: 25 }} />
          )}
        </Popconfirm>
      ),
    },
  ];

  return (
    <>
      <div className="table-staff">
        <Table
          bordered
          pagination={{ defaultPageSize: 10, hideOnSinglePage: true }}
          scroll={{ x: 300 }}
          dataSource={dataSourceAdmin}
          columns={columns3}
        />
      </div>
    </>
  );
};
export default Test;
