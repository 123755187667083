import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18n';
import { ConfigProvider } from 'antd';
import App from './App';
import EmptyBlock from 'modules/base/EmptyBlock';
import * as serviceWorker from './serviceWorker';
import { REGIONS } from 'pkg/localization/constants';
import { getCurrentLanguage } from 'pkg/localization/helpers';

// The initial phase is finish.
// Now you can do your logic.
ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <ConfigProvider
      locale={REGIONS[getCurrentLanguage()].antdLocale}
      renderEmpty={() => <EmptyBlock />}
    >
      <App />
    </ConfigProvider>
  </I18nextProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
