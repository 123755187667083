import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  InfoCircleTwoTone,
  SmileOutlined,
  WarningTwoTone,
} from '@ant-design/icons';
import { Col, notification, Result, Row } from 'antd';
import axios from 'axios';
import { t } from 'i18n';
import {
  AUTHOR_USER,
  KEY_ACCESS_TOKEN,
  KEY_REFRESH_TOKEN,
} from 'pkg/cookie/constants';
import { getCookie, setCookie } from 'pkg/cookie/helpers';
import { promotionClient } from 'pkg/request/clients';
import React, { useEffect, useState } from 'react';
import { formatMoney } from '../Controller';
import useQuery from '../hook/useQueryParams';

interface Params {
  txn_ref: string;
  status_code: string;
  amount: string;
  order_id: string;
  timestamp: string;
  checksum: string;
}

const StatusWait = [10];
const StatusSuccess = [11, 25, 26];
const StatusSuccessNotification = [12, 13];
const StatusErrorPay = [14, 16, 19, 20];
const StatusErrorSystem = [30, 31, 32];

const ResultCkPay = () => {
  const query = useQuery();
  const [data, setData] = useState<any>(null);
  const [status, setStatus] = useState<any>(null);
  const getAuthor = () => {
    return getCookie('author_user');
  };
  const getAdminId = () => {
    return getCookie('current_username');
  };
  useEffect(() => {
    if (query.get('txn_ref')) {
      const data = {
        admin_id: getAdminId(),
        txn_ref: query.get('txn_ref'),
        status_code: query.get('status_code'),
        amount: query.get('amount'),
        order_id: query.get('order_id'),
        timestamp: query.get('timestamp'),
        checksum: query.get('checksum'),
      };
      promotionClient
        .post(`/order/admin/get`, data)
        .then((res: any) => {
          if (res.data.success) {
            notification.success({
              message: t('Success'),
              description: 'Order success',
            });
            setData(res.data.status);
            if (StatusWait.includes(res.data.status.status_code)) {
              setStatus(<SmileOutlined />);
            } else if (StatusSuccess.includes(res.data.status.status_code)) {
              setStatus(<CheckCircleTwoTone twoToneColor="#52c41a" />);
            } else if (
              StatusSuccessNotification.includes(res.data.status.status_code)
            ) {
              setStatus(<InfoCircleTwoTone twoToneColor="#52c41a" />);
            } else if (StatusErrorPay.includes(res.data.status.status_code)) {
              setStatus(<WarningTwoTone twoToneColor="red" />);
            } else if (
              StatusErrorSystem.includes(res.data.status.status_code)
            ) {
              setStatus(<CloseCircleTwoTone twoToneColor="red" />);
            }
          } else {
            notification.error({
              message: t('Error'),
              description: res.data.message,
            });
          }
        })
        .catch((err: any) => {
          notification.error(err);
        });
    }
  }, []);
  const getAdminID = () => {
    return getCookie('current_username');
  };
  useEffect(() => {
    const refreshToken = getCookie(KEY_REFRESH_TOKEN);
    if (data && [11, 13, 12, 25].includes(data.status_code)) {
      promotionClient
        .post(`/user/get`, { user_name: getAdminId() })
        .then((res: any) => {
          if (res.data.success) {
            if (
              res.data.user.admin_role === 'standard' &&
              getAuthor() === 'free'
            ) {
              setCookie(AUTHOR_USER, 'standard');
              axios
                .get('/auth/refresh', {
                  headers: {
                    Authorization: `${refreshToken},${getAdminID()}`,
                    ContentType: 'application/json',
                  },
                  baseURL: `${process.env.REACT_APP_PROMOTION_DOMAIN}/api/v1`,
                })
                .then((res: any) => {
                  if (res.data) {
                    const { access_token, refresh_token } = res.data;
                    setCookie(KEY_ACCESS_TOKEN, access_token);
                    setCookie(KEY_REFRESH_TOKEN, refresh_token);
                  }
                });
            }
          } else {
            notification.error({
              message: t('Error'),
              description: res.data.message,
            });
          }
        })
        .catch((err: any) => {
          notification.error(err);
        });
    }
  }, [data]);

  if (!data) return null;
  return (
    <Row>
      <Col span={12} offset={6}>
        <Result icon={status} title={t(data.status_code)}>
          <Row justify="space-between">
            <Col span={12}>Payment type</Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              {data.product.description}
            </Col>
            {/* <Col span={12}>Bank</Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              12345678
            </Col> */}
            <Col span={12}>Amount Paid</Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              {formatMoney(+data.amount)}
            </Col>
            <Col span={12}>Transaction id</Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              {data.txn_ref}
            </Col>
            <Col span={12}>Transaction date</Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              {data.bank_date}
            </Col>
          </Row>
        </Result>
      </Col>
    </Row>
  );
};

export default ResultCkPay;
