import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Collapse, Form, Input, Alert, Steps, Row, Col } from 'antd';
import { t } from 'i18n';
import React from 'react';

const AddBlacklistNew = (props: any) => {
  const { Panel } = Collapse;
  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  const tailLayout = {
    wrapperCol: { offset: 0, span: 16 },
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };
  const [form] = Form.useForm();

  return (
    <>
      <div style={{ maxWidth: '500px' }} className={'mt-3'}>
        {/* <Alert message="Vui lòng nâng cấp gói bạn đang sử dụng" type="success" /> */}
        <Collapse
          style={{ border: '2px solid green' }}
          defaultActiveKey={'0'}
          accordion
          expandIcon={({ isActive }) => (
            <PlusCircleOutlined
              style={{ fontSize: '120%', color: 'green' }}
              rotate={isActive ? 130 : 0}
            />
          )}
        >
          <Panel header={t('userManagement.blackList.create.header')} key="1">
            <Form
              form={form}
              {...layout}
              name="basic"
              initialValues={{ remember: true }}
              onFinish={props.AddBlacklistGroup}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                label={t('userManagement.blackList.create.name')}
                name="name"
                className="staff-item"
                rules={[
                  { required: true, message: t('notification.input.name') },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item className="staff-item" {...tailLayout}>
                <Button type="primary" htmlType="submit">
                  {t('userManagement.blackList.create.btnCreate')}
                </Button>
              </Form.Item>
            </Form>
          </Panel>
        </Collapse>
      </div>
    </>
  );
};

export default AddBlacklistNew;
