import { useState } from 'react';
import { isLoggedIn } from 'pkg/user/repo';
import { login } from './repo';
import { PATH } from 'routes/constants';
import { browserHistory } from 'pkg/route/helper';
import { promotionClient } from 'pkg/request/clients';

export function useLogin() {
  if (isLoggedIn()) {
    const queryParams = new URLSearchParams(window.location.search);
    const url = queryParams.get('redirect');
    if (url) {
      window.location.href = url;
    } else {
      browserHistory.push(PATH.DASHBOARD_NEW);
    }
  }
  const [isLoading, setLoading] = useState(false);
  const handleLogin = async (value: any) => {
    if (value.username && value.password) {
      try {
        setLoading(true);
        const a = await login(value.username, value.password, value.token);
        if (!a) setLoading(false);
        const res = await promotionClient.post('/license/promo/admin/get', {
          admin_id: value.username,
        });
        if (res.data.success && res.data.promo) {
          if (res.data.type == 'extend_before') {
            browserHistory.push(`${PATH.DASHBOARD_NEW}?type=extend_before`);
          } else if (res.data.type == 'extend_after') {
            browserHistory.push(`${PATH.DASHBOARD_NEW}?type=extend_after`);
          }
        } else if (res.data.success && !res.data.promo) {
          browserHistory.push(`${PATH.DASHBOARD_NEW}?type=children`);
        } else {
          browserHistory.push(`${PATH.DASHBOARD_NEW}`);
        }
        // browserHistory.push(PATH.HOME);
      } finally {
        setLoading(false);
      }
    }
  };
  return {
    isLoading,
    handleLogin,
  };
}
