import React, { useEffect, useState } from 'react';

type Props = {
  dayOfWeek: any;
  weekdaysSelect: any;
  type: any;
  handleChangeSelectDay: (item: any) => void;
  isModalOpen?: boolean;
};

function SelectDayOfWeek({
  dayOfWeek,
  weekdaysSelect,
  type,
  handleChangeSelectDay,
  isModalOpen = false,
}: Props) {
  const [days, setDays] = useState(dayOfWeek);
  useEffect(() => {
    if (type === 'dayOfWeek') {
      setDays(dayOfWeek);
    } else {
      setDays(dayOfWeek.filter((x: any) => !weekdaysSelect.includes(x)));
    }
  }, [type, weekdaysSelect]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: `${type === 'dayOfWeek' ? 'space-between' : ''}`,
      }}
    >
      {days.map((item: any) => (
        <div
          style={{
            background: `${
              weekdaysSelect.includes(item) || type !== 'dayOfWeek'
                ? 'blue'
                : '#fff'
            }`,
            borderRadius: '50%',
            color: `${
              weekdaysSelect.includes(item) || type !== 'dayOfWeek'
                ? '#fff'
                : 'black'
            }`,
            width: '35px',
            height: '35px',
            textAlign: 'center',
            border: '1px solid black',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: `${type === 'dayOfWeek' ? '' : '10px'}`,
          }}
          onClick={() => {
            if (isModalOpen) handleChangeSelectDay(item);
          }}
        >
          {item.substring(0, 3)}
        </div>
      ))}
    </div>
  );
}

export default SelectDayOfWeek;
