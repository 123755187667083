import { EditOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Card } from 'antd';
import { t } from 'i18n';
import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';

interface Data {
  label: string;
  value: string;
  isShow?: boolean;
}

interface Props {
  loading?: boolean;
  data: Data[];
  id?: string;
  icon?: string | React.ReactElement;
  path?: string;
}

const InfoWithAvatar = ({
  loading = false,
  data = [],
  id,
  path,
  icon = <UserOutlined />,
}: Props) => {
  const history = useHistory();
  return (
    <Card
      loading={loading}
      bodyStyle={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
      actions={
        id && path
          ? [
              <EditOutlined
                key="edit"
                onClick={() => history.push(`${path}`)}
              />,
            ]
          : []
      }
    >
      <Avatar size={100} icon={icon} />
      {data.length >= 1 &&
        data.map((item, index) => (
          <Fragment key={index}>
            {(!item.isShow || item.isShow) && (
              <div>{`${t(item.label)} : ${item.value}`}</div>
            )}
          </Fragment>
        ))}
    </Card>
  );
};

export default InfoWithAvatar;
