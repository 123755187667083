export const HTTP_CODE = {
  CODE_200: 200,
  CODE_400: 400,
  CODE_401: 401,
  CODE_403: 403,
  CODE_CONFIRM_EXCEPTION: 409,
};

export const CODE_SUCCESS = 'SUCCESS';
export const CODE_INVALID = 'INVALID';
export const CODE_ERROR = 'ERROR';
export const DEBOUNCE_TIME = 500;
