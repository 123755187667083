
import { PureComponent } from 'react';
import './Loader.scss'
import React from 'react';
class Loader extends PureComponent {
  render() {
    return (
      <div className="fixed_loading">
        {/*<div className="loader" />*/}
        <div id="bars1">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    );
  }
}
export  default Loader;
