import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import {
  AutoComplete,
  Button,
  Collapse,
  Form,
  Input,
  Modal,
  notification,
  Popconfirm,
  Table,
} from 'antd';
import { t } from 'i18n';
import { getCookie } from 'pkg/cookie/helpers';
import { promotionClient } from 'pkg/request/clients';
import React, { useEffect, useRef, useState } from 'react';
import '../Blacklist/Blacklist.scss';
let originData: any[] = [];

function randomIntFromInterval(min: number, max: number) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

const randomListWebsite = () => {
  let numberRamdom = randomIntFromInterval(0, 3);
  const arr = [
    `https://baophapluat.vn/`,
    'https://facebook.com/',
    'https://zalo.com/',
    'https://safeweb.app/',
  ];
  return arr[numberRamdom];
};

for (let i = 0; i < 3; i++) {
  originData.push({
    key: i.toString(),
    name: randomListWebsite(),
  });
}

const EditableCell = (props: any) => {
  const [options, setOptions] = useState<any>([]);
  useEffect(() => {
    if (props.listName && props.listName.length > 0) {
      const o = props.listName.map((item: any) => {
        return { label: item.name, value: item.id };
      });

      setOptions(o);
    }
  }, [props.listName]);
  const [optionsShow, setOptionsShow] = useState(options);

  const handleOnSearch = (searchText: string) => {
    const data = options
      .filter((obj: any) =>
        obj.label.toLowerCase().includes(searchText.toLowerCase())
      )
      .map((item: any, index: number) => {
        const isDuplicate = props.data.some((obj: any, i: any) => {
          return obj.name === item.label;
        });
        return { ...item, isDuplicate };
      });
    setOptionsShow(data);
  };
  return (
    <td {...props}>
      {props.editing ? (
        <Form.Item
          name={props.dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `${t('notification.input.please', {
                name: props.title,
              })}`,
            },
            // {
            //   required: true,
            //   type: "url",
            //   message: `${t('url.incorrect')}`
            // },
          ]}
        >
          <AutoComplete
            options={optionsShow}
            onSelect={props.handleOnSelect}
            onSearch={handleOnSearch}
            placeholder="input here"
          />
        </Form.Item>
      ) : (
        props.children
      )}
    </td>
  );
};

export default function Blcklist(props: any) {
  useEffect(() => {
    // getBlacklistGroup()
  }, []);

  useEffect(() => {
    convertArr();
  }, [props.item]);

  const [form] = Form.useForm();
  const [data, setData] = useState(originData);
  const [editingKey, setEditingKey] = useState('');
  const { Panel } = Collapse;
  const [isModalVisible, setIsModalVisible] = useState(false);

  const isEditing = (record: any) => record.key === editingKey;

  const convertArr = () => {
    let array = [];
    for (let i = 0; i < props.item.list.length; i++) {
      array.push({
        key: i,
        name: props.item.list[i],
      });
    }
    setData(array);
  };

  const edit = (record: any) => {
    form.setFieldsValue({
      name: '',
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
    props.getApi();
  };
  const isAdd = useRef(false);

  const save = async (record: any) => {
    let arrString: any = [];
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => record.key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });

        arrString = newData.map((item: any) => item.name);

        promotionClient
          .post('/app_rule/admin/edit', {
            id: props.item.id,
            list: arrString,
          })
          .then((res: any) => {
            if (res.data.success) {
              props.getApi();
              setData(newData);
              setEditingKey('');
              notification.success({
                message: t('Success'),
                description: isAdd.current
                  ? t('notification.blockApp.addApp.success')
                  : t('notification.blockApp.updateApp.success'),
              });
            } else {
              props.getApi();
              notification.error({
                message: 'Error',
                description: res.data.message,
              });
            }
          })
          .catch((err: any) => {
            notification.error({
              message: t('Error'),
              description: err || 'error',
            });
          })
          .finally(() => {
            isAdd.current = false;
          });
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey('');
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const columns = [
    {
      title: `${t('userManagement.blackList.createWebsite.id')}`,
      dataIndex: 'id',
      key: 'id',
      width: 45,
      render: (value: any, staff: any, index: any) => <span>{index + 1}</span>,
    },
    {
      title: `${t('userManagement.blockApp.createWebsite.name')}`,
      dataIndex: 'name',
      editable: true,
      render: (value: any, staff: any, index: any) => {
        return (
          <>
            <span>{value}</span>
            {staff.isDuplicate && (
              <div style={{ color: 'red' }}>{t('duplicate')}</div>
            )}
          </>
        );
      },
    },
    {
      title: `${t('userManagement.blockApp.createWebsite.action')}`,
      dataIndex: 'operation',
      width: '25%',
      render: (value: any, record: { key: any }) => {
        const editable = isEditing(record);
        return (
          <div>
            {editable ? (
              <span>
                <Button
                  type="primary"
                  ghost
                  onClick={() => save(record)}
                  style={{
                    marginRight: 8,
                    marginBottom: 10,
                  }}
                >
                  {t('userManagement.blockApp.createWebsite.btnSave')}
                </Button>
                <Button
                  onClick={cancel}
                  style={{
                    marginRight: 8,
                    marginBottom: 10,
                  }}
                >
                  {t('userManagement.blockApp.createWebsite.btnCancel')}
                </Button>
                {/* <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                    <a>Cancel</a>
                  </Popconfirm> */}
              </span>
            ) : (
              <Button
                disabled={editingKey !== ''}
                onClick={() => edit(record)}
                style={{
                  marginRight: 8,
                  marginBottom: 10,
                }}
              >
                {t('userManagement.blockApp.createWebsite.btnEdit')}
              </Button>
            )}

            <Button
              disabled={false}
              // style={{ marginLeft: '10px' }}
              onClick={() => handleDelete(record)}
              danger
            >
              {t('userManagement.blockApp.createWebsite.btnDelete')}
            </Button>
          </div>
        );
      },
    },
  ];

  const [listName, setListName] = useState<any>([]);
  useEffect(() => {
    getListName();
  }, []);

  const getListName = () => {
    promotionClient
      .get('/app_info/admin/list_name')
      .then((res: any) => {
        if (res.data) {
          setListName(res.data.list);
        } else {
          notification.error({
            message: t('Error'),
            description: res.data.message,
          });
        }
      })
      .catch((err) => {});
  };

  const currentCol = useRef<any>(null);
  const handleOnSelect = (item: any) => {
    const data = listName.find((name: any) => name.id == item);
    form.setFields([
      {
        name: currentCol.current.dataIndex,
        value: data.name,
      },
    ]);
  };
  const handleOnSearch = (value: any) => {
    form.setFields([
      {
        name: currentCol.current.dataIndex,
        value: value,
      },
    ]);
  };

  const mergedColumns = columns.map((col: any) => {
    if (!col.editable) {
      return col;
    }

    currentCol.current = col;

    return {
      ...col,
      onCell: (record: any) => ({
        record,
        inputType: col.dataIndex === 'time' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        listName,
        handleOnSelect,
        handleOnSearch,
        value: form.getFieldValue(col.dataIndex),
        data,
      }),
    };
  });

  const handleAdd = () => {
    isAdd.current = true;
    const newData = {
      key: data.length + 1 || 1,
      name: ``,
    };
    let arr = [newData, ...data];
    setData(arr);
    edit(newData);
    // notification.success({
    //   message: t('Success'),
    //   description: "Add website successfully",
    // });
  };

  const EditBlackList = (value: any) => {
    promotionClient
      .post('/app_rule/admin/set_name', {
        id: props.item.id,
        name: value.blocklistName,
      })
      .then((res: any) => {
        if (res.data.success) {
          notification.success({
            message: t('Success'),
            description: t('notification.update.success', {
              type: t('blocklist'),
            }),
          });
          props.getApi();
          setIsModalVisible(false);
        } else {
          notification.error({
            message: t('Error'),
            description: res.message,
          });
        }
      })
      .catch((err: any) => {
        notification.error({
          message: t('Error'),
          description: err || 'error',
        });
      });
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const handleDeleteUser = () => {
    promotionClient
      .post('/app_rule/admin/delete', {
        id: props.item.id,
      })
      .then((res: any) => {
        if (res.data.success) {
          notification.success({
            message: t('Success'),
            description: t('notification.delete.success', {
              type: t('blacklist'),
            }),
          });
          props.getApi();
        } else {
          notification.error({
            message: t('Error'),
            description: res.message,
          });
        }
      })
      .catch((err: any) => {
        notification.error({
          message: t('Error'),
          description: err || 'error',
        });
      });
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleDelete = (record: any) => {
    let findArray = data.filter((item) => {
      return item.key !== record.key;
    });
    let updateData = findArray.map((item: any, index) => {
      item.key = index;
      return item;
    });

    let arrString: any = [];
    arrString = updateData.map((item: any) => item.name);

    setData(updateData);

    promotionClient
      .post('/app_rule/admin/edit', {
        id: props.item.id,
        list: arrString,
      })
      .then((res: any) => {
        if (res.data.success) {
          props.getApi();
          notification.success({
            message: t('Success'),
            description: t('notification.blockApp.deleteApp.success'),
          });
        } else {
          notification.error({
            message: 'Error',
            description: res.data.message,
          });
        }
      })
      .catch((err: any) => {
        notification.error({
          message: t('Error'),
          description: err || 'error',
        });
      });
  };

  const mapData: any = (data: any) => {
    const output = data.map((item: any, index: any, arr: any) => {
      const isDuplicate = arr.some(
        (obj: any, i: any) => obj.name === item.name && i !== index
      );
      return { ...item, isDuplicate };
    });

    return output;
  };

  return (
    <>
      <div className={'blacklist-detail-main'}>
        <div className={'blacklist-detail-main-ab'}>
          <Popconfirm
            placement="topRight"
            title={t('notification.delete.title', { type: t('blocklist') })}
            onConfirm={() => handleDeleteUser()}
            okText={t('notification.delete.yes')}
            cancelText={t('notification.delete.no')}
          >
            <DeleteOutlined style={{ color: 'red', fontSize: 25 }} />
          </Popconfirm>
        </div>
        <div className={'blacklist-detail-edit'}>
          <EditOutlined
            onClick={() => showModal()}
            style={{ fontSize: '150%' }}
          />
        </div>
        <Collapse defaultActiveKey={'0'} accordion>
          <Panel header={props.item.name} key="1">
            {/* <AddBlacklistForm AddBlacklist={AddBlacklist} /> */}
            <Form form={form} className={'mt-3'} component={false}>
              <Button
                onClick={handleAdd}
                type="primary"
                style={{
                  marginBottom: 16,
                }}
              >
                {t('userManagement.blockApp.add.list.addApp')}
              </Button>
              <Table
                scroll={{ x: 600 }}
                components={{
                  body: {
                    cell: EditableCell,
                  },
                }}
                bordered
                dataSource={mapData(data)}
                columns={mergedColumns}
                rowClassName="editable-row"
                pagination={{
                  onChange: cancel,
                }}
              />
            </Form>
          </Panel>
        </Collapse>

        <Modal
          title={t('userManagement.blockApp.update.name')}
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={null}
        >
          <Form
            form={form}
            name="basic"
            labelCol={{ span: 9 }}
            wrapperCol={{ span: 15 }}
            initialValues={props.item.name}
            onFinish={(value: any) => EditBlackList(value)}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label={t('userManagement.blockApp.update.label')}
              name="blocklistName"
              rules={[
                {
                  required: true,
                  message: t('notification.input.blacklist.nameEdit', {
                    name: t('blocklist'),
                  }),
                },
              ]}
            >
              <Input value={props.item.name} />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 6, span: 18 }}>
              <Button type="primary" className="mt-1" htmlType="submit">
                {t('userManagement.blockApp.update.btnUpdate')}
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </>
  );
}
