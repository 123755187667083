export const CAMPAIGNS_PATH = {
  LIST: '/campaigns',
  CREATE: '/campaigns/:type/new',
  EDIT: '/campaigns/:id/edit',
  DETAIL: '/campaigns/:id',
};

export const PATH = {
  HOME: '/',
  CHECKOUT: '/checkout',
  NEW_PASSWORD: '/account/resetpass/:id',
  GET_PASSWORD: '/get-password',
  USER_INFORMATION: '/information',
  USER_INFORMATION_EDIT: '/information/:id/edit',
  LOGIN: '/signin',
  AGENTPAGE: '/agentpage/:id/tabs/:tab',
  REGISTER: '/signup',
  VERIFYACCOUNT: '/account/activate/:id',
  CAMPAIGNS: CAMPAIGNS_PATH,
  CAMPAIGN: '/campaigns',
  CAMPAIGN_DETAIL: '/campaigns/:id',
  CAMPAIGN_EDIT: '/campaigns/:id/edit',
  WEBSITE: '/website',
  DASHBOARD: '/Dashboard',
  DASHBOARD_NEW: '/dashboard-new',
  APPLICATION: '/application',
  POLICY: '/policy',
  USER: {
    LIST: '/users',
    PAGE: '/usermanager',
  },
  RPA_PAGE: '/rpamanage',
  BLACKLIST: '/blacklist',
  WHITELIST: '/whitelist',
  BLOCK_APP: '/blockapp',
  MERCHANT_BLACKLIST: '/merchant-blacklist',
  CKPAY_RETURN_URL: '/payment/ckpay/result',
  BILLING: {
    LIST: '/billing',
    DETAIL: '/billing/:id',
  },
  KEYWORDS: {
    LIST: '/keyword',
    DETAIL: '/keyword/:id',
  },
  CHECKOUT_V1_QR_CODE: '/checkout/v1/qrcode',
};
