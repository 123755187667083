import { EditOutlined } from '@ant-design/icons';
import { Card, Col, Divider, notification, Row } from 'antd';
import { t } from 'i18n';
import InfoWithAvatar from 'modules/base/InfoWithAvatar';
import { KEY_CURRENT_USERNAME } from 'pkg/cookie/constants';
import { getCookie } from 'pkg/cookie/helpers';
import { promotionClient } from 'pkg/request/clients';
import { useRouter } from 'pkg/route/hooks';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PATH } from 'routes/constants';

type Params = {
  id: string;
};

const DetailUser = () => {
  const router = useRouter<Params>();
  const { id } = router.matchParams;
  const [loading, setLoading] = useState<boolean>(false);
  const [user, setUser] = useState<any>(null);

  useEffect(() => {
    setLoading(true);
    promotionClient
      .post(`user/get`, { user_name: getCookie(KEY_CURRENT_USERNAME) })
      .then((res: any) => {
        if (res.data) {
          setUser(res.data.user);
        } else {
          notification.error({
            message: t('Error'),
            description: res.data.message,
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  const history = useHistory();

  const tabList = [
    {
      key: 'info',
      tab: (
        <span>
          User detail{' '}
          <EditOutlined
            key="edit"
            onClick={() =>
              history.push(`${PATH.USER_INFORMATION}/${user.id}/edit`)
            }
          />
        </span>
      ),
    },
  ];
  const tabListPassword = [
    {
      key: 'getPassword',
      tab: (
        <span>
          Password{' '}
          <EditOutlined
            key="edit"
            onClick={() => history.push(`get-password`)}
          />
        </span>
      ),
    },
  ];

  const contentList: Record<string, React.ReactNode> = {
    info: (
      <div>
        <Row gutter={[16, 16]}>
          <Col span={6} className="title-detail">
            {t('users.detail.form.sex')}
          </Col>
          <Col span={18}>{user?.sex}</Col>
          <Divider className="diveder" />
          <Col span={6} className="title-detail">
            {t('users.detail.form.fullName')}
          </Col>
          <Col span={18}>{user?.full_name}</Col>
          <Divider className="diveder" />
          <Col span={6} className="title-detail">
            {t('users.detail.form.email')}
          </Col>
          <Col span={18}>{user?.email}</Col>
          <Divider className="diveder" />
          <Col span={6} className="title-detail">
            {t('users.detail.form.phone')}
          </Col>
          <Col span={18}>{user?.phone}</Col>

          <Divider className="diveder" />
          <Col span={6} className="title-detail">
            {t('users.detail.form.country')}
          </Col>
          <Col span={18}>{user?.country}</Col>
          <Divider className="diveder" />
          <Col span={6} className="title-detail">
            {t('users.detail.form.timezone')}
          </Col>
          <Col span={18}>{user?.time_zone}</Col>
        </Row>
      </div>
    ),
    getPassword: (
      <div>
        <Row gutter={[16, 16]}></Row>
      </div>
    ),
  };

  return (
    <div className="detail-user">
      <Row gutter={[16, 16]}>
        <Col xs={24} md={7}>
          {user && (
            <InfoWithAvatar
              data={[
                { label: 'users.detail.adminRole', value: user.user_name },
                { label: 'users.detail.type', value: user.admin_role },
                {
                  label: 'users.detail.exp',
                  value: user.exp_date,
                  isShow: user.admin_role === 'standard',
                },
              ]}
              loading={loading}
            />
          )}
        </Col>
        <Col xs={24} md={17}>
          <Card
            loading={loading}
            style={{ width: '100%', marginBottom: 16 }}
            tabList={tabListPassword}
            activeTabKey={'getPassword'}
          >
            {contentList['getPassword']}
          </Card>

          <Card
            loading={loading}
            style={{ width: '100%' }}
            tabList={tabList}
            activeTabKey={'info'}
          >
            {contentList['info']}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default DetailUser;
