import React from 'react';
import { Link } from 'react-router-dom';
import { Result, Button } from 'antd';
import { PATH } from 'routes/constants';
import { t } from 'i18n';

const Page404: React.FC = () => {
  return (
    <Result
      className="app-result-page"
      status="404"
      title="404"
      subTitle={t('PageNotFound')}
      extra={
        <Link to={PATH.HOME}>
          <Button type="primary">{t('BackToHome')}</Button>
        </Link>
      }
    />
  );
};

export default Page404;
