import { getTimezoneProps, SelectTimezone } from '@capaj/react-select-timezone';
import { Button, Form, Input, notification } from 'antd';
import { SECRET_PASSWORD } from 'config/contants';
import CryptoJS from 'crypto-js';
import { t } from 'i18n';
import momentTimezone from 'moment-timezone';
import { promotionClient } from 'pkg/request/clients';
import React, { useEffect, useRef, useState } from 'react';
import { CountryDropdown } from 'react-country-region-selector';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

type Props = {
  setIsConfirmText: (isConfirmText: boolean) => void;
};
const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const tailLayout = {
  wrapperCol: { offset: 0, span: 16 },
};

const FormRegister = ({ setIsConfirmText }: Props) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [isLoadingUsername, setIsLoadingUsername] = useState(false);
  const usernameFocus: any = useRef(undefined);

  let country = '';
  let time_zone = '';
  const selectCountry = (val: string) => {
    country = val;
  };
  const formRef = useRef<any>();

  useEffect(() => {
    if (usernameFocus.current) {
      usernameFocus.current.focus();
    }
  }, []);

  useEffect(() => {
    let result = localStorage.getItem('isConfirmText');
    if (result && result === 'success') {
      setIsConfirmText(true);
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      localStorage.removeItem('isConfirmText');
    }, 600000);
  }, []);

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const checkGmail = (value: any) => {
    let result = value;
    if (/@gmail\.com$/.test(result)) {
      const userName = result.split('@');
      const removeDot = userName[0].replaceAll('.', '');
      result = removeDot + '@' + userName[1];
    }
    return result.toLowerCase();
  };

  const addAccount = async (value: any) => {
    setIsLoadingUsername(true);
    if (!executeRecaptcha) {
      notification.error({
        message: t('Error'),
        description: 'Token in valid',
      });
      setIsLoadingUsername(false);
      return;
    }
    const token = await executeRecaptcha('Register');

    const { userName, email, fullName, country } = value;
    let encrypted = CryptoJS.AES.encrypt(value.password, SECRET_PASSWORD);
    let password: any = encrypted.toString();
    value.email = checkGmail(email);
    if (token) {
      promotionClient
        .post(`/user/signup`, {
          userName,
          email: value.email,
          country,
          time_zone,
          fullName: '',
          password,
          token,
        })
        .then((response: any) => {
          setIsLoadingUsername(false);
          if (response.data.success) {
            setIsConfirmText(true);
            localStorage.setItem('isConfirmText', 'success');
          } else {
            notification.error({
              message: t('Error'),
              description: response.data.message,
            });
          }
        })
        .catch((err) => {
          setIsLoadingUsername(false);
          notification.error({
            message: t('Error'),
            description: err,
          });
        });
    }
    setIsLoadingUsername(false);
  };

  let timeout: any = null;
  const handleChangeName = (rule: any, value: any, callback: any) => {
    clearTimeout(timeout);

    timeout = setTimeout(() => {
      callApiUserName(formRef.current.getFieldValue('userName'), callback);
    }, 500);
  };

  const callApiUserName = (value: any, cb: any) => {
    // setIsLoadingUsername(true);
    promotionClient
      .post(`/user/testname`, {
        userName: value,
        token: '',
      })
      .then((response: any) => {
        if (response.data.success) {
          cb('Tài khoản đã tồn tại');
        } else {
          cb();
        }
      })
      .catch((err) => {
        notification.error({
          message: t('Error'),
          description: err,
        });
      })
      .finally(() => {
        // setIsLoadingUsername(false);
      });
  };
  return (
    <Form
      {...layout}
      name="basic"
      initialValues={{ remember: true }}
      onFinish={addAccount}
      onFinishFailed={onFinishFailed}
      ref={formRef}
    >
      <Form.Item
        label={t('Register.userName')}
        name="userName"
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập Username!',
          },
          {
            required: true,
            message: (
              <div
                className="okok"
                style={{ display: 'block', height: '40px' }}
              >
                Không nhập khoảng trắng, tối thiểu 8 ký tự, được phép nhập: chữ,
                số, dấu _ (ví dụ: safeweb_app2022)
              </div>
            ),
            pattern: new RegExp(/^[a-zA-Z0-9\-_]{8,200}$/),
          },
          {
            validator: handleChangeName,
          },
        ]}
        className="user-name"
      >
        <Input
          style={{ border: 'none', width: '100%', padding: '5px 10px' }}
          ref={usernameFocus}
        />
      </Form.Item>
      <Form.Item
        label={t('Register.email')}
        name="email"
        rules={[
          { required: true, message: 'Xin hãy nhập Email!' },
          {
            type: 'email',
            message: 'Xin hãy nhập đúng định dạng Email!',
          },
          // {
          //   required: true,
          //   message: 'Bạn có thể nhập cái ký tự sau - _ $ @ * !',
          //   pattern: new RegExp(/^[a-zA-Z0-9\-_$.@*!]{3,200}$/),
          // },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={t('Register.password')}
        name="password"
        rules={[
          { required: true, message: 'Vui lòng nhập mật khẩu!' },
          {
            required: true,
            message:
              'Mật khẩu phải có ít nhất 8 ký tự, bao gồm một số, một ký tự viết thường, một ký tự viết hoa, một ký tự đặc biệt (ví dụ @ # $)',
            pattern: new RegExp(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#~$%^&*()+|_])[A-Za-z\d@$#!%*^~?&+()|_]{8,}$/
            ),
          },
        ]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        label={t('Register.rePasword')}
        name="confirmPassword"
        rules={[
          { required: true, message: 'Nhâp lại mật khẩu!' },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error('Mật khẩu vừa nhập lại chưa giống nhau!')
              );
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item label={t('Register.country')} name="country">
        <CountryDropdown
          value={country}
          onChange={(val) => selectCountry(val)}
          defaultOptionLabel={t('Register.selectCountry')}
        />
      </Form.Item>
      <Form.Item label={t('Register.timezone')} name="time_zone">
        <SelectTimezone
          value={momentTimezone.tz.guess()}
          isClearable
          guess
          onChange={(val: any) => {
            let timezoneProps = getTimezoneProps(val);
            time_zone = timezoneProps.label;
          }}
        />
      </Form.Item>
      <Form.Item>
        <Button
          loading={isLoadingUsername}
          className="mt-2 w-100"
          type="primary"
          htmlType="submit"
          style={{ fontWeight: 'bold', backgroundColor: '#d22736' }}
        >
          {t('Register.submit')}
        </Button>
      </Form.Item>
      <div className="backToLogin mt-3">
        <div>
          {t('Register.youHaveAccount')}{' '}
          <a href="/signin">{t('authentication.login')}</a>
        </div>
      </div>
    </Form>
  );
};

export default FormRegister;
