import { t } from 'i18n';

export const DEFAULT_PAGINATION = {
  page: 1,
  pageSize: 10,
};
export const PAGE_SIZE_OPTIONS = [10, 15, 20, 50, 100];
export const DEFAULT_SIZE_CHANGER = {
  showSizeChanger: true,
  pageSizeOptions: PAGE_SIZE_OPTIONS.map(size => String(size)),
  locale: {
    items_per_page: t('RecordPerPage'),
  },
};
