import { Col, Row } from 'antd';
import ButtonSelectDay from 'modules/Keyword/components/ButtonSelectDay';
import { handleToDay } from 'modules/Keyword/components/action';
import { KEY_TIMEZONE } from 'pkg/cookie/constants';
import { getCookie } from 'pkg/cookie/helpers';
import React, { useState } from 'react';
import ChartClickDomain from './ChartClickDomain';
import ChartKeyword from './ChartKeyword';
import ChartTimeSite from './charTimeSite';

const DashboardChartColumn = ({ userId, user }: any) => {
  const [dateSelected, setDateSelected] = useState(0);

  const getTimeZone = () => {
    return getCookie(KEY_TIMEZONE);
  };
  const getAdminId = () => {
    return getCookie('current_username');
  };

  const getDataByDate = (dateSelected: number) => {
    setDateSelected(dateSelected);
  };

  return (
    <div>
      <Row gutter={[8, 8]}>
        <Col span={24}>
          <ButtonSelectDay actionFunction={getDataByDate} />
        </Col>
      </Row>
      <Row style={{ minHeight: 300 }}>
        <Col span={24} style={{ textAlign: 'center' }}>
          <ChartTimeSite
            userId={userId}
            handleToDay={handleToDay}
            getTimeZone={getTimeZone}
            getAdminId={getAdminId}
            dateSelected={dateSelected}
          />
        </Col>
      </Row>
      <div>
        <Row gutter={32} style={{ minHeight: 300 }}>
          <Col
            xs={24}
            md={12}
            style={{ maxHeight: '600px', overflowY: 'scroll' }}
          >
            <ChartClickDomain
              userId={userId}
              handleToDay={handleToDay}
              getTimeZone={getTimeZone}
              getAdminId={getAdminId}
              dateSelected={dateSelected}
            />
          </Col>
          <Col
            xs={24}
            md={12}
            style={{ maxHeight: '600px', overflowY: 'scroll' }}
          >
            <ChartKeyword
              userId={userId}
              handleToDay={handleToDay}
              getTimeZone={getTimeZone}
              getAdminId={getAdminId}
              dateSelected={dateSelected}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default DashboardChartColumn;
