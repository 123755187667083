import React from 'react';
import { Layout } from 'antd';
import * as userHooks from 'pkg/user/hooks';
import routes from 'routes';
import AppSider from './AppSider';
import AppHeader from './AppHeader';
import AppContent from './AppContent';
import './AppLayout.scss';
import { promotionClient } from 'pkg/request/clients';

const AppLayout: React.FC = () => {
  const { filteredNavigation, filteredRoutes } =
    userHooks.useAuthorizationData(routes);

  const sso = () => {
    promotionClient
      .get('/auth/sso')
      .then((res) => {
        if (res.data && res.data.sso_token) {
          window.open(
            `${process.env.REACT_APP_MANAGER_HOST}/sso?token=${res.data.sso_token}`
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Layout className="app-layout">
      <AppSider filteredNavigation={filteredNavigation} />
      <Layout>
        <AppHeader sso={sso} />
        <AppContent filteredRoutes={filteredRoutes} />
      </Layout>
    </Layout>
  );
};

export default AppLayout;
