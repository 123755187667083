import { notification, Table } from 'antd';
import { t } from 'i18n';
import { getCookie } from 'pkg/cookie/helpers';
import { promotionClient } from 'pkg/request/clients';
import React, { useEffect, useState } from 'react';

export default function BlockappManager(props: any) {
  const [dataSource, setDataSoucre] = useState<any>([]);

  useEffect(() => {
    getBlockApp();
  }, []);

  const getAdminId = () => {
    return getCookie('current_username');
  };

  const getBlockApp = () => {
    promotionClient
      .post('/staff_user/admin/list', {
        admin_id: getAdminId(),
      })
      .then((res: any) => {
        if (res.data.total) {
          setDataSoucre(res.data.staff_list);
        } else {
          notification.error({
            message: 'Error',
            description: res.data.message,
          });
        }
      })
      .catch((err: any) => {
        notification.error({
          message: t('Error'),
          description: err,
        });
      });
  };

  const columns = [
    {
      title: `${t('userManagement.blockApp.add.list.stt')}`,
      dataIndex: 'id',
      key: 'id',
      width: 45,
      render: (value: any, staff: any, index: any) => <span>{index + 1}</span>,
    },
    {
      title: `${t('userManagement.blockApp.add.list.name')}`,
      dataIndex: 'full_name',
      key: 'full_name',
      render: (value: any, staff: any) => {
        return <span>{staff.full_name ?? staff.user_name}</span>;
      },
    },
    {
      title: `${t('userManagement.blockApp.add.list.blockMode')}`,
      dataIndex: 'enable',
      key: 'enable',
      render: (value: any, staff: any, index: number) => {
        return (
          <form>
            <div className="radio-group-app">
              <label>
                <input
                  style={{
                    width: '20px',
                    height: '20px',
                    position: 'relative',
                    top: '5px',
                  }}
                  onChange={() => handleOptionChange('manual', staff)}
                  checked={staff.app_mode === 'manual'}
                  type="radio"
                  id="default"
                  name="app_mode"
                />
                <span style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                  {t('manual')}
                </span>
              </label>

              <label>
                <input
                  style={{
                    width: '20px',
                    height: '20px',
                    position: 'relative',
                    top: '5px',
                  }}
                  onChange={() => handleOptionChange('timer', staff)}
                  checked={staff.app_mode === 'timer'}
                  type="radio"
                  id="blacklist"
                  name="app_mode"
                />
                <span style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                  {t('timer')}
                </span>
              </label>
            </div>
          </form>
        );
      },
    },
  ];

  const handleOptionChange = (status: any, staff: any) => {
    promotionClient
      .post(`/staff_user/admin/switch_app_mode`, {
        staff_id: staff.id,
        switch_mode: status,
      })
      .then((res) => {
        if (res.data.success) {
          notification.success({
            message: t('Success'),
            description: t('notification.tabMode.update.success', {
              type: t(`${status}`),
            }),
          });
          getBlockApp();
        } else {
          notification.error({
            message: 'Error',
            description: res.data.message,
          });
        }
      })
      .catch((err: any) => {
        notification.error({
          message: t('Error'),
          description: err,
        });
      });
  };

  return (
    <>
      <Table
        bordered
        pagination={{ position: ['bottomRight'] }}
        scroll={{ x: 600 }}
        dataSource={dataSource}
        columns={columns}
      />
    </>
  );
}
