import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import * as userHooks from 'pkg/user/hooks';
import { StoreContext } from 'contexts';
import { isLoggedIn } from 'pkg/user/repo';
import { PATH } from 'routes/constants';
import { IRoute } from 'pkg/route/types';
import { browserHistory } from 'pkg/route/helper';

const PrivateRoute = ({ component: Component, ...rest }: IRoute) => {
  // Check if user is logged in or not

  useEffect(() => {
    const url = window.location.href;

    if (!isLoggedIn()) {
      browserHistory.push(`${PATH.LOGIN}?redirect=${url}`);
    }
  }, []);

  // Fetch global data
  const { currentUser } = userHooks.useUserInfo();

  // Show spin when fetching data
  // if (!currentUser) {
  //   return (
  //     <Spin spinning style={{ position: 'fixed', top: '30%', left: '50%' }} />
  //   );
  // }

  return (
    <StoreContext.Provider
      value={{
        currentUser,
      }}
    >
      <Route {...rest} render={(routeProps) => <Component {...routeProps} />} />
    </StoreContext.Provider>
  );
};

export default PrivateRoute;
