import { Tabs } from 'antd';
import { t } from 'i18n';
import Checkout from 'modules/checkout/Controller';
import useQuery from 'modules/checkout/hook/useQueryParams';
import React from 'react';
import Billing from './Billings';

const Subscription = () => {
  const query = useQuery();

  return (
    <Tabs defaultActiveKey={query.get('tab') || '1'}>
      <Tabs.TabPane tab={`${t('billing.subscription')}`} key="1">
        <Checkout />
      </Tabs.TabPane>
      <Tabs.TabPane tab={`${t('billing.history')}`} key="2">
        <Billing />
      </Tabs.TabPane>
    </Tabs>
  );
};

export default Subscription;
