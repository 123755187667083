import { Modal, Result, Tabs } from 'antd';
import { t } from 'i18n';
import useQuery from 'modules/checkout/hook/useQueryParams';
import React, { useEffect, useState } from 'react';
import ChildrenDashboard from './components/childrent';
import ModalChildInfoQuestion from 'modules/Dashboard/ModalChildInfoQuestion';
import { browserHistory } from 'pkg/route/helper';
import { PATH } from 'routes/constants';

const DashboardManagement = () => {
  const query = useQuery();
  const [tab, setTab] = useState('1');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenChildren, setIsModalOpenChildren] = useState(false);
  const queryParams = new URLSearchParams(window.location.search);
  const type = queryParams.get('type');
  useEffect(() => {
    if (type && type !== 'children') setIsModalOpen(true);
    else if (type && type == 'children') setIsModalOpenChildren(true);
  }, [type]);

  const handleOk = () => {
    browserHistory.push(`${PATH.BILLING.LIST}`);
  };
  const oncancel = () => {
    browserHistory.push(PATH.DASHBOARD_NEW);
  };
  return (
    <div>
      <ModalChildInfoQuestion
        isModalOpenChildren={isModalOpenChildren}
        setIsModalOpenChildren={setIsModalOpenChildren}
      />
      <Modal
        maskClosable={false}
        destroyOnClose
        visible={isModalOpen}
        onOk={handleOk}
        okText={t('modal.renewNow.body.btnRenew')}
        cancelText={t('modal.renewNow.body.btnBack')}
        closable={false}
        onCancel={oncancel}
      >
        <Result
          status="warning"
          title={
            type == 'extend_before'
              ? t('modal.renewNow.body.toExpire')
              : t('modal.renewNow.body.hasExpired')
          }
        />
      </Modal>

      <Tabs
        defaultActiveKey={query.get('tab') || '1'}
        onChange={(tab: string) => setTab(tab)}
      >
        <Tabs.TabPane tab={`${t('dashboardNew.tab.children')}`} key="1">
          <ChildrenDashboard tab={tab} />
        </Tabs.TabPane>
        <Tabs.TabPane tab={`${t('dashboardNew.tab.agent')}`} key="2">
          {/* <Billing /> */}
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

export default DashboardManagement;
