import { CheckCircleTwoTone } from '@ant-design/icons';
import { Button, Card, Col, notification, Row } from 'antd';
import axios from 'axios';
import { t } from 'i18n';
import {
  AUTHOR_USER,
  KEY_ACCESS_TOKEN,
  KEY_CURRENT_USERNAME,
  KEY_REFRESH_TOKEN,
} from 'pkg/cookie/constants';
import { getCookie, setCookie } from 'pkg/cookie/helpers';
import { promotionClient } from 'pkg/request/clients';
import React, { useEffect, useState } from 'react';
import { CheckoutContext, formatMoney } from '../Controller';
import '../scss/style.scss';
const gridStyle: React.CSSProperties = {
  // width: '33.33%',
  textAlign: 'center',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
};

const style = {
  stepOneMain: {
    margin: '50px auto',
  },
  submit: {
    textDecoration: 'none',
    borderRadius: '30px',
    background: 'green',
    maxWidth: '120px',
    display: 'block',
    margin: '0 auto',
    color: '#ffffff',
    minHeihgt: '40px',
    padding: '5px 10px',
  },
  hidden: {
    textDecoration: 'none',
    borderRadius: '30px',
    maxWidth: '120px',
    display: 'block',
    margin: '0 auto',
    color: '#ffffff',
    padding: '5px 10px',
    opacity: 0,
  },
  bgSupper: {
    background: '#00FFAD',
  },
};
interface IProps {
  setCurrent: (current: number) => void;
  data: any;
}
export const planName: any = {
  free: `${t('billing.step1.free')}`,
  standard: `${t('billing.step1.standard')}`,
  premium: `${t('billing.step1.premium')}`,
};
const StepsFirst = () => {
  const { data, setCurrent } = CheckoutContext();
  const [user, setUser] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const getUser = () => {
    promotionClient
      .post(`user/get`, { user_name: getCookie(KEY_CURRENT_USERNAME) })
      .then((res: any) => {
        if (res.data) {
          setUser(res.data.user);
        } else {
          notification.error({
            message: t('Error'),
            description: res.data.message,
          });
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getUser();
  }, []);

  const getAuthor = () => {
    return getCookie('author_user');
  };

  const getAdminId = () => {
    return getCookie('current_username');
  };

  const Title = () => {
    return (
      <span>
        {`${t('billing.step1.title', {
          package: `${planName[getAuthor()]} `,
        })}`}
        {user?.admin_role === 'standard'
          ? `${t('billing.step1.subTitle', {
              exp_date: user?.exp_date,
            })}`
          : ''}
        <Button
          type="primary"
          ghost
          style={{
            display: 'inline',
            marginLeft: '10px',
            borderRadius: '5px',
            cursor: 'pointer',
          }}
          onClick={() => setCurrent(1)}
        >
          {getAuthor() === 'standard'
            ? `${t('billing.step1.btnExtend')}`
            : `${t('billing.step1.btnUpgrade')}`}
        </Button>
      </span>
    );
  };

  useEffect(() => {
    Title();
  }, [loading]);

  const freePackage = () => {
    const refreshToken = getCookie(KEY_REFRESH_TOKEN);
    setLoading(true);
    promotionClient
      .post(`/subscription/admin/downtofree`, {
        user_name: getCookie(KEY_CURRENT_USERNAME),
      })
      .then((res: any) => {
        notification.success({
          message: t('Success'),
          description: 'Free package successfully',
        });
        setCookie(AUTHOR_USER, 'free');
        axios
          .get('/auth/refresh', {
            headers: {
              Authorization: `${refreshToken},${getAdminId()}`,
              ContentType: 'application/json',
            },
            baseURL: `${process.env.REACT_APP_PROMOTION_DOMAIN}/api/v1`,
          })
          .then((res: any) => {
            if (res.data) {
              const { access_token, refresh_token } = res.data;
              setCookie(KEY_ACCESS_TOKEN, access_token);
              setCookie(KEY_REFRESH_TOKEN, refresh_token);
              getUser();
            }
          });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <Row className="container" style={style.stepOneMain} justify="center">
      <Col className="div">
        <Card title={<Title />} id="myCard">
          <Row justify="center">
            <Col sm={24} md={8} className="gutter-row">
              <Card bordered={false} className="free" style={{ ...gridStyle }}>
                <div style={{ flex: 1 }}>
                  <h3>
                    <b>{planName.free}</b>
                    {getAuthor() === 'free' && (
                      <CheckCircleTwoTone
                        style={{ marginLeft: '5px', fontSize: '1.3rem' }}
                        twoToneColor="#52c41a"
                      />
                    )}
                  </h3>
                  <div style={{ fontSize: '1.17em', fontWeight: 'bold' }}>
                    {t('billing.step1.price.free')}
                  </div>
                  <div
                    style={{
                      fontSize: '1em',
                      fontWeight: 'bold',
                      marginBottom: 20,
                    }}
                  >
                    {t('billing.step1.textForMonth')}
                  </div>
                  <p>{t('billing.step1.maxComputer', { max: 2 })}</p>
                  <p>{t('billing.step1.maxbaby', { max: 2 })}</p>
                  <p>{t('billing.step1.noFocus')}</p>
                  <p>{t('billing.step1.noBlockApp')}</p>
                  <p>{t('billing.step1.blockBlackWeb')}</p>
                  <p>{t('billing.step1.noScreenshot')}</p>
                </div>
                <div style={{ height: '30px' }}>
                  {getAuthor() === 'standard' && (
                    <a style={style.submit} onClick={() => freePackage()}>
                      {t('billing.step1.btnApply')}
                    </a>
                  )}
                </div>
              </Card>
            </Col>
            <Col sm={24} md={8} className="gutter-row">
              <Card bordered={false} className="vip" style={{ ...gridStyle }}>
                <div style={{ flex: 1 }}>
                  <h3 style={{ color: '#ffffff' }}>
                    <b>{planName.standard}</b>
                    {getAuthor() === 'standard' && (
                      <CheckCircleTwoTone
                        style={{ marginLeft: '5px', fontSize: '1.3rem' }}
                        twoToneColor="#52c41a"
                      />
                    )}
                  </h3>
                  {/* <div style={{ position: 'relative' }}>
                    <h1 style={{ color: '#ffffff' }}>
                      {formatMoney(data.order_total)} VND
                    </h1>
                  </div> */}
                  <div style={{ fontSize: '1.17em', fontWeight: 'bold' }}>
                    {t('billing.step1.price.standard')}
                  </div>
                  <div
                    style={{
                      fontSize: '1em',
                      fontWeight: 'bold',
                      marginBottom: 20,
                    }}
                  >
                    {t('billing.step1.textForMonth')}
                  </div>
                  <p>{t('billing.step1.maxComputer', { max: 4 })}</p>
                  <p>{t('billing.step1.maxbaby', { max: 4 })}</p>
                  <p>{t('billing.step1.focus')}</p>
                  <p>{t('billing.step1.blockApp')}</p>
                  <p>{t('billing.step1.blockBlackWeb')}</p>
                  <p>{t('billing.step1.noScreenshot')}</p>
                </div>
                <div style={{ height: '30px' }}>
                  <a style={style.submit} onClick={() => setCurrent(1)}>
                    {getAuthor() === 'standard'
                      ? `${t('billing.step1.btnExtend')}`
                      : `${t('billing.step1.btnUpgrade')}`}
                  </a>
                </div>
              </Card>
            </Col>
            <Col sm={24} md={8} className="gutter-row">
              <Card bordered={false} className="gold" style={{ ...gridStyle }}>
                <div style={{ flex: 1 }}>
                  <h3>
                    <b>{planName.premium}</b>
                    {getAuthor() === 'premium' && (
                      <CheckCircleTwoTone
                        style={{ marginLeft: '5px', fontSize: '1.3rem' }}
                        twoToneColor="#52c41a"
                      />
                    )}
                  </h3>
                  {/* <div style={{ position: 'relative' }}>
                    <h1>135.000 VND</h1>
                  </div>
                   */}
                  <div style={{ fontSize: '1.17em', fontWeight: 'bold' }}>
                    {t('billing.step1.price.premium')}
                  </div>
                  <div
                    style={{
                      fontSize: '1em',
                      fontWeight: 'bold',
                      marginBottom: 20,
                    }}
                  >
                    {t('billing.step1.textForMonth')}
                  </div>
                  <p>{t('billing.step1.maxComputer', { max: 4 })}</p>
                  <p>{t('billing.step1.maxbaby', { max: 4 })}</p>
                  <p>{t('billing.step1.focus')}</p>
                  <p>{t('billing.step1.blockApp')}</p>
                  <p>{t('billing.step1.blockBlackWeb')}</p>
                  <p>{t('billing.step1.screenshot')}</p>
                </div>
                <div style={{ height: '30px' }}>
                  <a style={style.submit}>{t('billing.step1.btnTodo')}</a>
                </div>
              </Card>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default StepsFirst;
