import { Button, Form, Input, notification, Select } from 'antd';
import { t } from 'i18n';
import { promotionClient } from 'pkg/request/clients';
import React from 'react';

const StaffChangePassword = (props: any) => {
  const { Option } = Select;
  const [form] = Form.useForm();
  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };
  const tailLayout = {
    wrapperCol: { offset: 0, span: 16 },
  };
  const SubmitPassword = (values: any) => {
    promotionClient
      .post('/staff_user/admin/password', {
        staff_id: values.staff_id,
        password: values.newPassword,
        token: '',
      })
      .then((res: any) => {
        if (res.data.success) {
          onReset();
          notification.success({
            message: res.data.message,
            description: t('notification.staff.updatePass.success'),
          });
        } else {
          notification.error({
            message: res.data.message,
            description: `Reset staff password fail`,
          });
        }
      })
      .catch((err: any) => {
        notification.error({
          message: err,
          description: `Reset password fail`,
        });
      });
  };

  const onReset = () => {
    form.resetFields();
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };
  const { listStaff } = props;
  return (
    <>
      <Form
        {...layout}
        form={form}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={SubmitPassword}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label={t('staff_listYourUser')}
          name="staff_id"
          className="staff-item"
          rules={[
            {
              required: true,
              message: t('notification.input.select.staff'),
            },
          ]}
        >
          <Select style={{ width: '100%' }}>
            {listStaff.map((staff: any) => (
              <Option value={staff.id}>{staff.full_name}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={t('staff_newPass')}
          name="newPassword"
          className="staff-item"
          rules={[
            // { required: true, message: 'Please input your password!', },
            { min: 4, message: t('notification.input.create.user.minpass') },
            // {
            //   whitespace: false,
            //   message: 'no spaces are allowed!',
            // },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item className="staff-item mt-10" {...tailLayout}>
          <Button type="primary" htmlType="submit">
            {t('staff_reset')}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default StaffChangePassword;
