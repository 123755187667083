import { getTimezoneProps, SelectTimezone } from '@capaj/react-select-timezone';
import { Card, Col, Form, Input, notification, Radio, Row } from 'antd';
import { t } from 'i18n';
import { BackButton, SaveButton } from 'modules/base/Buttons';
import InfoWithAvatar from 'modules/base/InfoWithAvatar';
import { KEY_CURRENT_USERNAME, KEY_TIMEZONE } from 'pkg/cookie/constants';
import { getCookie, setCookie } from 'pkg/cookie/helpers';
import { promotionClient } from 'pkg/request/clients';
import { useRouter } from 'pkg/route/hooks';
import React, { useEffect, useState } from 'react';
import { CountryDropdown } from 'react-country-region-selector';
import { useHistory } from 'react-router-dom';
import { PATH } from 'routes/constants';
import './User.scss';
const options = [
  { label: 'Male', value: 'MALE' },
  { label: 'Female', value: 'FEMALE' },
  { label: 'LBGT', value: 'LGBT' },
];
const tabList = [
  {
    key: 'info',
    tab: 'User update',
  },
];

type Params = {
  id: string;
};

const layout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 17 },
};

const tailLayout = {
  wrapperCol: { offset: 7, span: 17 },
};

const EditUser = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const router = useRouter<Params>();
  const { id } = router.matchParams;
  const [loading, setLoading] = useState<boolean>(false);
  const [user, setUser] = useState<any>(null);
  const [timezone, setTimezone] = useState<string>('');

  useEffect(() => {
    setLoading(true);
    promotionClient
      .post(`user/get`, {
        user_name: getCookie(KEY_CURRENT_USERNAME),
      })
      .then((res: any) => {
        if (res.data) {
          setUser(res.data.user);
          setTimezone(res.data.user.time_zone.split(' ')[1]);
        } else {
          notification.error({
            message: t('Error'),
            description: res.data.message,
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [id]);
  let country = '';
  const selectCountry = (val: string) => {
    country = val;
  };

  const handleLoaded = (val: any) => {
    const data = {
      ...val,
      time_zone:
        timezone === user.time_zone.split(' ')[1] ? user.time_zone : timezone,
      id,
    };
    promotionClient
      .post(`user/update`, data)
      .then((res: any) => {
        if (res.data.success) {
          setCookie(KEY_TIMEZONE, timezone);
          notification.success({
            message: t('Success'),
            description: 'Update user success',
          });
          history.push(`${PATH.USER_INFORMATION}`);
        } else {
          notification.error({
            message: t('Error'),
            description: res.data.message,
          });
        }
      })
      .catch((err) => {});
  };
  const onFinishFailed = (val: any) => {};

  const contentList: Record<string, React.ReactNode> = {
    info: (
      <div>
        <Form
          {...layout}
          name="updateUser"
          initialValues={user}
          onFinish={handleLoaded}
          onFinishFailed={onFinishFailed}
          form={form}
        >
          <Form.Item label={t('users.detail.form.fullName')} name="full_name">
            <Input />
          </Form.Item>
          <Form.Item label={t('users.detail.form.sex')} name="sex">
            <Radio.Group
              options={options}
              value={user?.sex}
              optionType="button"
              buttonStyle="solid"
            />
          </Form.Item>

          <Form.Item label={t('users.detail.form.phone')} name="phone">
            <Input />
          </Form.Item>

          <Form.Item label={t('Register.country')} name="country">
            <CountryDropdown
              value={country}
              onChange={(val) => selectCountry(val)}
            />
          </Form.Item>
          <Form.Item label={t('Register.timezone')}>
            <SelectTimezone
              value={timezone}
              isClearable
              guess
              onChange={(val: any) => {
                let timezoneProps = getTimezoneProps(val);
                setTimezone(timezoneProps.label);
              }}
            />
          </Form.Item>
          <Form.Item className="staff-item" {...tailLayout}>
            <BackButton style={{ marginRight: '10px' }} />
            <SaveButton htmlType="submit" />
          </Form.Item>
        </Form>
      </div>
    ),
  };

  return (
    <div className="detail-user">
      <Row gutter={[16, 16]}>
        <Col span={7}>
          {user && (
            <InfoWithAvatar
              data={[
                { label: 'users.detail.adminRole', value: user.user_name },
                { label: 'users.detail.type', value: user.admin_role },
                {
                  label: 'users.detail.exp',
                  value: user.exp_date,
                  isShow: user.admin_role === 'standard',
                },
              ]}
              loading={loading}
            />
          )}
        </Col>
        <Col span={17}>
          <Card
            loading={loading}
            style={{ width: '100%' }}
            tabList={tabList}
            activeTabKey={'info'}
          >
            {contentList['info']}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default EditUser;
