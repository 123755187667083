import './NewPassword.scss';
import React, { useEffect, useState } from 'react';
import { Avatar, Button, Form, Input, notification } from 'antd';
import { t } from '../../i18n';
import { promotionClient } from '../../pkg/request/clients';
import { useHistory, useParams } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import { SECRET_PASSWORD } from '../../config/contants';
import { PATH } from '../../routes/constants';
import Logo from '../../assets/images/android-chrome-128x128.png';
import { changeLanguage } from 'pkg/localization/helpers';
import { REGIONS } from 'pkg/localization/constants';
import {
  ExclamationCircleOutlined,
  ExclamationCircleTwoTone,
  ExclamationCircleFilled,
} from '@ant-design/icons';
import Loader from 'modules/loader/Loader';

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const tailLayout = {
  wrapperCol: { offset: 0, span: 16 },
};

export default function NewPassword() {
  const history = useHistory();
  let params: any = useParams();

  const [loading, setLoading] = useState(false);
  const [tokenOk, setTokenOk] = useState(false);
  const backToLoginPage = () => {
    history.push(PATH.LOGIN);
  };

  useEffect(() => {
    if (params.id) {
      checkToken(params.id);
    }
  }, [params]);

  const checkToken = (token: string) => {
    setLoading(true);
    promotionClient
      .post(`/user/testtoken`, {
        token,
      })
      .then((res) => {
        if (!res.data.success) {
          notification.error({
            message: t('Error'),
            description: 'Token expired',
          });
          setTokenOk(false);
        } else {
          setTokenOk(true);
        }
      })
      .catch((err) => {
        notification.error({
          message: t('Error'),
          description: err,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const checkGmail = (value: any) => {
    let isGmail = value?.indexOf('@');
    let before = '';
    let after = '';
    let result = '';
    let spText = '';
    if (isGmail === -1) {
      result = value?.split('.').join('');
    } else if (isGmail) {
      before = value?.slice(0, isGmail);
      spText = before?.split('.').join('');

      after = value?.slice(isGmail);

      result = `${spText}${after}`;
    }
    return result.replace(/\./g, '').toLowerCase();
  };

  const newPassword = (value: any) => {
    setLoading(true);
    let encrypted = CryptoJS.AES.encrypt(value.password, SECRET_PASSWORD);
    let password: any = encrypted.toString();
    promotionClient
      .post(`/user/newpassword`, {
        token: params.id,
        password: password,
      })
      .then((res) => {
        if (res.data.success) {
          notification.success({
            message: t('Success'),
            description: 'New password successfully',
          });
          backToLoginPage();
        } else {
          notification.error({
            message: t('Error'),
            description: res.data.message,
          });
        }
      })
      .catch((err) => {
        notification.error({
          message: t('Error'),
          description: err,
        });
      });
  };
  const onUpdateLanguage = (key: any) => {
    changeLanguage(key);
  };
  if (loading) return <Loader />;
  return (
    <div className="GetPassword-form login-form">
      <div className="newPassword-img">
        <img src={Logo} alt="" />
      </div>
      <h2 className="newPassword-title-logo">SAFEWEB.APP</h2>

      {/* <h1 className="login-form__title">NEW PASSWORD</h1> */}
      {!loading && tokenOk ? (
        <Form
          {...layout}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={newPassword}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label={t('resetPassword.newPassword')}
            name="password"
            rules={[
              { required: true, message: 'Vui lòng nhập mật khẩu!' },
              {
                required: true,
                message:
                  'Mật khẩu phải có ít nhất 8 ký tự, bao gồm một số, một ký tự viết thường, một ký tự viết hoa, một ký tự đặc biệt.',
                pattern: new RegExp(
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#~$%^&*()+|_])[A-Za-z\d@$#!%*^~?&+()|_]{8,}$/
                ),
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item {...tailLayout} className="w-100">
            <Button
              loading={loading}
              className="mt-2"
              type="primary"
              htmlType="submit"
            >
              {t('resetPassword.submit')}
            </Button>
          </Form.Item>
          {/*<div className='mt-1'>*/}
          {/*  <div><a href='/'>{t('authentication.login')} </a></div>*/}
          {/*</div>*/}
        </Form>
      ) : (
        <div>
          <span className="app-user d-flex justify-content-center">
            <div
              className="mr-05 mb-1 cursor-poiter"
              onClick={() => onUpdateLanguage('en')}
            >
              <Avatar src={REGIONS.en.flag} shape="square" />
            </div>
            <div
              className="cursor-poiter"
              onClick={() => onUpdateLanguage('vi')}
            >
              <Avatar src={REGIONS.vi.flag} shape="square" />
            </div>
          </span>
          <div id="input-style">
            <Input
              disabled
              bordered
              addonBefore={
                <ExclamationCircleTwoTone
                  twoToneColor="#D22737"
                  style={{ fontSize: '40px', color: '#D22737' }}
                />
              }
              defaultValue={t('TokenExpiredMessage')}
              size="large"
              style={{ fontSize: '30px' }}
            />
          </div>
          <div style={{ color: 'gray', textAlign: 'center' }}>
            {t('TokenExpiredMessageDes')}
          </div>
        </div>
      )}
    </div>
  );
}
