import { Button } from 'antd';
import { t } from 'i18n';
import moment from 'moment';
import { getCookie } from 'pkg/cookie/helpers';
import React, { useEffect, useState } from 'react';

type Props = {
  actionFunction: (value: any) => void;
};

const ButtonSelectDay = ({ actionFunction }: Props) => {
  const [days, setDays] = useState<string[]>([]);
  useEffect(() => {
    getListDay();
  }, []);
  const getAuthor = () => {
    return getCookie('author_user');
  };
  const count = getAuthor() === 'standard' ? 7 : 3;
  const [isDisableDay, setIsDisableDay] = useState(count - 1);
  const getListDay = () => {
    const days: any = [];

    for (let i = 0; i < count; i++) {
      let day = moment().subtract(i, 'days');
      days.push(day.format('DD/M'));
    }
    setDays(days.reverse());
  };
  return (
    <div className="list-command-action">
      {days.map((item: any, index: number) => {
        const value = count - index - 1;
        return (
          <Button
            key={index}
            className={'mr-1 mt-1 mb-1'}
            onClick={() => {
              setIsDisableDay(index);
              actionFunction(value);
            }}
            type="primary"
            shape="round"
            size={'small'}
            disabled={isDisableDay === index}
          >
            {value === 0
              ? t('AgentPage.today')
              : value === 1
              ? t('AgentPage.yesterday')
              : value === 2
              ? t('AgentPage.dby')
              : item}
          </Button>
        );
      })}
    </div>
  );
};

export default ButtonSelectDay;
