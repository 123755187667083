import React, { useEffect, useState } from 'react';
import { Row, Col, Modal, Result } from 'antd';
import './Dashboard.scss';
import Loader from '../loader/Loader';
import AddAgent from './AddAgent';
import { browserHistory } from 'pkg/route/helper';
import { PATH } from 'routes/constants';
import ModalChildInfoQuestion from './ModalChildInfoQuestion';
import { t } from 'i18n';
const Dashboard = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenChildren, setIsModalOpenChildren] = useState(false);
  const queryParams = new URLSearchParams(window.location.search);
  const type = queryParams.get('type');
  useEffect(() => {
    if (type && type !== 'children') setIsModalOpen(true);
    else if (type && type == 'children') setIsModalOpenChildren(true);
  }, [type]);

  const handleOk = () => {
    browserHistory.push(`${PATH.BILLING.LIST}`);
  };
  const oncancel = () => {
    browserHistory.push(PATH.DASHBOARD);
  };
  return (
    <Row gutter={15}>
      <ModalChildInfoQuestion
        isModalOpenChildren={isModalOpenChildren}
        setIsModalOpenChildren={setIsModalOpenChildren}
      />
      <Modal
        maskClosable={false}
        destroyOnClose
        visible={isModalOpen}
        onOk={handleOk}
        okText={t('modal.renewNow.body.btnRenew')}
        cancelText={t('modal.renewNow.body.btnBack')}
        closable={false}
        onCancel={oncancel}
      >
        <Result
          status="warning"
          title={
            type == 'extend_before'
              ? t('modal.renewNow.body.toExpire')
              : t('modal.renewNow.body.hasExpired')
          }
        />
      </Modal>
      <Col className="gutter-row" span={24}>
        <AddAgent />
      </Col>
      {/* <LoadingCP /> */}
    </Row>
  );
};
export default Dashboard;
