import { LocalizationProvider, MobileTimePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import moment from 'moment';
import React from 'react';

type Props = {
  timers: any;
  onChangeTime: (time: string) => void;
};

const SelectTime = ({ timers, onChangeTime }: Props) => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DemoContainer components={['MobileTimePicker']}>
        <DemoItem label="">
          <MobileTimePicker
            onAccept={(time: any) => {
              const timeString = moment(time).format('HH:mm');
              onChangeTime(timeString);
            }}
            ampm={false}
            views={['hours', 'minutes']}
            format="HH:mm"
            // value={moment(
            //   `2023-01-01 ${timer.activation_time}`
            // )}
            defaultValue={moment(`2023-01-01 ${timers}`)}
            sx={{
              width: 100,
              padding: '5px 10px',
            }}
          />
        </DemoItem>
      </DemoContainer>
    </LocalizationProvider>
  );
};

export default SelectTime;
