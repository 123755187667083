import _ from 'lodash';
import { PRICE_FORMAT } from 'pkg/price/constants';
import { SEPARATORS } from './constants';

export const capitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLocaleLowerCase();
};

export const capitalJoin = (...args: Array<string>) => {
  return capitalize(args.join(' '));
};

export const formatPrice = (price: any, defaultValue: string = '') => {
  return `${_.isNumber(price) ? price : defaultValue}`.replace(
    PRICE_FORMAT,
    '.'
  );
};

export const formatPriceFull = (price: number | string) => {
  return `${formatPrice(price)} đ`;
};

export const isPositiveNumber = (value: string) => {
  return /^\d+$/.test(value);
};

export const getIntValIfValid = (value: string) => {
  return isPositiveNumber(value) ? parseInt(value) : value;
};

export const getIntOrDefault = (value: string, defaultValue: number = 0) => {
  return isPositiveNumber(value) ? parseInt(value) : defaultValue;
};

export const containsIgnoreCase = (
  text: string,
  value: string | undefined | null
) => {
  return text.toLowerCase().includes(value?.toLowerCase() || '');
};

export const buildClassNames = (map: object) => {
  return Object.keys(_.pickBy(map)).join(' ');
};

// change accent to non accent vietnamese, support unicode
export const noAccentVietnamese = (data: string) => {
  if (!data) return data;

  data = data.replace(/[àáạảãâầấậẩẫăằắặẳẵ]/g, 'a');
  data = data.replace(/[ÀÁẠẢÃĂẰẮẶẲẴÂẦẤẬẨẪ]/g, 'A');
  data = data.replace(/[èéẹẻẽêềếệểễ]/g, 'e');
  data = data.replace(/[ÈÉẸẺẼÊỀẾỆỂỄ]/g, 'E');
  data = data.replace(/[òóọỏõôồốộổỗơờớợởỡ]/g, 'o');
  data = data.replace(/[ÒÓỌỎÕÔỒỐỘỔỖƠỜỚỢỞỠ]/g, 'O');
  data = data.replace(/[ìíịỉĩ]/g, 'i');
  data = data.replace(/[ÌÍỊỈĨ]/g, 'I');
  data = data.replace(/[ùúụủũưừứựửữ]/g, 'u');
  data = data.replace(/[ƯỪỨỰỬỮÙÚỤỦŨ]/g, 'U');
  data = data.replace(/[ỳýỵỷỹ]/g, 'y');
  data = data.replace(/[ỲÝỴỶỸ]/g, 'Y');
  data = data.replace(/[đ]/g, 'd');
  data = data.replace(/[Đ]/g, 'D');

  return data;
};

// Slug URL Key
export const slugURLKey = (data: string) => {
  data = data
    .replace(
      /[^ 0-9a-zA-ZáàảãạÁÀẢÃẠâÂấẤầẦẩẨẫẪậẬăĂắẮằẰẳẲẵẴặẶđĐéÉèÈẻẺẽẼẹẸêÊếẾềỀểỂễỄệỆíÍìÌỉỈĩĨịỊóÓòÒỏỎõÕọỌôÔốỐồỒổỔỗỖộỘơƠớỚờỜởỞỡỠợỢúÚùÙủỦũŨụỤưƯứỨừỪửỬữỮựỰýÝỳỲỷỶỹỸỵỴ]/g,
      ''
    )
    .trim();
  return noAccentVietnamese(data)
    .replace(/ /g, '-')
    .toLowerCase();
};

export const normalizeSeparator = (value: string) => {
  const trimRegex = /(^,+)|(,$)/g;
  return value
    .split(SEPARATORS)
    .join(',')
    .replace(trimRegex, '');
};

export const updateQueryStringParameter = (
  uri: string,
  key: string,
  value: string | number
) => {
  let re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
  let separator = uri.indexOf('?') !== -1 ? '&' : '?';
  if (uri.match(re)) {
    return uri.replace(re, '$1' + key + '=' + value + '$2');
  } else {
    return uri + separator + key + '=' + value;
  }
};
