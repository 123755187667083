import {
  Button,
  Card,
  Col,
  Form,
  Input,
  notification,
  Row,
  Select,
  Table,
} from 'antd';
import axios from 'axios';
import { t } from 'i18n';
import { getCookie } from 'pkg/cookie/helpers';
import { promotionClient } from 'pkg/request/clients';
import { formatPrice } from 'pkg/string/helpers';
import React, { useEffect } from 'react';
import { CountryDropdown } from 'react-country-region-selector';
import { CheckoutContext, columns } from '../Controller';
import { useHistory } from 'react-router-dom';
const getAuthor = () => {
  return getCookie('author_user');
};

const StepsLast = (props: { setCurrent: any; data: any }) => {
  const { Option } = Select;
  const { dataSource } = CheckoutContext();
  const [paymentMethod, setPaymentMethod] = React.useState<any>('ckpay');
  const history = useHistory();
  let country = '';
  const selectCountry = (val: string) => {
    country = val;
  };
  const getAdminId = () => {
    return getCookie('current_username');
  };
  useEffect(() => {
    promotionClient
      .get(`payment_partner/admin/get`)
      .then((res: any) => {
        console.log(res);
        if (res.data.success) {
          setPaymentMethod(res.data);
        }
      })
      .catch((err: any) => {
        notification.error(err);
      });
  }, []);

  const onFinishFailed = (errorInfo: any) => {
    const err = errorInfo.errorFields;
    console.log('Failed:', errorInfo.errorFields);
    console.log('err', err);
    if (err.length) {
      console.log('err[0].name', err[0].name);
      const input = document.getElementById(`basic_${err[0].name}`);
      console.log('input', input);
      document.documentElement.scrollTop = 300;
    }
  };

  const onFinish = (values: any) => {
    const data = {
      ...props.data,
      admin_id: getAdminId(),
      order_total: props.data.order_total,
      admin_phone: values.admin_phone || '',
      admin_name: values.admin_name,
      admin_title: values.admin_title,
      admin_country: values.admin_country,
      return_url: process.env.REACT_APP_RETURN_URL,
      order_info:
        getAuthor() === 'free'
          ? 'free to standard'
          : getAuthor() === 'standard'
          ? 'standard to standard'
          : '',
    };
    promotionClient
      .post(`/order/admin/create`, data)
      .then((res: any) => {
        notification.success({
          message: t('Success'),
          description: 'Add order success',
        });

        testPostOrder(res.data.checkout);
        console.log(res);
      })
      .catch((err: any) => {
        notification.error(err);
      });
  };

  const testPostOrder = function (order: any) {
    const postObj = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        order: order,
      }),
    };
    console.log('paymentMethod', paymentMethod);
    if (paymentMethod.name == 'ckpay') {
      console.log('121212', 121212);
      fetch(paymentMethod.url, postObj)
        .then((res) => res.json())
        .then(
          (response) => {
            errorCallback(response);
          },
          (error) => {
            errorCallback('Request POST error: ' + error);
          }
        );
    } else {
      // alert('Payment method: safepay');
      history.push('/checkout/v1/qrcode');
    }

    // fetch payment URL to get order token
  };
  const errorCallback = function (message: any) {
    console.log('Error callback: ' + JSON.stringify(message));

    if (message.errors) {
      alert(JSON.stringify(message.errors));
    } else {
      // alert(JSON.stringify(message));
      window.location.href = message.url;
    }
  };
  return (
    <div className="container">
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ admin_title: 'Mr' }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        style={style.formGroupStyle}
        // scrollToFirstError
      >
        <Row gutter={16}>
          <Col sm={24} md={11}>
            <Form.Item
              style={style.formControllItem}
              label={t('payment.title')}
              name="admin_title"
            >
              <Select style={{ width: 150 }}>
                <Option value="Mr">Mr</Option>
                <Option value="Mrs">Mrs</Option>
              </Select>
            </Form.Item>
            <Form.Item
              style={style.formControllItem}
              label={t('payment.username')}
              name="admin_name"
              rules={[
                { required: true, message: 'Vui lòng nhập tên của bạn!' },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              style={style.formControllItem}
              label={t('payment.phone')}
              name="admin_phone"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={t('Register.country')}
              initialValue={'Vietnam'}
              name="admin_country"
            >
              <CountryDropdown
                value={country}
                onChange={(val) => selectCountry(val)}
              />
            </Form.Item>
          </Col>
          <Col sm={24} md={13}>
            <Card
              title={t('billing.step3.detailTotal')}
              extra={<a href="#"></a>}
              style={{ width: '100%', ...style.formGroupStyle }}
            >
              <Row style={{ margin: '10px 0' }}>
                <Col span={24}>
                  {`${t('payment.unitPrice')}: ${formatPrice(
                    props.data.product_price
                  )} ${props.data.product_unit}`}
                </Col>
              </Row>
              <Table
                pagination={false}
                dataSource={dataSource}
                columns={columns}
                // scroll={{ x: 400 }}
              />
              <div style={{ ...style.item, marginTop: '20px' }}></div>
              <h4>{t('payment.bankTransfer')}</h4>
              <i>{`${t('payment.clickPayment')} "${t('payment.submit')}" ${t(
                'payment.clickPayment.desc'
              )}`}</i>
            </Card>
            <Form.Item
              style={{
                ...style.formControllItem,
                display: 'flex',
                justifyContent: '',
              }}
              wrapperCol={{ offset: 1, span: 14 }}
            >
              <a
                style={{ ...style.back, marginRight: '20px' }}
                onClick={() => props.setCurrent(1)}
              >
                {t('payment.prev')}
              </a>
              <Button type="primary" htmlType="submit">
                {t('payment.submit')}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

const style = {
  main: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '30px',
  },
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  submit: {
    textDecoration: 'none',
    borderRadius: '30px',
    background: 'green',
    maxWidth: '250px',
    display: 'block',
    margin: '0 auto',
    color: '#ffffff',
    minHeihgt: '40px',
    padding: '5px 20px',
    marginTop: '30px',
  },
  back: {
    textDecoration: 'none',
    borderRadius: '3px',
    background: 'blue',
    maxWidth: '250px',
    display: 'inline-block',
    color: '#ffffff',
    minHeihgt: '40px',
    padding: '5px 20px',
    margỉnight: '20px',
  },
  formControllItem: {
    marginTop: '10px',
    width: '100%',
  },
  formGroupStyle: {
    marginTop: '50px',
    marginBottom: '50px',
  },
};

export default StepsLast;
