import { Button, Col, Row, Select, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import SelectDayOfWeek from './SelectDayOfWeek';
import { t } from 'i18n';
import ModalSelectDays from './ModalSelectDays';
import { EditOutlined } from '@ant-design/icons';
import { promotionClient } from 'pkg/request/clients';

type Props = {
  user: any;
  getBlockApp: () => void;
  // handleAChangeTimer: (user: any, mode: any, value: any) => void;
};
const dayOfWeek = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

const SelectDateComponent = ({ user, getBlockApp }: Props) => {
  const [weekdaysSelect, setWeekdaysSelect] = useState<any>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const { list } = user;
    const Weekdays = list.find((item: any) => item.name === 'Weekdays');
    setWeekdaysSelect(Weekdays.repeater);
  }, [user]);

  console.log('weekdaysSelect', weekdaysSelect);

  const handleChangeSelectDay = (itemSelect: any) => {
    const selected = [...weekdaysSelect];
    var index = selected.indexOf(itemSelect);
    if (index !== -1) {
      selected.splice(index, 1);
    } else {
      selected.push(itemSelect);
    }

    setWeekdaysSelect(selected);
  };

  console.log('weekdaysSelect', weekdaysSelect);

  const handleAChangeTimer = (user: any, mode: any, value: any) => {
    console.log('value', value);
    const day = [...dayOfWeek];
    const data = [
      {
        name: 'Weekdays',
        repeater: weekdaysSelect,
        time_action: [
          {
            type: value,
            start: user.list.find((item: any) => item.name === 'Weekdays')
              .time_action[0].start,
            stop: user.list.find((item: any) => item.name === 'Weekdays')
              .time_action[0].stop,
          },
        ],
      },
      {
        name: 'Weekend',
        repeater: day.filter((x: any) => !weekdaysSelect.includes(x)),
        time_action: [
          {
            type: value,
            start: user.list.find((item: any) => item.name !== 'Weekdays')
              .time_action[0].start,
            stop: user.list.find((item: any) => item.name !== 'Weekdays')
              .time_action[0].stop,
          },
        ],
      },
    ];
    handleOk(data);
  };

  const handleOk = (list: any) => {
    const data = {
      power: user.power,
      staff_id: user.staff_id,
      list,
    };
    console.log('data', data);

    promotionClient
      .post('/staff_timer/app/admin/set', data)
      .then((res: any) => {
        if (res.data.success) {
          notification.success({
            message: t('Success'),
            description: 1
              ? t('notification.activeStaff.success')
              : t('notification.InactiveStaff.success'),
          });
          getBlockApp();
          setIsModalOpen(false);
        } else {
          notification.error({
            message: t('Success'),
            description: t('notification.activeStaff.fail'),
          });
        }
      })
      .catch((err: any) => {
        setIsModalOpen(false);
        notification.error({
          message: t('Error'),
          description: err || 'error',
        });
      });
  };

  return (
    <Row style={{ alignItems: 'center' }}>
      <Col span={8}>{t('mode')}:</Col>
      <Col span={16}>
        <Select
          defaultValue="Normal"
          value={user.run_mode}
          style={{ width: '100%' }}
          // allowClear
          options={[
            { value: 'block', label: 'Learn time' },
            { value: 'normal', label: 'Game time' },
            { value: 'unlockscreen', label: 'Open screen' },
            { value: 'lockscreen', label: 'Close screen' },
          ]}
          onChange={(value: any) => {
            handleAChangeTimer(user, 'mode', value);
          }}
        />
      </Col>

      {user &&
        user.list.map((item: any) => (
          <Row style={{ marginTop: '20px' }}>
            {item.name === 'Weekdays' ? (
              <Col
                span={24}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: '5px',
                }}
              >
                <div>{t('blockApp.modal.selectDayOfWeek')}:</div>
                <div>
                  <Button
                    style={{
                      backgroundColor: '#F7D124',
                      borderColor: '#F7D124',
                    }}
                    type="primary"
                    shape="circle"
                    icon={<EditOutlined />}
                    onClick={() => setIsModalOpen(true)}
                  />
                </div>
              </Col>
            ) : (
              <Col span={24}>{t('blockApp.modal.selectDayOfWeekend')}:</Col>
            )}
            <Col span={24}>
              <SelectDayOfWeek
                dayOfWeek={dayOfWeek}
                weekdaysSelect={weekdaysSelect}
                type={`${item.name === 'Weekdays' ? 'dayOfWeek' : 'weekend'}`}
                handleChangeSelectDay={(item: any) =>
                  handleChangeSelectDay(item)
                }
              />
            </Col>
            <Col span={12} style={{ marginTop: '5px' }}>
              {t('startTime')}: {item.time_action[0].start}
            </Col>
            <Col span={12} style={{ marginTop: '5px' }}>
              {t('stopTime')}: {item.time_action[0].stop}
            </Col>
          </Row>
        ))}
      <ModalSelectDays
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        user={user}
        dayOfWeek={dayOfWeek}
        weekdaysSelect={weekdaysSelect}
        type="dayOfWeek"
        handleChangeSelectDay={(item: any) => handleChangeSelectDay(item)}
        handleOk={handleOk}
      />
    </Row>
  );
};

export default SelectDateComponent;
