import React, { Component } from 'react';
import { Column } from '@ant-design/charts';
export default class ChartContent extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    console.log('this.props.data', this.props.data);
    const config2 = {
      data: this.props.data,
      xField: 'type',
      yField: 'sales',
      label: {
        position: 'middle',
        style: {
          fill: '#FFFFFF',
          opacity: 0.6,
        },
      },
      xAxis: {
        label: {
          autoHide: true,
          autoRotate: true,
        },
      },
      meta: {
        type: { alias: 'app' },
        sales: {
          alias: 'time',
          formatter: (value) =>
            new Date(value * 1000).toISOString().slice(11, 19),
        },
      },
    };

    return (
      <div className="chartContent-element-aa">
        {/* <Column {...config} /> */}
        {!!this.props.data?.length && <Column {...config2} height={500} />}
      </div>
    );
  }
}
