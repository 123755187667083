import './GetPassword.scss';

import { Button, Form, Input, notification } from 'antd';
import React, { useState } from 'react';
import Logo from '../../assets/images/android-chrome-128x128.png';
import { t } from '../../i18n';
import { promotionClient } from '../../pkg/request/clients';

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const tailLayout = {
  wrapperCol: { offset: 0, span: 16 },
};

export default function GetPassword() {
  const [loading, setLoading] = useState(false);
  const [isNewPasswordForm, setIsNewPasswordForm] = useState(true);
  const [saveEmail, setSaveEmail] = useState('');

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const checkGmail = (value: any) => {
    let result = value;
    if (/@gmail\.com$/.test(result)) {
      const userName = result.split('@');
      const removeDot = userName[0].replaceAll('.', '');
      result = removeDot + '@' + userName[1];
    }
    return result.toLowerCase();
  };

  const forgotPassword = (value: any) => {
    value.email = checkGmail(value.email);
    setSaveEmail(value.email);
    setLoading(true);
    promotionClient
      .post(`/user/forgotpassword`, {
        email: value.email,
      })
      .then((res) => {
        console.log(res, 'forgotPassword');
        if (res.data.success) {
          notification.success({
            message: t('Success'),
            description: t('notification.forgot.pass.success'),
          });
          setLoading(false);
          setIsNewPasswordForm(false);
        } else {
          notification.error({
            message: t('Error'),
            description:
              res.data.message === 'Not Found Or Not Member'
                ? t('notification.forgot.pass.error')
                : '',
          });
          setLoading(false);
        }
      })
      .catch((err) => {
        notification.error({
          message: t('Error'),
          description: err,
        });
      });
  };

  const RenderFormForgotPassword = () => {
    return (
      <>
        <div className="GetPassword-form login-form">
          <Form
            {...layout}
            name="basic"
            initialValues={{ remember: true }}
            onFinish={forgotPassword}
            onFinishFailed={onFinishFailed}
          >
            <div className="register-img">
              <a href="/">
                <img src={Logo} />
              </a>
            </div>
            <h1 className="register-form__title">SAFEWEB.APP</h1>
            <Form.Item
              label={t('Register.email')}
              name="email"
              rules={[
                {
                  required: true,
                  message: t('notification.forgot.pass.input.email'),
                },
                {
                  type: 'email',
                  message: t('notification.forgot.pass.input.emailNotValid'),
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item {...tailLayout} className="w-100">
              <div className="mt-2">
                <Button
                  loading={loading}
                  type="primary"
                  className="mr-1"
                  htmlType="submit"
                >
                  {t('forgotPassword.submit')}
                </Button>
                <a href="/">{t('authentication.login')}</a>
              </div>
            </Form.Item>
          </Form>
        </div>
      </>
    );
  };
  return (
    <div className="GetPassword">
      {isNewPasswordForm ? (
        <RenderFormForgotPassword />
      ) : (
        <div className="checkEmai text-center">
          <div className="register-img mb-5">
            <a href="/">
              <img src={Logo} />
            </a>
          </div>
          <h1 className="checkEmail-mai mrt-5">
            {t('authentication.checkemail')}
            <a href={`mailto:${saveEmail}`} className="pl-1">
              {saveEmail}
            </a>
          </h1>
          <h3>
            <a style={{ textDecoration: 'underline' }} href="/">
              {t('authentication.login')}
            </a>
          </h3>
        </div>
      )}
    </div>
  );
}
