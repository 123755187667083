import React from 'react';
import { Link } from 'react-router-dom';
import { Result, Button } from 'antd';
import { LogoutButton } from 'modules/base/Buttons';
import { PATH } from 'routes/constants';
import { t } from 'i18n';

const Page403: React.FC = () => {
  return (
    <Result
      className="app-result-page"
      status="403"
      title="403"
      subTitle={t('PermissionDenied')}
      extra={
        <>
          <Link to={PATH.HOME} className="mr-base">
            <Button type="primary">{t('BackToHome')}</Button>
          </Link>
          <LogoutButton />
        </>
      }
    />
  );
};

export default Page403;
