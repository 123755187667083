import { RollbackOutlined } from '@ant-design/icons';
import { Button, Col, Descriptions, notification, Row } from 'antd';
import { t } from 'i18n';
import { promotionClient } from 'pkg/request/clients';
import { useRouter } from 'pkg/route/hooks';
import { formatPrice } from 'pkg/string/helpers';
import React, { CSSProperties, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PATH } from 'routes/constants';
import './Billing.scss';
type Params = {
  id: string;
};
const labelStyle: CSSProperties = {
  backgroundColor: '#008080',
  color: '#ffff',
};
export default function DetailBilling(props: any) {
  const [data, setData] = useState<any>();
  const history = useHistory();
  const router = useRouter<Params>();
  const { id } = router.matchParams;

  useEffect(() => {
    getApi();
  }, []);

  const getApi = () => {
    promotionClient
      .post('/order/admin/view', { id })
      .then((res: any) => {
        if (res.data.detail) {
          setData(res.data.detail);
        } else {
          notification.error({
            message: 'Error',
            description: res.data.message,
          });
        }
      })
      .catch((err: any) => {
        notification.error({
          message: t('Error'),
          description: err,
        });
      })
      .finally(() => {});
  };

  return (
    <div style={{ marginTop: '22px' }}>
      <Button
        onClick={() => history.push(`${PATH.BILLING.LIST}?tab=2`)}
        type="primary"
        icon={<RollbackOutlined />}
        size="large"
        style={{ margin: '20px 0' }}
      >
        {t('billing.history.detail.btnBack')}
      </Button>
      <Descriptions
        className="style-label-res"
        title={`${t('billing.history.detail.detail')}`}
        // extra={<Button type="primary">Edit</Button>}
        layout="vertical"
        column={3}
      >
        <Descriptions.Item
          className="style-label-res"
          label={`${t('billing.history.detail.invoicedTo')}`}
        >{`${data?.customer_title}. ${data?.customer_name}`}</Descriptions.Item>

        <Descriptions.Item
          className="style-label-res"
          label={`${t('billing.history.detail.invoicedNumber')}`}
        >
          #{data?.order_id}
        </Descriptions.Item>

        <Descriptions.Item
          className="style-label-res"
          label={`${t('billing.history.detail.amountPaid')}`}
        >
          <span className="style-content-number">{`${formatPrice(
            +data?.amount || 0
          )} ${data?.package_unit}`}</span>
        </Descriptions.Item>
        <Descriptions.Item
          className="style-label-res"
          label={``}
        >{``}</Descriptions.Item>
        <Descriptions.Item
          className="style-label-res"
          label={`${t('billing.history.detail.date')}`}
        >
          {data?.bank_date}
        </Descriptions.Item>
        <Descriptions.Item
          className="style-label-res"
          label={`${t('billing.history.detail.status')}`}
        >
          {data?.status_name}
        </Descriptions.Item>
      </Descriptions>
      <br />
      <Row className="show-mobile">
        <Col span={24}>
          {`${t('payment.unit')} ${t('payment.price')}: ${formatPrice(
            +data?.package_price
          )} ${data?.package_unit}`}
        </Col>
      </Row>
      <Descriptions bordered layout="vertical" column={5}>
        <Descriptions.Item
          label={`${t('billing.history.list.no')}`}
          className="style-label"
        >
          1
        </Descriptions.Item>
        <Descriptions.Item
          label={`${t('billing.history.list.description')}`}
          className="style-label"
        >
          {data?.package_description}
        </Descriptions.Item>
        <Descriptions.Item label="Qty" className="style-label">
          {data?.package_quantity}
        </Descriptions.Item>
        <Descriptions.Item
          label={`${t('billing.history.detail.price')}`}
          className="style-label hidden-style"
        >
          {`${formatPrice(+data?.package_price || 0)} ${data?.package_unit}`}
        </Descriptions.Item>
        <Descriptions.Item
          label={`${t('billing.history.list.amount')}`}
          className="style-label"
        >
          {`${formatPrice(+data?.package_quantity * +data?.package_price)} ${
            data?.package_unit
          }`}
        </Descriptions.Item>
      </Descriptions>
      <br />
      <br />
      <Row justify="end">
        <Col sm={24} md={8}>
          <Descriptions bordered column={1}>
            <Descriptions.Item
              label={`${t('billing.history.detail.total')}`}
              className="style-label"
            >
              <span style={{ float: 'right' }}>
                {' '}
                {`${formatPrice(
                  +data?.package_quantity * +data?.package_price
                )} ${data?.package_unit}`}
              </span>
            </Descriptions.Item>
            <Descriptions.Item
              label={`${t('billing.history.detail.discount')}`}
              className="style-label"
            >
              <span style={{ float: 'right' }}>
                {formatPrice(+data?.package_discount)} {data?.package_unit}
              </span>
            </Descriptions.Item>
            <Descriptions.Item
              label={`${t('billing.history.detail.vat')}`}
              className="style-label"
            >
              <span style={{ float: 'right' }}>
                {' '}
                {formatPrice(+data?.package_tax)} {data?.package_unit}
              </span>
            </Descriptions.Item>
            <Descriptions.Item
              label={`${t('billing.history.detail.totalPaid')}`}
              className="style-label"
            >
              <span style={{ float: 'right' }}>{`${formatPrice(
                +data?.order_total
              )} ${data?.package_unit}`}</span>
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
    </div>
  );
}
