/**
 * @fileoverview gRPC-Web generated client stub for app.safeweb.v1
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.13.0
// source: service_stream_qr_pay.proto

/* eslint-disable */
// @ts-nocheck

const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.app = {};
proto.app.safeweb = {};
proto.app.safeweb.v1 = require('./service_stream_qr_pay_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.app.safeweb.v1.StreamQrPayServiceClient = function (
  hostname,
  credentials,
  options
) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');
};

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.app.safeweb.v1.StreamQrPayServicePromiseClient = function (
  hostname,
  credentials,
  options
) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');
};

/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.app.safeweb.v1.GetQRpayReq,
 *   !proto.app.safeweb.v1.GetQRpayRes>}
 */
const methodDescriptor_StreamQrPayService_GetQRpay =
  new grpc.web.MethodDescriptor(
    '/app.safeweb.v1.StreamQrPayService/GetQRpay',
    grpc.web.MethodType.SERVER_STREAMING,
    proto.app.safeweb.v1.GetQRpayReq,
    proto.app.safeweb.v1.GetQRpayRes,
    /**
     * @param {!proto.app.safeweb.v1.GetQRpayReq} request
     * @return {!Uint8Array}
     */
    function (request) {
      return request.serializeBinary();
    },
    proto.app.safeweb.v1.GetQRpayRes.deserializeBinary
  );

/**
 * @param {!proto.app.safeweb.v1.GetQRpayReq} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.app.safeweb.v1.GetQRpayRes>}
 *     The XHR Node Readable Stream
 */
proto.app.safeweb.v1.StreamQrPayServiceClient.prototype.getQRpay = function (
  request,
  metadata
) {
  return this.client_.serverStreaming(
    this.hostname_ + '/app.safeweb.v1.StreamQrPayService/GetQRpay',
    request,
    metadata || {},
    methodDescriptor_StreamQrPayService_GetQRpay
  );
};

/**
 * @param {!proto.app.safeweb.v1.GetQRpayReq} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.app.safeweb.v1.GetQRpayRes>}
 *     The XHR Node Readable Stream
 */
proto.app.safeweb.v1.StreamQrPayServicePromiseClient.prototype.getQRpay =
  function (request, metadata) {
    return this.client_.serverStreaming(
      this.hostname_ + '/app.safeweb.v1.StreamQrPayService/GetQRpay',
      request,
      metadata || {},
      methodDescriptor_StreamQrPayService_GetQRpay
    );
  };

module.exports = proto.app.safeweb.v1;
