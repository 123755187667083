import { Avatar, Button } from 'antd';
import { t } from 'i18n';
import useQuery from 'modules/checkout/hook/useQueryParams';
import { REGIONS } from 'pkg/localization/constants';
import { changeLanguage, getCurrentLanguage } from 'pkg/localization/helpers';
import React, { useEffect, useState } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useHistory } from 'react-router-dom';
import Logo from '../../assets/images/android-chrome-128x128.png';
import FormRegister from './components/FormRegister';
import './Register.scss';

const Register = () => {
  const [isConfirmText, setIsConfirmText] = useState(false);
  const history = useHistory();
  const query = useQuery();

  useEffect(() => {
    const name = query.get('lang');
    if (name && name !== getCurrentLanguage()) {
      changeLanguage(name);
    }
  }, []);
  const handleLogin = () => {
    history.push('/signin');
    localStorage.removeItem('isConfirmText');
  };

  const onUpdateLanguage = (key: any) => {
    changeLanguage(key);
  };

  const RegisterComponent = (lg: any) => {
    if (!isConfirmText) {
      return (
        <div>
          <div className="register-img">
            <a href="/">
              <img src={Logo} />
            </a>
          </div>
          <h1 className="register-form__title">SAFEWEB.APP</h1>
          <span className="app-user d-flex justify-content-center">
            <div
              className="mr-05 cursor-poiter"
              onClick={() => onUpdateLanguage('en')}
            >
              <Avatar src={REGIONS.en.flag} shape="square" />
            </div>
            <div
              className="cursor-poiter"
              onClick={() => onUpdateLanguage('vi')}
            >
              <Avatar src={REGIONS.vi.flag} shape="square" />
            </div>
          </span>
          <GoogleReCaptchaProvider
            reCaptchaKey={process.env.REACT_APP_RECAPTCHA || ''}
          >
            <FormRegister setIsConfirmText={setIsConfirmText} />
          </GoogleReCaptchaProvider>
        </div>
      );
    }
    return (
      <div style={{ maxWidth: '700px' }}>
        <div className="register-img">
          <a href="/">
            <img src={Logo} />
          </a>
        </div>
        <span
          style={{ marginTop: '10px' }}
          className="app-user d-flex justify-content-center"
        >
          <div
            className="mr-05 cursor-poiter"
            onClick={() => onUpdateLanguage('en')}
          >
            <Avatar src={REGIONS.en.flag} shape="square" />
          </div>
          <div className="cursor-poiter" onClick={() => onUpdateLanguage('vi')}>
            <Avatar src={REGIONS.vi.flag} shape="square" />
          </div>
        </span>
        {console.log(getCurrentLanguage())}
        <h2>{t('Register.Checkemail')}</h2>
        {getCurrentLanguage() === 'en' ? (
          <p>
            In order to complete the subscription process, simply check your
            inbox and click on the <b>"Verify"</b> button in the email we have
            just sent you. If you do not see the email in a few minutes, please
            check your <b>“spam”</b> folder or <b>"junk mail"</b> folder and if
            it is in the <b>“spam”</b> folder, remember to click on the{' '}
            <b>"Report not spam"</b> button or mark the email as{' '}
            <b>"not junk"</b>.
          </p>
        ) : (
          <p>
            Để hoàn tất quá trình đăng ký, chỉ cần kiểm tra hộp thư đến của bạn
            và nhấp vào nút <b> "Xác nhận" </b> trong email chúng tôi vừa gửi
            cho bạn. Nếu bạn không thấy email sau một vài phút, vui lòng kiểm
            tra thư mục <b> “hộp thư đến” </b> hoặc thư mục <b> "thư rác" </b> ,
            nếu nó nằm trong thư mục <b> “thư rác”</b>, nhớ nhấp vào nút{' '}
            <b> "Báo cáo không phải là thư rác" </b> hoặc đánh dấu email là{' '}
            <b> "không phải rác" </b>.{' '}
          </p>
        )}
        <div>
          <Button onClick={handleLogin} size={'middle'} type={'primary'}>
            {t('authentication.login')}
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className="register">
      <div className="overlay">
        <RegisterComponent />
      </div>
    </div>
  );
};

export default Register;
