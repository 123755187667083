import { KEY_REFRESH_TOKEN } from './../cookie/constants';
import axios from 'axios';
import { KEY_ACCESS_TOKEN } from 'pkg/cookie/constants';
import { getCookie, setCookie } from 'pkg/cookie/helpers';
import { getAccessToken, isLoggedIn } from 'pkg/user/repo';
import { handleResponseError } from './helper';

export const promotionClient = axios.create({
  baseURL: `${process.env.REACT_APP_PROMOTION_DOMAIN}/api/v1`,
});

const getAuthorization = () => {
  return isLoggedIn() ? getAccessToken() : '';
};

const getAdminID = () => {
  return getCookie('current_username');
};

// Do something before request is sent
const requestInterceptor = (request: any) => {
  var accessToken = getAuthorization();
  request.headers.Authorization = `${accessToken},${getAdminID()}`;
  request.headers.ContentType = 'application/json';
  request.timeout = 10000;
  return request;
};

// Any status code that lie within the range of 2xx cause this function to trigger
const responseSuccessInterceptor = (response: any) => {
  // Do something with response data
  return response;
};
let isRefreshing = false;
let failedQueue: any = [];
const processQueue = (error: any, token = null) => {
  failedQueue.forEach((prom: any) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

// Any status codes that falls outPROMOTION ENGINE SYSTEMside the range of 2xx cause this function to trigger
const responseErrorInterceptor = async (err: any) => {
  const originalConfig = err.config;
  if (originalConfig.url !== '/auth/login' && err.response) {
    // Access Token was expired
    if (
      err.response.status === 401 &&
      err.response.data.code == 16 &&
      !originalConfig._retry
    ) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            return promotionClient(originalConfig);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }
      originalConfig._retry = true;
      isRefreshing = true;
      try {
        const refreshToken = getCookie(KEY_REFRESH_TOKEN);
        const rs = await axios.get('/auth/refresh', {
          headers: {
            Authorization: `${refreshToken},${getAdminID()}`,
            ContentType: 'application/json',
          },
          baseURL: `${process.env.REACT_APP_PROMOTION_DOMAIN}/api/v1`,
        });

        const { access_token } = rs.data;
        isRefreshing = false;
        processQueue(null, access_token);
        setCookie(KEY_ACCESS_TOKEN, access_token);

        return promotionClient(originalConfig);
      } catch (_error) {
        processQueue(err, null);
        handleResponseError(err);
        return Promise.reject(_error);
      }
    }
  }
  handleResponseError(err);
  return Promise.reject(err);
};

const clients = [promotionClient];

clients.forEach((client) => {
  client.interceptors.request.use(requestInterceptor);
  client.interceptors.response.use(
    responseSuccessInterceptor,
    responseErrorInterceptor
  );
});
