import { Button, Col, DatePicker, Form, Modal, Radio, Row, Steps } from 'antd';
import { DatePickerProps } from 'antd/lib/date-picker';
import { RadioChangeEvent } from 'antd/lib/radio';
import { t } from 'i18n';
import moment from 'moment';
import { getCookie } from 'pkg/cookie/helpers';
import { promotionClient } from 'pkg/request/clients';
import React, { useEffect, useState } from 'react';

type Props = {
  isModalOpenChildren: boolean;
  setIsModalOpenChildren: (status: boolean) => void;
};

const FormInfo = ({ data, setInputs, inputs }: any) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [data]);

  const onChangeYear: DatePickerProps['onChange'] = (date, dateString) => {
    setInputs({ ...inputs, year: dateString });
  };

  const onChangeSex = (data: RadioChangeEvent) => {
    setInputs({ ...inputs, sex: data.target.value });
  };
  return (
    <Row justify="center" gutter={24}>
      <Col span={24} style={{ textAlign: 'center' }}>
        <h2>{data.full_name === '' ? data.user_name : data.full_name}</h2>
      </Col>
      <Col span={24} style={{ marginBottom: 30 }}>
        <Form
          form={form}
          name="control-hooks"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 20 }}
          layout="vertical"
        >
          <Form.Item label={`1. ${t('modal.children.sex')}`} name="sex">
            <Radio.Group name="sex" onChange={onChangeSex}>
              <Radio value="1"> {t('modal.children.male')} </Radio>
              <Radio value="0"> {t('modal.children.female')} </Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item label={`2. ${t('modal.children.yob')}`} name="year">
            <DatePicker
              style={{ width: '60%' }}
              onChange={onChangeYear}
              picker="year"
              disabledDate={(current) => {
                return current && current < moment('1923', 'YYYY');
              }}
            />
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

const ModalChildInfoQuestion = ({
  isModalOpenChildren,
  setIsModalOpenChildren,
}: Props) => {
  const [data, setData] = useState<any>([]);
  const [current, setCurrent] = useState(0);
  const [inputs, setInputs] = useState<any>({ sex: '-1', year: 0 });

  const getNameAdmin = () => {
    return getCookie('current_username');
  };
  const getData = async () => {
    const res = await promotionClient.post('/staff_user/admin/question', {
      admin_id: getNameAdmin(),
    });

    if (res.data.total < 1) setIsModalOpenChildren(false);

    setData(res.data.list);
  };

  const update = async (end: boolean = false) => {
    const res = await promotionClient.post('/staff_user/admin/update', {
      staff_id: data[current].id,
      ...inputs,
    });
    if (res.data.success && !end) {
      setCurrent(current + 1);
      setInputs({ sex: '-1', year: 0 });
    }
  };

  useEffect(() => {
    if (isModalOpenChildren) getData();
  }, [isModalOpenChildren]);

  const next = (end: boolean, type: string) => {
    if (type === 'next' || type === 'end') {
      update(end);
    } else if (type === 'prev') {
      setCurrent(current - 1);
    }

    if (end) setIsModalOpenChildren(false);
  };

  if (data.length < 1) return null;
  return (
    <Modal
      maskClosable={false}
      destroyOnClose
      visible={isModalOpenChildren}
      footer={false}
      onCancel={() => setIsModalOpenChildren(!isModalOpenChildren)}
    >
      <p style={{ marginTop: 30 }}>{t('modal.children.content')}</p>
      <p>
        <Steps current={current} />

        <div className="steps-content">
          <FormInfo
            data={data[current]}
            setInputs={setInputs}
            inputs={inputs}
          />
        </div>
        <div className="steps-action">
          {current < data.length - 1 && (
            <Button
              type="primary"
              htmlType="button"
              onClick={() => next(false, 'next')}
            >
              {t('modal.children.btnNext')}
            </Button>
          )}
          {current === data.length - 1 && (
            <Button
              type="primary"
              htmlType="button"
              onClick={() => next(true, 'end')}
            >
              {t('modal.children.btnFinish')}
            </Button>
          )}
          {/* {current > 0 && (
            <Button
              style={{ margin: '0 8px' }}
              onClick={() => next(false, 'prev')}
            >
              Quay lại
            </Button>
          )} */}
        </div>
      </p>
    </Modal>
  );
};

export default ModalChildInfoQuestion;
