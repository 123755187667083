import React, { useState } from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import Page403 from 'modules/app/Page403';
import Page404 from 'modules/app/Page404';
import Page500 from 'modules/app/Page500';
import PrivateRoute from 'modules/base/PrivateRoute';
import AppLayout from 'modules/app/AppLayout';
import './App.scss';
import Login from 'modules/login/Login';
import { PATH } from 'routes/constants';
import { browserHistory } from 'pkg/route/helper';
import { StoreContext } from './contexts';
import Register from './modules/register/Register';
import VerifyAccount from './modules/verifyAccount/VerifyAccount';
import GetPassword from './modules/getPassword/GetPassword';
import NewPassword from './modules/newPassword/NewPassword';
// import AppHeader from 'modules/app/AppLayout/AppHeader';

const App: React.FC = () => {
  const [user, setUser] = useState(StoreContext);
  return (
    <StoreContext.Provider value={[user, setUser]}>
      <Router history={browserHistory}>
        <Switch>
          <Route exact path="/403" name="403" component={Page403} />
          <Route exact path="/404" name="404" component={Page404} />
          <Route exact path="/500" name="500" component={Page500} />
          <Route
            exact
            path={PATH.VERIFYACCOUNT}
            name="VerifyAccount"
            component={VerifyAccount}
          />
          <Route
            exact
            path={PATH.GET_PASSWORD}
            name="GetPassword"
            component={GetPassword}
          />
          <Route
            exact
            path={PATH.NEW_PASSWORD}
            name="NewPassword"
            component={NewPassword}
          />
          <Route
            exact
            path={PATH.REGISTER}
            name="Register"
            component={Register}
          />
          <Route exact path={PATH.LOGIN} name="Login" component={Login} />
          <PrivateRoute
            path={PATH.HOME}
            name="Home Page"
            component={AppLayout}
          />
        </Switch>
      </Router>
    </StoreContext.Provider>
    // <AppHeader />
  );
};

export default App;
