import { Pagination, Table } from 'antd';
import { t } from 'i18n';
import React, { useEffect } from 'react';
import ButtonSelectDay from './ButtonSelectDay';

type Props = {
  agentId: string;
  loading: boolean;
  getKeywords: any;
  data: any;
  day: any;
  currentPageNumber: any;
};

const KeywordData = ({
  agentId,
  loading,
  data,
  getKeywords,
  day,
  currentPageNumber,
}: Props) => {
  let columnsUrl: any = [
    {
      title: `${t('keywords.title')}`,
      dataIndex: 'keyword',
      key: 'keyword',
    },
    {
      title: `${t('keywords.count')}`,
      dataIndex: 'count',
      key: 'count',
    },
  ];
  useEffect(() => {
    getKeywords(agentId, day);
  }, []);

  return (
    <div>
      <ButtonSelectDay
        actionFunction={(value: any) => getKeywords(agentId, value)}
      />
      <Table
        scroll={{ x: 600, y: 'calc(100vh - 27em)' }}
        dataSource={data}
        columns={columnsUrl}
        pagination={false}
        loading={loading}
        rowKey={(record: any) => record.id}
      />

      <Pagination
        style={{ marginTop: '20px' }}
        onChange={(pageNumber: any) => getKeywords(agentId, day, pageNumber)}
        defaultPageSize={1}
        defaultCurrent={1}
        current={currentPageNumber}
        total={50}
      />
    </div>
  );
};

export default KeywordData;
