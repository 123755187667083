

import { getCookie } from 'pkg/cookie/helpers';
import React, { useEffect, useState } from 'react';
import BlacklistNew from './BlacklistNew';

const BlacklistGroup = () => {
    return <>
        <BlacklistNew isOpenFormLeft={false} />
    </>
}

export default BlacklistGroup;