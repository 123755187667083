import { setCookie } from 'pkg/cookie/helpers';
import {
  AUTHOR_USER,
  KEY_ACCESS_TOKEN,
  KEY_CURRENT_USERNAME,
  KEY_REFRESH_TOKEN,
  KEY_TIMEZONE,
  USER_ROLE,
} from 'pkg/cookie/constants';
import { promotionClient } from 'pkg/request/clients';
import { getData } from 'pkg/request/helper';
import { notification } from 'antd';
import { t } from 'i18n';

export const login = async (
  username: string,
  password: string,
  token: string
) => {
  try {
    const res = await promotionClient
      .post('/auth/login', { username, password, token })
      .then(getData);
    const accessToken = res.access_token;
    const refreshToken = res.refresh_token;
    const timezone = res.timezone;
    setCookie(KEY_CURRENT_USERNAME, res.user_name);
    setCookie(KEY_ACCESS_TOKEN, accessToken);
    setCookie(KEY_REFRESH_TOKEN, refreshToken);
    setCookie(KEY_TIMEZONE, timezone);
    setCookie(AUTHOR_USER, res.admin);
    setCookie(USER_ROLE, res.role);
    return res;

    // const userInfo = await getFullUserInfo();
    // setCookie(KEY_CURRENT_USER_ID, userInfo.id, 1);
  } catch (e) {
    notification.error({
      message: t('Error'),
      description: 'Thông tin tài khoản hoặc mật khẩu chưa chính xác',
    });
    return false;
  }
};
