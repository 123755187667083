import { Button, Form, Input, Modal } from 'antd';
import { t } from 'i18n';
import React from 'react';

const AgentPageModel = (props: any) => {
  let [form] = Form.useForm();
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const handleCancel = () => {
    props.setIsModalVisible(false);
  };

  return (
    <Modal
      title={t('agentManager.modal.title')}
      visible={props.isModalVisible}
      onCancel={handleCancel}
      footer={null}
    >
      <Form
        form={form}
        name="basic"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        onFinish={(value: any) => props.SubmitAgentName(value)}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label={t('agentManager.modal.label')}
          name="agent_name"
          initialValue={props.initialAgentName}
          rules={[
            {
              required: true,
              message: t('notification.input.agent.nameEdit'),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 6, span: 18 }}>
          <Button type="primary" className="mt-1" htmlType="submit">
            {t('agentManager.modal.btnUpdate')}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AgentPageModel;
