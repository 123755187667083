import { Button, Col, Row, Tabs, notification } from 'antd';
import { t } from 'i18n';
import { KEY_TIMEZONE } from 'pkg/cookie/constants';
import { getCookie } from 'pkg/cookie/helpers';
import { promotionClient } from 'pkg/request/clients';
import React, { useEffect, useRef, useState } from 'react';
import KeywordData from './components/Keyword';
import { handleToDay } from './components/action';
import { DoubleRightOutlined } from '@ant-design/icons';
import { PATH } from 'routes/constants';
import { useHistory } from 'react-router-dom';

export default function Keywords() {
  const [staffList, setStaffList] = useState<any>('');
  const [loading, setLoading] = React.useState<boolean>(false);
  const [data, setData] = useState([]);
  const day = useRef(0);
  const currentPageNumber = useRef(1);
  const history = useHistory();

  useEffect(() => {
    getListStaffOfAdminId();
  }, []);

  const { TabPane } = Tabs;

  const getAdminId = () => {
    return getCookie('current_username');
  };

  const getTimeZone = () => {
    return getCookie(KEY_TIMEZONE);
  };

  const getListStaffOfAdminId = () => {
    promotionClient
      .post('/staff_user/admin/list', {
        admin_id: getAdminId(),
        token: '',
      })
      .then((res) => {
        if (res.data.total > 0) {
          setStaffList(res.data.staff_list);
        } else {
          notification.error({
            message: 'Error',
            description: res.data.message,
          });
        }
      })
      .catch((err: any) => {
        notification.error({
          message: t('Error'),
          description: err,
        });
      });
  };
  const getKeywords = (
    agentId: string,
    daySelect: any = null,
    pageNumber?: any
  ) => {
    document.querySelector('div.ant-table-body')!.scrollTop = 0;
    setLoading(true);
    if (daySelect !== null) {
      currentPageNumber.current = 1;
      day.current = daySelect;
    }
    if (pageNumber) currentPageNumber.current = pageNumber;
    promotionClient
      .post(`/url_request/admin/user_query`, {
        admin_id: getAdminId(),
        user_id: agentId,
        date: handleToDay(daySelect || day.current),
        top: 50,
        page: pageNumber || 1,
        timezone: getTimeZone(),
      })
      .then((res: any) => {
        if (res.data.success) {
          setData(res.data.search);
          setLoading(false);
        }
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };
  const getAuthor = () => {
    return getCookie('author_user');
  };
  return (
    <div className="mt-3">
      {getAuthor() === 'standard' ? (
        <div className="card-container">
          <Tabs type="card" onChange={(key: any) => getKeywords(key)}>
            {staffList &&
              staffList.length > 0 &&
              staffList.map((staff: any) => {
                return (
                  <TabPane
                    style={{ padding: 5 }}
                    tab={staff.full_name ?? staff.user_name}
                    key={staff.user_name}
                  >
                    <KeywordData
                      agentId={staff.user_name}
                      loading={loading}
                      data={data}
                      getKeywords={getKeywords}
                      day={day.current}
                      currentPageNumber={currentPageNumber.current || 1}
                    />
                  </TabPane>
                );
              })}
          </Tabs>
        </div>
      ) : (
        <Row justify="center" align="middle" style={{ marginTop: '30px' }}>
          <Col
            span={24}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <h2 style={{ textAlign: 'center' }}>
              {t('whiteList.noti.package')}
            </h2>
            <Button
              type="primary"
              size="large"
              onClick={() => history.push(PATH.BILLING.LIST)}
            >
              {t('billing.step1.btnUpgrade')}
              <DoubleRightOutlined />
            </Button>
          </Col>
        </Row>
      )}
    </div>
  );
}
