import { EditOutlined } from '@ant-design/icons';
import {
  Avatar,
  Button,
  Card,
  Collapse,
  Form,
  Input,
  List,
  notification,
} from 'antd';
import { t } from 'i18n';
import { promotionClient } from 'pkg/request/clients';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { setTimeout } from 'timers';
import { KEY_CURRENT_USERNAME } from '../../pkg/cookie/constants';
import { getCookie } from '../../pkg/cookie/helpers';
import Loader from '../loader/Loader';
import './AddAgent.scss';
import AgentPageModel from './AgentPageModel';
const { Panel } = Collapse;
const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

const tailLayout = {
  wrapperCol: { offset: 6, span: 18 },
};

interface otp {
  username: string;
  id: string;
  OTP: string;
  agent_id: string;
}

export default function AddAgent() {
  const [loading, setLoading] = useState<boolean>(false);
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  let [form] = Form.useForm();
  const [maximumAgentOfAdmin, setMaximumAgentOfAdmin] = useState(0);
  const [isOpenCollapse, setIsOpenCollapse] = useState(['0']);
  const [otps, setOtps] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isOpenVisible, setIsOpenVisible] = useState(false);
  const [agentId, setAgentId] = useState(null);
  const [initialAgentName, setInitialAgentName] = useState('');
  const [myAgentName, setMyAgentName] = useState('');

  useEffect(() => {
    getListAgent();
  }, []);

  const showModal = (agent: any) => {
    setIsModalVisible(true);
    setAgentId(agent.agent_id);
    setInitialAgentName(agent.agent_name);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const getListAgent = () => {
    setLoading(true);
    promotionClient
      .post(`/agent_access/admin/list`, {
        admin_id: getCookie(KEY_CURRENT_USERNAME),
      })
      .then((res: any) => {
        setMaximumAgentOfAdmin(res.data.total);
        if (res.data.total) {
          setOtps(res.data.agent_list);
          localStorage.setItem(
            'listAgent',
            JSON.stringify(res.data.agent_list)
          );
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        // notification.error({
        //   message: t('Error'),
        //   description: err || "err",
        // });
        setLoading(false);
      });
  };

  const onFinish = (values: otp) => {
    values.agent_id = myAgentName;
    getOTP(values);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const handleAlert = (message: string, description: string) => {
    return notification.error({
      message,
      description,
    });
  };

  const getOTP = function (values: any) {
    setBtnLoading(true);
    promotionClient
      .post(`/agent_access/admin/access`, {
        agent_id: values.agent_id,
        otp: values.OTP,
        admin_id: getCookie(KEY_CURRENT_USERNAME),
      })
      .then((res: any) => {
        const { code } = res.data;
        if (code === '200') {
          checkAccept(values);
        } else if (code === '201') {
          setIsOpenCollapse((prev) => ['0']);
          form.resetFields();
          handleAlert('Success', 'Agent took the accepted, Login successfully');
          handleDisplayAgent(values);
        } else if (code === '403') {
          handleAlert(res.data.status, 'accounts with are maximum of 4 agents');
        }
      })
      .catch((err) => {
        notification.error({
          message: t('Error'),
          description: err,
        });
      });
  };

  const getAdminId = () => {
    return getCookie('current_username');
  };

  function LoadingCP() {
    if (loading) {
      return <Loader />;
    }
    return <div />;
  }

  const checkAccept = (values: any) => {
    setLoading(true);
    let accepted = false;
    const loopInterval = setInterval(function () {
      promotionClient
        .post(`/agent_access/admin/check`, {
          agent_id: values.agent_id,
          admin_id: getAdminId(),
        })
        .then((res: any) => {
          accepted = res.data.accepted;
          if (accepted) {
            setLoading(false);
            setBtnLoading(false);
            clearInterval(loopInterval);
            handleDisplayAgent(values);
            form.resetFields();
          }
        })
        .catch((err) => {
          notification.error({
            message: t('Error'),
            description: err,
          });
        });
    }, 2000);

    setTimeout(() => {
      if (!accepted) {
        clearInterval(loopInterval);
        form.resetFields();
        setLoading(false);
        setBtnLoading(false);
        notification.error({
          message: t('Error'),
          description: 'Timeout has expired, please re-enter a new OTP',
        });
      }
    }, 40000);
  };

  const handleDisplayAgent = (values: any) => {
    setBtnLoading(false);
    if (values) {
      getListAgent();
    }
  };

  const SubmitAgentName = (value: any) => {
    promotionClient
      .post(`/agent/admin/name/set`, {
        name: value.agent_name,
        agent_id: agentId,
      })
      .then((res) => {
        handleOk();
        if (res.data.success) {
          notification.success({
            message: t('Success'),
            description: t('notification.agent.update.success'),
          });
          getListAgent();
        } else {
          notification.error({
            message: t('Error'),
            description: res.data.message,
          });
        }
      })
      .catch((err) => {
        notification.error({
          message: t('Error'),
          description: err,
        });
      });
  };
  const handleClick = (agentName: any) => {
    if (agentName && agentName !== '') {
      localStorage.setItem('saveAgentName', agentName);
    }
  };
  const [agentName, setAgentName] = useState('');
  const updateAgentName = (agentName: any) => {
    setAgentName(agentName);
  };
  const handleOpenCollapse = (isOpen: any) => {
    setIsOpenVisible(!isOpen);
    if (isOpenVisible) {
      setIsOpenCollapse((prev) => ['0']);
    } else {
      setIsOpenCollapse((prev) => ['1']);
    }
  };

  const handleChange = (value: string) => {
    value = value.replace(/[\-\s]+/g, '');
    setMyAgentName(value);
  };
  const onCL = (item: any) => {
    debugger;
    console.log(item);
  };
  return (
    <div className="mt-5 pl-2 pt-2 pb-2 pr-2 add-agent-page">
      <Collapse
        activeKey={isOpenCollapse}
        onChange={() => handleOpenCollapse(isOpenVisible)}
      >
        <Panel header={t('connect_Agent')} key="1">
          <Form
            form={form}
            {...layout}
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              className="mt-1"
              label="ID"
              name="agent_id"
              rules={[{ required: true, message: t('required.AgentId') }]}
            >
              <Input onChange={(e) => handleChange(e.target.value)} />
            </Form.Item>

            <Form.Item
              className="mt-1"
              label="OTP"
              name="OTP"
              rules={[
                { required: true, message: t('required.importOtp') },
                {
                  required: true,
                  message: t('required.notSpace'),
                  pattern: new RegExp(/^[a-zA-Z0-9.\-_$@*!]{1,30}$/),
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item {...tailLayout} className="mt-1">
              <Button loading={btnLoading} type="primary" htmlType="submit">
                {t('connect_Agent_connect')}
              </Button>
            </Form.Item>
          </Form>
        </Panel>
      </Collapse>

      {!!otps.length ? (
        <List
          style={{ marginTop: 20, padding: 5 }}
          grid={{
            gutter: 16,
            xs: 1,
            sm: 2,
            md: 2,
            lg: 2,
            xl: 2,
            xxl: 2,
          }}
          dataSource={otps}
          renderItem={(item: any, index) => (
            <List.Item key={index + 1}>
              <EditOutlined
                style={{ fontSize: '150%' }}
                className="update-name-agent"
                onClick={() => showModal(item)}
              />
              <Link
                onClick={() => handleClick(item.agent_name)}
                to={`/agentpage/${item.agent_id}/tabs/2`}
              >
                <Card
                  className="listUserTitle"
                  title={`Agent ID: ${item.agent_id} ${
                    item.agent_name ? '(' + item.agent_name + ')' : ''
                  }`}
                >
                  <div className="d-flex justify-content-between">
                    <div className="userSwitchBtn">
                      {/* <Switch defaultChecked /> */}
                    </div>
                    <div>
                      <Button type="primary">{t('Agent_manage')}</Button>
                    </div>
                    <div className="userImg">
                      <Avatar src="https://png.pngtree.com/png-vector/20190710/ourmid/pngtree-user-vector-avatar-png-image_1541962.jpg" />
                    </div>
                  </div>
                </Card>
              </Link>

              <AgentPageModel
                SubmitAgentName={SubmitAgentName}
                initialAgentName={initialAgentName}
                setIsModalVisible={setIsModalVisible}
                isModalVisible={isModalVisible}
              />
            </List.Item>
          )}
        />
      ) : (
        ''
      )}
      <LoadingCP />
    </div>
  );
}
