// source: service_stream_qr_pay.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.app.safeweb.v1.BankInfo', null, global);
goog.exportSymbol('proto.app.safeweb.v1.GetQRpayReq', null, global);
goog.exportSymbol('proto.app.safeweb.v1.GetQRpayRes', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
const COMPILED = undefined;
const proto = {};
proto.app = {};
proto.app.safeweb = {};
proto.app.safeweb.v1 = {};
proto.app.safeweb.v1.GetQRpayReq = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.safeweb.v1.GetQRpayReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.safeweb.v1.GetQRpayReq.displayName =
    'proto.app.safeweb.v1.GetQRpayReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.safeweb.v1.BankInfo = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.safeweb.v1.BankInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.safeweb.v1.BankInfo.displayName = 'proto.app.safeweb.v1.BankInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.safeweb.v1.GetQRpayRes = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.safeweb.v1.GetQRpayRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.safeweb.v1.GetQRpayRes.displayName =
    'proto.app.safeweb.v1.GetQRpayRes';
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.app.safeweb.v1.GetQRpayReq.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.app.safeweb.v1.GetQRpayReq.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.app.safeweb.v1.GetQRpayReq} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.app.safeweb.v1.GetQRpayReq.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        adminId: jspb.Message.getFieldWithDefault(msg, 1, ''),
        adminTitle: jspb.Message.getFieldWithDefault(msg, 2, ''),
        adminName: jspb.Message.getFieldWithDefault(msg, 3, ''),
        adminPhone: jspb.Message.getFieldWithDefault(msg, 4, ''),
        adminCountry: jspb.Message.getFieldWithDefault(msg, 5, ''),
        orderTotal: jspb.Message.getFieldWithDefault(msg, 6, 0),
        orderInfo: jspb.Message.getFieldWithDefault(msg, 7, ''),
        productName: jspb.Message.getFieldWithDefault(msg, 8, ''),
        productCode: jspb.Message.getFieldWithDefault(msg, 9, ''),
        productPrice: jspb.Message.getFieldWithDefault(msg, 10, 0),
        productUnit: jspb.Message.getFieldWithDefault(msg, 11, ''),
        productDiscount: jspb.Message.getFieldWithDefault(msg, 12, 0),
        productTax: jspb.Message.getFieldWithDefault(msg, 13, 0),
        productQuantity: jspb.Message.getFieldWithDefault(msg, 14, 0),
        productDescription: jspb.Message.getFieldWithDefault(msg, 15, ''),
        coupon: jspb.Message.getFieldWithDefault(msg, 16, ''),
        returnUrl: jspb.Message.getFieldWithDefault(msg, 17, ''),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.safeweb.v1.GetQRpayReq}
 */
proto.app.safeweb.v1.GetQRpayReq.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.safeweb.v1.GetQRpayReq();
  return proto.app.safeweb.v1.GetQRpayReq.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.safeweb.v1.GetQRpayReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.safeweb.v1.GetQRpayReq}
 */
proto.app.safeweb.v1.GetQRpayReq.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setAdminId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setAdminTitle(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setAdminName(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setAdminPhone(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setAdminCountry(value);
        break;
      case 6:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setOrderTotal(value);
        break;
      case 7:
        var value = /** @type {string} */ (reader.readString());
        msg.setOrderInfo(value);
        break;
      case 8:
        var value = /** @type {string} */ (reader.readString());
        msg.setProductName(value);
        break;
      case 9:
        var value = /** @type {string} */ (reader.readString());
        msg.setProductCode(value);
        break;
      case 10:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setProductPrice(value);
        break;
      case 11:
        var value = /** @type {string} */ (reader.readString());
        msg.setProductUnit(value);
        break;
      case 12:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setProductDiscount(value);
        break;
      case 13:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setProductTax(value);
        break;
      case 14:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setProductQuantity(value);
        break;
      case 15:
        var value = /** @type {string} */ (reader.readString());
        msg.setProductDescription(value);
        break;
      case 16:
        var value = /** @type {string} */ (reader.readString());
        msg.setCoupon(value);
        break;
      case 17:
        var value = /** @type {string} */ (reader.readString());
        msg.setReturnUrl(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.safeweb.v1.GetQRpayReq.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.app.safeweb.v1.GetQRpayReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.safeweb.v1.GetQRpayReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.safeweb.v1.GetQRpayReq.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getAdminId();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getAdminTitle();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
  f = message.getAdminName();
  if (f.length > 0) {
    writer.writeString(3, f);
  }
  f = message.getAdminPhone();
  if (f.length > 0) {
    writer.writeString(4, f);
  }
  f = message.getAdminCountry();
  if (f.length > 0) {
    writer.writeString(5, f);
  }
  f = message.getOrderTotal();
  if (f !== 0) {
    writer.writeInt64(6, f);
  }
  f = message.getOrderInfo();
  if (f.length > 0) {
    writer.writeString(7, f);
  }
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(8, f);
  }
  f = message.getProductCode();
  if (f.length > 0) {
    writer.writeString(9, f);
  }
  f = message.getProductPrice();
  if (f !== 0) {
    writer.writeInt64(10, f);
  }
  f = message.getProductUnit();
  if (f.length > 0) {
    writer.writeString(11, f);
  }
  f = message.getProductDiscount();
  if (f !== 0) {
    writer.writeInt64(12, f);
  }
  f = message.getProductTax();
  if (f !== 0) {
    writer.writeInt64(13, f);
  }
  f = message.getProductQuantity();
  if (f !== 0) {
    writer.writeInt32(14, f);
  }
  f = message.getProductDescription();
  if (f.length > 0) {
    writer.writeString(15, f);
  }
  f = message.getCoupon();
  if (f.length > 0) {
    writer.writeString(16, f);
  }
  f = message.getReturnUrl();
  if (f.length > 0) {
    writer.writeString(17, f);
  }
};

/**
 * optional string admin_id = 1;
 * @return {string}
 */
proto.app.safeweb.v1.GetQRpayReq.prototype.getAdminId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.app.safeweb.v1.GetQRpayReq} returns this
 */
proto.app.safeweb.v1.GetQRpayReq.prototype.setAdminId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string admin_title = 2;
 * @return {string}
 */
proto.app.safeweb.v1.GetQRpayReq.prototype.getAdminTitle = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};

/**
 * @param {string} value
 * @return {!proto.app.safeweb.v1.GetQRpayReq} returns this
 */
proto.app.safeweb.v1.GetQRpayReq.prototype.setAdminTitle = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional string admin_name = 3;
 * @return {string}
 */
proto.app.safeweb.v1.GetQRpayReq.prototype.getAdminName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};

/**
 * @param {string} value
 * @return {!proto.app.safeweb.v1.GetQRpayReq} returns this
 */
proto.app.safeweb.v1.GetQRpayReq.prototype.setAdminName = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};

/**
 * optional string admin_phone = 4;
 * @return {string}
 */
proto.app.safeweb.v1.GetQRpayReq.prototype.getAdminPhone = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''));
};

/**
 * @param {string} value
 * @return {!proto.app.safeweb.v1.GetQRpayReq} returns this
 */
proto.app.safeweb.v1.GetQRpayReq.prototype.setAdminPhone = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};

/**
 * optional string admin_country = 5;
 * @return {string}
 */
proto.app.safeweb.v1.GetQRpayReq.prototype.getAdminCountry = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''));
};

/**
 * @param {string} value
 * @return {!proto.app.safeweb.v1.GetQRpayReq} returns this
 */
proto.app.safeweb.v1.GetQRpayReq.prototype.setAdminCountry = function (value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};

/**
 * optional int64 order_total = 6;
 * @return {number}
 */
proto.app.safeweb.v1.GetQRpayReq.prototype.getOrderTotal = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};

/**
 * @param {number} value
 * @return {!proto.app.safeweb.v1.GetQRpayReq} returns this
 */
proto.app.safeweb.v1.GetQRpayReq.prototype.setOrderTotal = function (value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};

/**
 * optional string order_info = 7;
 * @return {string}
 */
proto.app.safeweb.v1.GetQRpayReq.prototype.getOrderInfo = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ''));
};

/**
 * @param {string} value
 * @return {!proto.app.safeweb.v1.GetQRpayReq} returns this
 */
proto.app.safeweb.v1.GetQRpayReq.prototype.setOrderInfo = function (value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};

/**
 * optional string product_name = 8;
 * @return {string}
 */
proto.app.safeweb.v1.GetQRpayReq.prototype.getProductName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ''));
};

/**
 * @param {string} value
 * @return {!proto.app.safeweb.v1.GetQRpayReq} returns this
 */
proto.app.safeweb.v1.GetQRpayReq.prototype.setProductName = function (value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};

/**
 * optional string product_code = 9;
 * @return {string}
 */
proto.app.safeweb.v1.GetQRpayReq.prototype.getProductCode = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ''));
};

/**
 * @param {string} value
 * @return {!proto.app.safeweb.v1.GetQRpayReq} returns this
 */
proto.app.safeweb.v1.GetQRpayReq.prototype.setProductCode = function (value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};

/**
 * optional int64 product_price = 10;
 * @return {number}
 */
proto.app.safeweb.v1.GetQRpayReq.prototype.getProductPrice = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};

/**
 * @param {number} value
 * @return {!proto.app.safeweb.v1.GetQRpayReq} returns this
 */
proto.app.safeweb.v1.GetQRpayReq.prototype.setProductPrice = function (value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};

/**
 * optional string product_unit = 11;
 * @return {string}
 */
proto.app.safeweb.v1.GetQRpayReq.prototype.getProductUnit = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ''));
};

/**
 * @param {string} value
 * @return {!proto.app.safeweb.v1.GetQRpayReq} returns this
 */
proto.app.safeweb.v1.GetQRpayReq.prototype.setProductUnit = function (value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};

/**
 * optional int64 product_discount = 12;
 * @return {number}
 */
proto.app.safeweb.v1.GetQRpayReq.prototype.getProductDiscount = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};

/**
 * @param {number} value
 * @return {!proto.app.safeweb.v1.GetQRpayReq} returns this
 */
proto.app.safeweb.v1.GetQRpayReq.prototype.setProductDiscount = function (
  value
) {
  return jspb.Message.setProto3IntField(this, 12, value);
};

/**
 * optional int64 product_tax = 13;
 * @return {number}
 */
proto.app.safeweb.v1.GetQRpayReq.prototype.getProductTax = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};

/**
 * @param {number} value
 * @return {!proto.app.safeweb.v1.GetQRpayReq} returns this
 */
proto.app.safeweb.v1.GetQRpayReq.prototype.setProductTax = function (value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};

/**
 * optional int32 product_quantity = 14;
 * @return {number}
 */
proto.app.safeweb.v1.GetQRpayReq.prototype.getProductQuantity = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};

/**
 * @param {number} value
 * @return {!proto.app.safeweb.v1.GetQRpayReq} returns this
 */
proto.app.safeweb.v1.GetQRpayReq.prototype.setProductQuantity = function (
  value
) {
  return jspb.Message.setProto3IntField(this, 14, value);
};

/**
 * optional string product_description = 15;
 * @return {string}
 */
proto.app.safeweb.v1.GetQRpayReq.prototype.getProductDescription = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ''));
};

/**
 * @param {string} value
 * @return {!proto.app.safeweb.v1.GetQRpayReq} returns this
 */
proto.app.safeweb.v1.GetQRpayReq.prototype.setProductDescription = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 15, value);
};

/**
 * optional string coupon = 16;
 * @return {string}
 */
proto.app.safeweb.v1.GetQRpayReq.prototype.getCoupon = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ''));
};

/**
 * @param {string} value
 * @return {!proto.app.safeweb.v1.GetQRpayReq} returns this
 */
proto.app.safeweb.v1.GetQRpayReq.prototype.setCoupon = function (value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};

/**
 * optional string return_url = 17;
 * @return {string}
 */
proto.app.safeweb.v1.GetQRpayReq.prototype.getReturnUrl = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ''));
};

/**
 * @param {string} value
 * @return {!proto.app.safeweb.v1.GetQRpayReq} returns this
 */
proto.app.safeweb.v1.GetQRpayReq.prototype.setReturnUrl = function (value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.app.safeweb.v1.BankInfo.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.app.safeweb.v1.BankInfo.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.app.safeweb.v1.BankInfo} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.app.safeweb.v1.BankInfo.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        bankKey: jspb.Message.getFieldWithDefault(msg, 1, ''),
        bankCode: jspb.Message.getFieldWithDefault(msg, 2, ''),
        bankBin: jspb.Message.getFieldWithDefault(msg, 3, ''),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.safeweb.v1.BankInfo}
 */
proto.app.safeweb.v1.BankInfo.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.safeweb.v1.BankInfo();
  return proto.app.safeweb.v1.BankInfo.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.safeweb.v1.BankInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.safeweb.v1.BankInfo}
 */
proto.app.safeweb.v1.BankInfo.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setBankKey(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setBankCode(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setBankBin(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.safeweb.v1.BankInfo.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.app.safeweb.v1.BankInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.safeweb.v1.BankInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.safeweb.v1.BankInfo.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getBankKey();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getBankCode();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
  f = message.getBankBin();
  if (f.length > 0) {
    writer.writeString(3, f);
  }
};

/**
 * optional string bank_key = 1;
 * @return {string}
 */
proto.app.safeweb.v1.BankInfo.prototype.getBankKey = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.app.safeweb.v1.BankInfo} returns this
 */
proto.app.safeweb.v1.BankInfo.prototype.setBankKey = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string bank_code = 2;
 * @return {string}
 */
proto.app.safeweb.v1.BankInfo.prototype.getBankCode = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};

/**
 * @param {string} value
 * @return {!proto.app.safeweb.v1.BankInfo} returns this
 */
proto.app.safeweb.v1.BankInfo.prototype.setBankCode = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional string bank_bin = 3;
 * @return {string}
 */
proto.app.safeweb.v1.BankInfo.prototype.getBankBin = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};

/**
 * @param {string} value
 * @return {!proto.app.safeweb.v1.BankInfo} returns this
 */
proto.app.safeweb.v1.BankInfo.prototype.setBankBin = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.app.safeweb.v1.GetQRpayRes.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.app.safeweb.v1.GetQRpayRes.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.app.safeweb.v1.GetQRpayRes} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.app.safeweb.v1.GetQRpayRes.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        signal: jspb.Message.getFieldWithDefault(msg, 1, ''),
        message: jspb.Message.getFieldWithDefault(msg, 2, ''),
        bankInfo:
          (f = msg.getBankInfo()) &&
          proto.app.safeweb.v1.BankInfo.toObject(includeInstance, f),
        accountNumber: jspb.Message.getFieldWithDefault(msg, 4, ''),
        accountName: jspb.Message.getFieldWithDefault(msg, 5, ''),
        payAmount: jspb.Message.getFieldWithDefault(msg, 6, 0),
        payPurpose: jspb.Message.getFieldWithDefault(msg, 7, ''),
        payCode: jspb.Message.getFieldWithDefault(msg, 8, 0),
        payStatus: jspb.Message.getFieldWithDefault(msg, 9, ''),
        payData: jspb.Message.getFieldWithDefault(msg, 10, 0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.safeweb.v1.GetQRpayRes}
 */
proto.app.safeweb.v1.GetQRpayRes.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.safeweb.v1.GetQRpayRes();
  return proto.app.safeweb.v1.GetQRpayRes.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.safeweb.v1.GetQRpayRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.safeweb.v1.GetQRpayRes}
 */
proto.app.safeweb.v1.GetQRpayRes.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setSignal(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setMessage(value);
        break;
      case 3:
        var value = new proto.app.safeweb.v1.BankInfo();
        reader.readMessage(
          value,
          proto.app.safeweb.v1.BankInfo.deserializeBinaryFromReader
        );
        msg.setBankInfo(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setAccountNumber(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setAccountName(value);
        break;
      case 6:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setPayAmount(value);
        break;
      case 7:
        var value = /** @type {string} */ (reader.readString());
        msg.setPayPurpose(value);
        break;
      case 8:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setPayCode(value);
        break;
      case 9:
        var value = /** @type {string} */ (reader.readString());
        msg.setPayStatus(value);
        break;
      case 10:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setPayData(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.safeweb.v1.GetQRpayRes.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.app.safeweb.v1.GetQRpayRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.safeweb.v1.GetQRpayRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.safeweb.v1.GetQRpayRes.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getSignal();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
  f = message.getBankInfo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.app.safeweb.v1.BankInfo.serializeBinaryToWriter
    );
  }
  f = message.getAccountNumber();
  if (f.length > 0) {
    writer.writeString(4, f);
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(5, f);
  }
  f = message.getPayAmount();
  if (f !== 0) {
    writer.writeInt64(6, f);
  }
  f = message.getPayPurpose();
  if (f.length > 0) {
    writer.writeString(7, f);
  }
  f = message.getPayCode();
  if (f !== 0) {
    writer.writeInt32(8, f);
  }
  f = message.getPayStatus();
  if (f.length > 0) {
    writer.writeString(9, f);
  }
  f = message.getPayData();
  if (f !== 0) {
    writer.writeInt64(10, f);
  }
};

/**
 * optional string signal = 1;
 * @return {string}
 */
proto.app.safeweb.v1.GetQRpayRes.prototype.getSignal = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.app.safeweb.v1.GetQRpayRes} returns this
 */
proto.app.safeweb.v1.GetQRpayRes.prototype.setSignal = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string message = 2;
 * @return {string}
 */
proto.app.safeweb.v1.GetQRpayRes.prototype.getMessage = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};

/**
 * @param {string} value
 * @return {!proto.app.safeweb.v1.GetQRpayRes} returns this
 */
proto.app.safeweb.v1.GetQRpayRes.prototype.setMessage = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional BankInfo bank_info = 3;
 * @return {?proto.app.safeweb.v1.BankInfo}
 */
proto.app.safeweb.v1.GetQRpayRes.prototype.getBankInfo = function () {
  return /** @type{?proto.app.safeweb.v1.BankInfo} */ (
    jspb.Message.getWrapperField(this, proto.app.safeweb.v1.BankInfo, 3)
  );
};

/**
 * @param {?proto.app.safeweb.v1.BankInfo|undefined} value
 * @return {!proto.app.safeweb.v1.GetQRpayRes} returns this
 */
proto.app.safeweb.v1.GetQRpayRes.prototype.setBankInfo = function (value) {
  return jspb.Message.setWrapperField(this, 3, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.app.safeweb.v1.GetQRpayRes} returns this
 */
proto.app.safeweb.v1.GetQRpayRes.prototype.clearBankInfo = function () {
  return this.setBankInfo(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.safeweb.v1.GetQRpayRes.prototype.hasBankInfo = function () {
  return jspb.Message.getField(this, 3) != null;
};

/**
 * optional string account_number = 4;
 * @return {string}
 */
proto.app.safeweb.v1.GetQRpayRes.prototype.getAccountNumber = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''));
};

/**
 * @param {string} value
 * @return {!proto.app.safeweb.v1.GetQRpayRes} returns this
 */
proto.app.safeweb.v1.GetQRpayRes.prototype.setAccountNumber = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};

/**
 * optional string account_name = 5;
 * @return {string}
 */
proto.app.safeweb.v1.GetQRpayRes.prototype.getAccountName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''));
};

/**
 * @param {string} value
 * @return {!proto.app.safeweb.v1.GetQRpayRes} returns this
 */
proto.app.safeweb.v1.GetQRpayRes.prototype.setAccountName = function (value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};

/**
 * optional int64 pay_amount = 6;
 * @return {number}
 */
proto.app.safeweb.v1.GetQRpayRes.prototype.getPayAmount = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};

/**
 * @param {number} value
 * @return {!proto.app.safeweb.v1.GetQRpayRes} returns this
 */
proto.app.safeweb.v1.GetQRpayRes.prototype.setPayAmount = function (value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};

/**
 * optional string pay_purpose = 7;
 * @return {string}
 */
proto.app.safeweb.v1.GetQRpayRes.prototype.getPayPurpose = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ''));
};

/**
 * @param {string} value
 * @return {!proto.app.safeweb.v1.GetQRpayRes} returns this
 */
proto.app.safeweb.v1.GetQRpayRes.prototype.setPayPurpose = function (value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};

/**
 * optional int32 pay_code = 8;
 * @return {number}
 */
proto.app.safeweb.v1.GetQRpayRes.prototype.getPayCode = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};

/**
 * @param {number} value
 * @return {!proto.app.safeweb.v1.GetQRpayRes} returns this
 */
proto.app.safeweb.v1.GetQRpayRes.prototype.setPayCode = function (value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};

/**
 * optional string pay_status = 9;
 * @return {string}
 */
proto.app.safeweb.v1.GetQRpayRes.prototype.getPayStatus = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ''));
};

/**
 * @param {string} value
 * @return {!proto.app.safeweb.v1.GetQRpayRes} returns this
 */
proto.app.safeweb.v1.GetQRpayRes.prototype.setPayStatus = function (value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};

/**
 * optional int64 pay_data = 10;
 * @return {number}
 */
proto.app.safeweb.v1.GetQRpayRes.prototype.getPayData = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};

/**
 * @param {number} value
 * @return {!proto.app.safeweb.v1.GetQRpayRes} returns this
 */
proto.app.safeweb.v1.GetQRpayRes.prototype.setPayData = function (value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};

goog.object.extend(exports, proto.app.safeweb.v1);

module.exports = proto.app.safeweb.v1;
