import { DoubleRightOutlined } from '@ant-design/icons';
import { Button, Col, Row, Table } from 'antd';
import { t } from 'i18n';
import { getCookie } from 'pkg/cookie/helpers';
import { promotionClient } from 'pkg/request/clients';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PATH } from 'routes/constants';

const ChartKeyword = ({
  handleToDay,
  getTimeZone,
  getAdminId,
  userId,
  dateSelected,
}: any) => {
  const [keywords, setKeywords] = useState<any>([]);
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getApiKeyword(userId, dateSelected);
  }, [userId, dateSelected]);

  const getAuthor = () => {
    return getCookie('author_user');
  };

  const getApiKeyword = (user_id: string, dateSelected: number = 0) => {
    if (getAuthor() === 'standard') {
      setLoading(true);
      promotionClient
        .post(`/url_request/admin/user_query`, {
          admin_id: getAdminId(),
          user_id,
          sort: 'desc',
          date: handleToDay(dateSelected),
          top: 50,
          timezone: getTimeZone(),
          page: 1,
        })
        .then((res: any) => {
          setKeywords(res.data.search);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setKeywords([]);
    }
  };

  let columnsUrl: any = [
    {
      title: `${t('keywords.title')}`,
      dataIndex: 'keyword',
      key: 'keyword',
    },
    {
      title: `${t('keywords.count')}`,
      dataIndex: 'count',
      key: 'count',
    },
  ];

  return (
    // <Spin spinning={loading} tip="Loading...">
    <>
      {getAuthor() === 'standard' ? (
        <Table
          //   scroll={{ x: 600, y: 'calc(100vh - 27em)' }}
          dataSource={keywords}
          columns={columnsUrl}
          pagination={false}
          rowKey={(record: any) => record.id}
          loading={loading}
        />
      ) : (
        <Row justify="center" align="middle" style={{ marginTop: '30px' }}>
          <Col
            span={24}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <h2 style={{ textAlign: 'center' }}>
              {t('whiteList.noti.package')}
            </h2>
            <Button
              type="primary"
              size="large"
              onClick={() => history.push(PATH.BILLING.LIST)}
            >
              {t('whiteList.noti.btnUpgrade')}
              <DoubleRightOutlined />
            </Button>
          </Col>
        </Row>
      )}
    </>
    // </Spin>
  );
};

export default ChartKeyword;
