import { Col, Modal, Row } from 'antd';
import { t } from 'i18n';
import React, { useEffect, useRef, useState } from 'react';
import SelectDayOfWeek from './SelectDayOfWeek';
import { LocalizationProvider, MobileTimePicker } from '@mui/x-date-pickers';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import moment from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import SelectTime from './SelectTime';

type Props = {
  isModalOpen: boolean;
  setIsModalOpen: (setIsModalOpen: boolean) => void;
  user: any;
  dayOfWeek: any;
  weekdaysSelect: any;
  type: any;
  handleChangeSelectDay: (item: any) => any;
  handleOk: (data: any) => void;
};

const ModalSelectDays = (props: Props) => {
  const {
    isModalOpen,
    setIsModalOpen,
    user,
    dayOfWeek,
    weekdaysSelect,
    type,
    handleChangeSelectDay,
    handleOk,
  } = props;

  const handleCancel = () => {
    setIsModalOpen(!isModalOpen);
  };
  const startWeekDay = useRef('');
  const stopWeekDay = useRef('');
  const startWeekend = useRef('');
  const stopWeekend = useRef('');
  const onChangeTime = (timeString: string, type: string) => {
    const time = timeString + ':00';
    if (type === 'startWeekDay') startWeekDay.current = time;
    else if (type === 'stopWeekDay') stopWeekDay.current = time;
    else if (type === 'startWeekend') startWeekend.current = time;
    else if (type === 'stopWeekend') stopWeekend.current = time;

    console.log('time', startWeekDay, stopWeekDay, startWeekend, stopWeekend);
  };

  const handOkOnModal = () => {
    const day = [...dayOfWeek];
    const data = [
      {
        name: 'Weekdays',
        repeater: weekdaysSelect,
        time_action: [
          {
            type: user.run_mode || 'normal',
            start:
              startWeekDay.current ||
              user.list.find((item: any) => item.name === 'Weekdays')
                .time_action[0].start,
            stop:
              stopWeekDay.current ||
              user.list.find((item: any) => item.name === 'Weekdays')
                .time_action[0].stop,
          },
        ],
      },
      {
        name: 'Weekend',
        repeater: day.filter((x: any) => !weekdaysSelect.includes(x)),
        time_action: [
          {
            type: user.run_mode || 'normal',
            start:
              startWeekend.current ||
              user.list.find((item: any) => item.name !== 'Weekdays')
                .time_action[0].start,
            stop:
              stopWeekend.current ||
              user.list.find((item: any) => item.name !== 'Weekdays')
                .time_action[0].stop,
          },
        ],
      },
    ];

    handleOk(data);
  };

  return (
    <>
      <Modal
        title=""
        visible={isModalOpen}
        onOk={handOkOnModal}
        onCancel={handleCancel}
        width={400}
      >
        {user &&
          user.list.map((item: any, index: number) => (
            <div>
              <Row>
                {item.name === 'Weekdays' ? (
                  <Col span={24}>{t('blockApp.modal.selectDayOfWeek')}:</Col>
                ) : (
                  <Col span={24}>{t('blockApp.modal.selectDayOfWeekend')}:</Col>
                )}
                <Col span={24}>
                  <SelectDayOfWeek
                    isModalOpen={isModalOpen}
                    dayOfWeek={dayOfWeek}
                    weekdaysSelect={weekdaysSelect}
                    type={`${
                      item.name === 'Weekdays' ? 'dayOfWeek' : 'weekend'
                    }`}
                    handleChangeSelectDay={(item: any) =>
                      handleChangeSelectDay(item)
                    }
                  />
                </Col>
              </Row>
              <Row
                style={{ display: 'flex', flexDirection: 'row' }}
                className="card-style"
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '50%',
                    alignItems: 'center',
                  }}
                >
                  <div>{t('startTime')}:</div>
                  <div>
                    <SelectTime
                      timers={item.time_action[0].start}
                      onChangeTime={(time: string) =>
                        onChangeTime(
                          time,
                          `${
                            item.name === 'Weekdays'
                              ? 'startWeekDay'
                              : 'startWeekend'
                          }`
                        )
                      }
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '50%',
                    alignItems: 'center',
                  }}
                >
                  <div>{t('stopTime')}:</div>
                  <div>
                    <SelectTime
                      timers={item.time_action[0].stop}
                      onChangeTime={(time: string) =>
                        onChangeTime(
                          time,
                          `${
                            item.name === 'Weekdays'
                              ? 'stopWeekDay'
                              : 'stopWeekend'
                          }`
                        )
                      }
                    />
                  </div>
                </div>
              </Row>
            </div>
          ))}
      </Modal>
    </>
  );
};

export default ModalSelectDays;
