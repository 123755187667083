import React, { useEffect, useState } from 'react';
import { Button, Col, DatePicker, Row, Select, Table } from 'antd';
import axios from 'axios'
import moment from 'moment';
import { promotionClient } from '../../pkg/request/clients';
interface Apps {
  id: string;
  agent_id: string;
  user_id: string;
  app_name: string;
  start_time: string;
  stop_time: string;
  usage_time: string
}
const { Option } = Select;
const columns = [
  {
    title: 'Tên ứng dụng',
    dataIndex: 'app_name',
    key: 'app_name',
  },
  {
    title: 'Thời gian bắt đầu',
    dataIndex: 'start_time',
    key: 'start_time',
  },
  {
    title: 'Thời gian kết thúc',
    dataIndex: 'stop_time',
    key: 'stop_time',
  },
  {
    title: 'Thời gian sử dụng',
    dataIndex: 'usage_time',
    key: 'usage_time',
  },
  {
    title: 'Thao tác',
    dataIndex: 'action',
    key: 'action',
    render: (text: any, record: any) => (
      <div>
        <Button type={'primary'}>Block</Button>
      </div>
    ),
  },
];

function Website() {
  const [apps, setApps] = useState<Array<Apps>>([])
  const [FilterType, setFilterType] = useState<string>('')
  useEffect(() => {
    getApplication();
  }, [])

  function getApplication ()  {
    let today = moment().format("YYYY-MM-DD")
    promotionClient.get(`/app_usages?date=${today}`)
      .then(res => {
        setApps(res.data.apps)
      }).catch(err => {
      alert(err)
    })
  }

  function chooseDay (day: any) {
    const today = moment(day).format("YYYY-MM-DD")
    promotionClient.get(`/app_usages?date=${today}`)
      .then(res => {
        setApps(res.data.apps)
      }).catch(err => {
      alert(err)
    })
  }

  function chooseFilter (value: string) {
    setFilterType(value)
  }


  function ReRenderDatePicker () {
    if (FilterType === 'week') {
      return (<DatePicker placeholder="Hãy chọn tuần" onChange={chooseDay} />)
    } else if (FilterType === 'month') {
      return (<DatePicker onChange={chooseDay} placeholder="Hãy chọn tháng"  picker="month" />)
    }
    return (<DatePicker  onChange={chooseDay}  />)
  }

  return (
    <div className="container">
      <Row gutter={[8, 8]}>
        <Col span={24}>
          <Select className="ml-1 mr-1"
                  defaultValue="Tùy Chọn tìm kiếm"
                  onChange={chooseFilter}
          >
            <Option value={'day'}>
              Tìm theo ngày
            </Option>
            <Option  value={'week'}>
              Tìm theo tuần
            </Option>
            <Option  value={'month'}>
              Tìm theo tháng
            </Option>
          </Select>

          <ReRenderDatePicker />

        </Col>
      </Row>
      <Row gutter={[8, 8]}>
        <Col span={24}>
          <Table
            rowKey={record => record.id}
            dataSource={apps}
            columns={columns}
          />
        </Col>
      </Row>
    </div>
  )
}

export default Website;
