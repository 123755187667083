import { Button, Form, Input } from 'antd';
import { t } from 'i18n';
import React from 'react';

const EditStaff = (props: any) => {
  console.log(props.staff);
  const [form] = Form.useForm();
  const layout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 19 },
  };
  const tailLayout = {
    wrapperCol: { offset: 5, span: 19 },
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const { staff } = props;

  return (
    <>
      <Form
        {...layout}
        form={form}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={props.handleSubmitEditStaff}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label={t('Register.fullname')}
          name="fullName"
          className="staff-item"
          rules={[
            {
              required: true,
              message: t('notification.input.create.user.fullname'),
            },
          ]}
        >
          <Input {...staff.user_name} />
        </Form.Item>
        {/* <Form.Item
          label={'Status'}
          name="active"
          className='staff-item'
          // rules={[{ required: true, message: 'Please input your status!' }]}
        >
          <Switch loading={false} checked={staff.active} defaultChecked={staff.active} />
        </Form.Item> */}
        <Form.Item className="staff-item" {...tailLayout}>
          <Button type="primary" htmlType="submit">
            {t('userManagement.modal.updateName.btnUpdate')}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default EditStaff;
