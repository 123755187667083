import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import {
  Button,
  Col,
  Collapse,
  Form,
  Input,
  Modal,
  notification,
  Popconfirm,
  Row,
  Table,
  Tabs,
} from 'antd';
import { t } from 'i18n';
import Blacklist from 'modules/Blacklist/Blacklist';
import Blockapp from 'modules/Blockapp/Blockapp';
import Whitelist from 'modules/Whitelist/Whitelist';
import { getCookie } from 'pkg/cookie/helpers';
import { promotionClient } from 'pkg/request/clients';
import React, { useEffect, useState } from 'react';
import { isDesktop } from 'react-device-detect';
import AddBlacklistToUser from './AddBlacklistToUser';
import AddStaffByAgent from './AddStaffByAgent';
import EditStaff from './EditStaff';
import StaffChangePassword from './StaffChangePassword';
import Test from './Test';
import './User.scss';
import BlockappManager from './BlockappManager';

const User = () => {
  const { Panel } = Collapse;
  const [isModalEditStaffVisible, setIsModalEditStaffVisible] = useState(false);
  const [dataSource, setDataSoucre] = useState([]);
  const [dataSourceAdmin, setDataSourceAdmin] = useState([]);
  const [loadingStaffActive, setLoadingStaffActive] = useState(false);
  const [agents, setAgents] = useState([]);
  const [count, setCount] = useState(1);
  const [updateBlackList, setUpdateBlackList] = useState(1);
  const [blacklist, setBlacklist] = useState([]);
  const [whitelist, setWhitelist] = useState([]);

  const [staff, setStaff] = useState({
    active: false,
    full_name: '',
    id: '',
    user_name: '',
  });
  const [form] = Form.useForm();
  const { TabPane } = Tabs;

  useEffect(() => {
    getListStaffOfAdminId();
    getListAgent();
  }, []);

  const onReset = () => {
    form.resetFields();
  };

  const handleCancel = () => {
    setIsModalEditStaffVisible(false);
  };

  const getAdminId = () => {
    return getCookie('current_username');
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  const tailLayout = {
    wrapperCol: { offset: 0, span: 16 },
  };

  const columns = [
    {
      title: t('AddUserToAgent_stt'),
      dataIndex: 'id',
      key: 'id',
      width: 45,
      render: (value: any, staff: any, index: any) => <span>{index + 1}</span>,
    },
    {
      title: t('user_fullName'),
      dataIndex: 'full_name',
      key: 'full_name',
      // with: '40%',
      render: (fullname: any, staff: any) => (
        <span>
          {fullname} <EditOutlined onClick={() => handleEditStaff(staff)} />{' '}
        </span>
      ),
      sorter: (a: any, b: any) => a.full_name.length - b.full_name.length,
    },
    {
      title: t('user_id'),
      dataIndex: 'user_name',
      key: 'user_name',
      // with: '30%',
      sorter: (a: any, b: any) => a.user_name.length - b.user_name.length,
    },
    {
      title: t('AddUserToAgent_delete'),
      dataIndex: 'delete',
      key: 'delete',
      render: (item: any, value: any) => (
        <Popconfirm
          placement="topRight"
          title={t('notification.staff.delete.title')}
          onConfirm={() => handleDeleteStaff(value)}
          okText={t('notification.delete.yes')}
          cancelText={t('notification.delete.no')}
        >
          {isDesktop ? (
            <Button danger>{t('AddUserToAgent_delete')}</Button>
          ) : (
            <DeleteOutlined style={{ color: 'red', fontSize: 25 }} />
          )}
        </Popconfirm>
      ),
    },
  ];

  const getListStaffOfAdminId = () => {
    promotionClient
      .post('/staff_user/admin/list', {
        admin_id: getAdminId(),
        token: '',
      })
      .then((res) => {
        setDataSoucre(res.data.staff_list || []);
      });
  };

  const AddStaff = (values: any) => {
    promotionClient
      .post('/staff_user/admin/create', {
        admin_id: getAdminId(),
        user_name: values.username,
        full_name: values.fullName,
        password: values.password,
        token: '',
      })
      .then((res: any) => {
        if (res.data.success) {
          notification.success({
            message: t('Success'),
            description: t('notification.staff.create.success'),
          });
          getListStaffOfAdminId();
          onReset();
        } else {
          notification.error({
            message: t('Error'),
            description: res.data.message,
          });
        }
      })
      .catch((err: any) => {
        notification.error({
          message: t('Error'),
          description: err || 'error',
        });
      });
  };

  const handleEditStaff = (staff: any) => {
    if (!staff.id) return;
    setStaff(staff);
    setIsModalEditStaffVisible(true);
  };

  const handleSubmitEditStaff = (value: any) => {
    promotionClient
      .post('/staff_user/admin/edit', {
        staff_id: staff.id,
        full_name: value.fullName,
        active: staff.active,
        token: '',
      })
      .then((res: any) => {
        if (res.data.success) {
          notification.success({
            message: t('Success'),
            description: t('notification.staff.editName.success'),
          });
          getListStaffOfAdminId();
          handleCancel();
        } else {
          notification.error({
            message: 'update fullName fail',
            description: res.data.message,
          });
        }
      })
      .catch((err: any) => {
        notification.error({
          message: 'update fullName fail',
          description: err,
        });
      });
  };

  const handleActiveStaff = (value: any, myStaff: any) => {
    setLoadingStaffActive(true);
    promotionClient
      .post('/staff_user/admin/edit', {
        staff_id: myStaff.id,
        full_name: myStaff.full_name,
        active: !myStaff.active,
        token: '',
      })
      .then((res: any) => {
        if (res.data.success) {
          notification.success({
            message: t('Success'),
            description: !myStaff.active
              ? t('notification.activeStaff.success')
              : t('notification.InactiveStaff.success'),
          });
          setTimeout(() => {
            setLoadingStaffActive(false);
            getListStaffOfAdminId();
          }, 300);
        } else {
          notification.error({
            message: t('Success'),
            description: t('notification.activeStaff.fail'),
          });
        }
      })
      .catch((err: any) => {
        notification.error({
          message: t('Error'),
          description: err || 'error',
        });
      });
  };

  const handleDeleteStaff = (staff: any) => {
    promotionClient
      .post('/staff_user/admin/delete', {
        staff_id: staff.id,
        token: '',
      })
      .then((res: any) => {
        if (res.data.success) {
          notification.success({
            message: t('Success'),
            description: t('notification.staff.delete.success'),
          });
          getListStaffOfAdminId();
        } else {
          notification.error({
            message: t('Error'),
            description: res.message,
          });
        }
      })
      .catch((err: any) => {
        notification.error({
          message: t('Error'),
          description: err || 'error',
        });
      });
  };

  const getListStaffOfAgent = () => {
    promotionClient
      .post('staff_agent/admin/list', {
        admin_id: getAdminId(),
        token: '',
      })
      .then((res: any) => {
        setDataSourceAdmin(res.data.staff_agent);
      });
  };

  const getAuthor = () => {
    return getCookie('author_user');
  };

  const getInfoUser = () => {};

  const getListAgent = () => {
    promotionClient
      .post(`/agent_access/admin/list`, {
        admin_id: getAdminId(),
      })
      .then((res: any) => {
        if (res.data.total) {
          setAgents(res.data.agent_list);
          localStorage.setItem(
            'listAgent',
            JSON.stringify(res.data.agent_list)
          );
        }
      });
  };
  const getGroupblacklistApi = () => {
    promotionClient
      .post('/web_rule/blacklist/admin/list', {
        admin_id: getAdminId(),
      })
      .then((res: any) => {
        if (res.data.total) {
          setBlacklist(res.data.rules);
        } else {
          notification.error({
            message: 'Error',
            description: res.data.message,
          });
        }
      })
      .catch((err: any) => {
        notification.error({
          message: t('Error'),
          description: err,
        });
      });
  };

  return (
    <div>
      <Tabs defaultActiveKey="1">
        <TabPane tab={t('userManagement.tab.userManager')} key="1">
          <Row gutter={15}>
            <Col className="gutter-row" lg={6} md={24} xs={24}>
              <div className="list-staff-item">
                <Collapse
                  style={{ border: '2px solid green', maxWidth: '500px' }}
                  defaultActiveKey={'0'}
                  accordion
                  expandIcon={({ isActive }) => (
                    <PlusCircleOutlined
                      style={{ fontSize: '120%', color: 'green' }}
                      rotate={isActive ? 130 : 0}
                    />
                  )}
                >
                  <Panel header={t('createUser')} key="1">
                    <Form
                      {...layout}
                      form={form}
                      name="basic"
                      initialValues={{ remember: true }}
                      onFinish={AddStaff}
                      onFinishFailed={onFinishFailed}
                    >
                      <Form.Item
                        label={t('createUser_name')}
                        name="username"
                        className="staff-item"
                        rules={[
                          {
                            required: true,
                            message: t(
                              'notification.input.create.user.username'
                            ),
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        label={t('Register.fullname')}
                        name="fullName"
                        className="staff-item"
                        rules={[
                          {
                            required: true,
                            message: t(
                              'notification.input.create.user.fullname'
                            ),
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        label={t('Register.password')}
                        name="password"
                        className="staff-item"
                        rules={[
                          // { required: true, message: 'Please input your password!', },
                          {
                            min: 4,
                            message: t(
                              'notification.input.create.user.minpass'
                            ),
                          },
                        ]}
                      >
                        <Input.Password />
                      </Form.Item>

                      <Form.Item
                        label={t('Register.rePasword')}
                        name="confirmPassword"
                        className="staff-item"
                        rules={[
                          // { required: true, message: 'Please confirm your password!' },
                          {
                            min: 4,
                            message: t(
                              'notification.input.create.user.minpass'
                            ),
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                !value ||
                                getFieldValue('password') === value
                              ) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error(
                                  t(
                                    'notification.input.create.user.noMatchingPass'
                                  )
                                )
                              );
                            },
                          }),
                        ]}
                      >
                        <Input.Password />
                      </Form.Item>

                      <Form.Item className="staff-item" {...tailLayout}>
                        <Button type="primary" htmlType="submit">
                          {t('createUser_create')}
                        </Button>
                      </Form.Item>
                    </Form>
                  </Panel>
                  <Panel header={t('resetPassword')} key="2">
                    <StaffChangePassword listStaff={dataSource} />
                  </Panel>
                </Collapse>
              </div>
            </Col>
            <Col className="gutter-row" lg={24} md={24} xs={24}>
              <div className="table-staff">
                <Table
                  bordered
                  pagination={{ position: ['bottomRight'] }}
                  scroll={{ x: 600 }}
                  dataSource={dataSource}
                  columns={columns}
                />
              </div>
            </Col>
          </Row>
          <Row gutter={15}>
            <Col className="gutter-row" lg={6} md={24} xs={24}>
              <div className="list-staff-item">
                <Collapse
                  style={{ border: '2px solid #055fac', maxWidth: '500px' }}
                  defaultActiveKey={'0'}
                  accordion
                  expandIcon={({ isActive }) => (
                    <PlusCircleOutlined
                      style={{ fontSize: '120%', color: '#055fac' }}
                      rotate={isActive ? 130 : 0}
                    />
                  )}
                >
                  <Panel header={t('AddUserToAgent')} key="2">
                    <AddStaffByAgent
                      setCount={setCount}
                      listStaff={dataSource}
                      getListStaffOfAgent={getListStaffOfAgent}
                      getAdminId={getAdminId}
                    />
                  </Panel>
                </Collapse>
              </div>
            </Col>
            <Col className="gutter-row" lg={24} md={24} xs={24}>
              <div className="table-staff">
                <Test count={count} dataSourceAdmin={dataSourceAdmin} />
              </div>
            </Col>
          </Row>

          <Modal
            title={t('userManagement.modal.updateName.fullName')}
            visible={isModalEditStaffVisible}
            footer={null}
            onCancel={handleCancel}
          >
            <EditStaff
              handleSubmitEditStaff={handleSubmitEditStaff}
              staff={staff}
            />
          </Modal>
        </TabPane>
        {/* <TabPane tab={t('userManagement.tab.Blacklist')} key="2">
          <Blacklist
            getGroupblacklistApi={getGroupblacklistApi}
            setUpdateBlacklist={setUpdateBlackList}
          />
          <AddBlacklistToUser
            getGroupblacklistApi={getGroupblacklistApi}
            blacklist={blacklist}
            updateBlackList={updateBlackList}
          />
        </TabPane>
        <TabPane tab={t('userManagement.tab.Whitelist')} key="4">
          {getAuthor() === 'standard' ? (
            <Whitelist staffUser={dataSource} isOpenFormTabUser={true} />
          ) : (
            <h2>{t('whiteList.noti.package')}</h2>
          )}
        </TabPane> */}
        <TabPane tab={t('userManagement.tab.blockApp')} key="3">
          {getAuthor() === 'standard' ? (
            <BlockappManager staffUser={dataSource} />
          ) : (
            <h2>{t('whiteList.noti.package')}</h2>
          )}
        </TabPane>
      </Tabs>
    </div>
  );
};

export default User;
