import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Collapse, Form, notification, Select } from 'antd';
import { t } from 'i18n';
import { getCookie } from 'pkg/cookie/helpers';
import { promotionClient } from 'pkg/request/clients';
import React, { useEffect, useState } from 'react';

function ApplyBlacklistNew(props: any) {
  const { Panel } = Collapse;
  const [form] = Form.useForm();
  const [dataApply, setDataApply] = useState([] as any[]);
  const { Option } = Select;
  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  useEffect(() => {
    getBlacklistApiOfStaff();
  }, []);

  const tailLayout = {
    wrapperCol: { offset: 0, span: 16 },
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const createBlacklistToUser = (values: any) => {
    promotionClient
      .post('staff_web/blacklist/admin/add', {
        staff_id: values.staff,
        rule_id: values.blacklist,
      })
      .then((res: any) => {
        if (res.data.success) {
          notification.success({
            message: t('Success'),
            description: t('notification.blacklist.apply.success'),
          });
          props.getBlacklistApiOfStaff();
          // onReset()
        } else {
          notification.error({
            message: 'Error',
            description: res.data.message,
          });
        }
      })
      .catch((err: any) => {
        notification.error({
          message: t('Error'),
          description: err,
        });
      });
  };

  const onReset = () => {
    form.resetFields();
  };

  const getBlacklistApiOfStaff = () => {
    promotionClient
      .post('staff_web/admin/list', {
        admin_id: getAdminId(),
      })
      .then((res: any) => {
        if (res.data.total) {
          if (res?.data?.staff_web?.length) {
            const dataTable = res.data.staff_web.map(
              (val: any, index: number) => {
                return {
                  index: index,
                  ...val,
                };
              }
            );
            setDataApply(dataTable);
          } else {
            setDataApply([]);
          }
        } else {
          notification.error({
            message: 'Error',
            description: res.data.message,
          });
        }
      })
      .catch((err: any) => {
        notification.error({
          message: t('Error'),
          description: err,
        });
      });
  };

  const getAdminId = () => {
    return getCookie('current_username');
  };
  return (
    <>
      <Collapse
        style={{
          border: '2px solid #055fac',
          marginTop: '20px',
          maxWidth: '500px',
        }}
        defaultActiveKey={'0'}
        accordion
        expandIcon={({ isActive }) => (
          <PlusCircleOutlined
            style={{ fontSize: '120%', color: '#055fac' }}
            rotate={isActive ? 130 : 0}
          />
        )}
      >
        <Panel header={t('userManagement.blackList.apply.title')} key="1">
          <Form
            form={form}
            {...layout}
            name="basic"
            initialValues={{ remember: true }}
            onFinish={createBlacklistToUser}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label={t('userManagement.blackList.apply.form.blacklist')}
              name="blacklist"
              className="staff-item"
              rules={[
                {
                  required: true,
                  message: t('notification.input.select.list', {
                    name: 'Blacklist',
                  }),
                },
              ]}
            >
              <Select style={{ width: '100%' }}>
                {props.whitelist &&
                  props.whitelist.map((item: any) => (
                    <Option value={item.id}>{item.name}</Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item
              label={t('userManagement.blackList.apply.form.listUser')}
              name="staff"
              // className='staff-item'
              rules={[
                {
                  required: true,
                  message: t('notification.input.select.listUser', {
                    name: 'User',
                  }),
                },
              ]}
            >
              <Select style={{ width: '100%' }}>
                {props.listUser &&
                  props.listUser.map((staff: any) => (
                    <Option key={staff.id} value={staff.id}>
                      {staff.full_name || staff.agent_id}
                    </Option>
                  ))}
              </Select>
            </Form.Item>

            <Form.Item className="staff-item mt-10" {...tailLayout}>
              <Button type={'primary'} htmlType="submit">
                {t('userManagement.blackList.apply.form.btnApply')}
              </Button>
            </Form.Item>
          </Form>
        </Panel>
      </Collapse>
    </>
  );
}

export default ApplyBlacklistNew;
