import {
  Button,
  Card,
  Col,
  Form,
  Input,
  notification,
  Row,
  Select,
  Table,
} from 'antd';
import { t } from 'i18n';
import moment from 'moment';
import { promotionClient } from 'pkg/request/clients';
import { formatPrice } from 'pkg/string/helpers';
import React, { useEffect, useState } from 'react';
import { CheckoutContext, columns } from '../Controller';

const style = {
  main: {
    // display: 'flex',
    // justifyContent: 'center',
    marginTop: '30px',
  },
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  submit: {
    textDecoration: 'none',
    borderRadius: '30px',
    background: 'green',
    maxWidth: '250px',
    display: 'block',
    margin: '0 auto',
    color: '#ffffff',
    minHeihgt: '40px',
    padding: '5px 20px',
    marginTop: '30px',
  },
  back: {
    textDecoration: 'none',
    borderRadius: '30px',
    background: 'blue',
    maxWidth: '250px',
    display: 'block',
    margin: '0 auto',
    color: '#ffffff',
    minHeihgt: '40px',
    padding: '5px 20px',
    marginTop: '30px',
  },
};

interface Props {
  handleCheckDiscount: (discountCode: any) => void;
}

const StepsSecond = ({ handleCheckDiscount }: Props) => {
  const [form] = Form.useForm();
  const [msgErr, setMsdErr] = useState('');
  const { data, setData, setCurrent, dataSource } = CheckoutContext();
  const { Option } = Select;
  const handlePrev = () => {
    setCurrent(0);
  };
  const handleSubmit = () => {
    setCurrent(2);
  };
  const onChangeMonth = (value: any) => {
    setData((prevState: any) => ({
      ...prevState,
      product_quantity: +value,
      sub_total: prevState.product_price * +value,
      order_total: prevState.product_price * +value,
    }));

    handleCheckDiscount(data.discountCode);
    checkMessage(data.discountCode.min, +value);

    // chua hieu muc dich
    // if (value == CHECKOUT.ONE_YEAR) {
    //   setData((prev: any) => ({ ...prev, product_discount: 2 }));
    // } else {
    //   setData((prev: any) => ({ ...prev, product_discount: 0 }));
    // }
  };

  const onFinish = (values: any) => {
    promotionClient
      .post(`/promotion/admin/get`, {
        code: values.discountCode,
      })
      .then((res: any) => {
        const dataDiscount = res.data;
        if (dataDiscount.success) {
          if (moment().diff(moment(dataDiscount.expire), 'days') > 0) {
            setMsdErr(`Mã hết hạn ngày: ${dataDiscount.expire}`);
            handleClearInput();
          } else {
            setMsdErr('');
            notification.success({
              message: t('Success'),
              description: 'Get discount code success',
            });
            handleCheckDiscount(dataDiscount);
            checkMessage(dataDiscount.min, data.product_quantity);
          }
        } else {
          notification.error({
            message: t('Error'),
            description: data.message,
          });
          setMsdErr(data.message);
        }
      })
      .catch((err: any) => {
        notification.error(err);
      });
  };
  const handleClearInput = () => {
    let discount = data.product_discount;
    if (discount > data.product_quantity * data.product_price) {
      discount = data.product_quantity * data.product_price;
    }

    setData((prev: any) => ({
      ...prev,
      product_discount: 0,
      discountPercent: '',
      order_total: prev.order_total + discount,
      discountCode: '',
    }));
  };
  const checkMessage = (min: number, month: number) => {
    if (min > month) {
      setMsdErr(`Mã giảm giá chỉ áp dụng cho đơn hàng từ ${min} tháng trở lên`);
    } else {
      setMsdErr('');
    }
  };

  return (
    <>
      <Row justify="center">
        <Col sm={24} md={16} style={style.main}>
          <Card
            title={t('billing.step2.title')}
            bordered={false}
            style={{
              border: '1px solid #000000',
            }}
          >
            <Row gutter={16} style={{ alignItems: 'end' }}>
              <Col className="gutter-row" sm={24} md={10}>
                <Form
                  name="chooseMonth"
                  initialValues={{ chooseMonth: data.product_quantity }}
                >
                  <Form.Item
                    name="chooseMonth"
                    label={t('payment.choosePackage')}
                  >
                    <Select style={{ width: 110 }} onChange={onChangeMonth}>
                      <Option value="1">1 {t('payment.month')}</Option>
                      <Option value="3">3 {t('payment.months')}</Option>
                      <Option value="6">6 {t('payment.months')}</Option>
                      <Option value="12">12 {t('payment.months')}</Option>
                    </Select>
                  </Form.Item>
                </Form>
              </Col>
              <Col className="gutter-row" sm={24} md={14}>
                <Form
                  form={form}
                  name="getDiscountCode"
                  onFinish={onFinish}
                  initialValues={{ discountCode: data.discountCode.code }}
                >
                  <Row gutter={8} style={{ alignItems: 'end' }}>
                    <Col span={19}>
                      <Form.Item
                        label={t('payment.discountCode')}
                        name="discountCode"
                      >
                        <Input
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleClearInput()
                          }
                          allowClear
                        />
                      </Form.Item>
                    </Col>

                    <Col span={5}>
                      <Form.Item>
                        <Button type="primary" htmlType="submit">
                          {t('payment.applyCode')}
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
            <Row>
              <Col span={24} offset={0} md={14}>
                <span style={{ color: 'red', textAlign: 'end' }}>{msgErr}</span>
              </Col>
            </Row>

            {/* <TableData /> */}
            <Row style={{ margin: '10px 0' }}>
              <Col span={24}>
                {`${t('payment.unitPrice')}: ${formatPrice(
                  data.product_price
                )} ${data.product_unit}`}
              </Col>
            </Row>
            <Table
              pagination={false}
              dataSource={dataSource}
              columns={columns}
            />
          </Card>
        </Col>
      </Row>
      <Row justify="center" style={{ marginBottom: '30px' }}>
        <Col sm={24} md={16}>
          <div
            style={{
              maxWidth: 500,
              margin: 'auto',
              display: 'flex',
            }}
          >
            <a
              style={{ ...style.back, marginRight: '20px' }}
              onClick={() => handlePrev()}
            >
              {t('payment.prev')}
            </a>
            <a style={{ ...style.submit }} onClick={() => handleSubmit()}>
              {t('payment.next')}
            </a>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default StepsSecond;
