import { EditOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, notification, Table } from 'antd';
import { t } from 'i18n';
import { promotionClient } from 'pkg/request/clients';
import { formatPrice } from 'pkg/string/helpers';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PATH } from 'routes/constants';
import './Billing.scss';

export default function Billing(props: any) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    getApi();
  }, []);

  let columnsUrl = [
    {
      title: `${t('billing.history.list.no')}`,
      dataIndex: 'id',
      key: 'id',
      render: (text: any, record: any, index: any) => index + 1,
    },
    {
      title: `${t('billing.history.list.description')}`,
      dataIndex: 'order_info',
      key: 'order_info',
      render: (text: any, record: any, index: any) =>
        text === 'standard to standard'
          ? `${t('billing.history.list.standardToStandard')}`
          : `${t('billing.history.list.freeToStandard')}`,
    },
    {
      title: `${t('billing.history.list.date')}`,
      dataIndex: 'bank_date',
      key: 'bank_date',
    },
    {
      title: `${t('billing.history.list.amount')}`,
      dataIndex: 'order_total',
      key: 'order_total',
      render: (text: any, record: any, index: any) => formatPrice(+text),
    },
    {
      title: `${t('billing.history.list.status')}`,
      dataIndex: 'status_name',
      key: 'status_name',
      render: (text: any, record: any, index: any) =>
        text === 'success' ? `${t('billing.history.list.success')}` : `${text}`,
    },
    {
      title: `${t('billing.history.list.action')}`,
      dataIndex: '',
      key: 'delete',
      render: (item: any, value: any) => (
        <div className="ant-btn-group" style={{ marginLeft: '5px' }}>
          <Button
            icon={<SearchOutlined />}
            onClick={() => history.push(`${PATH.BILLING.LIST}/${value.id}`)}
          />
          {/* <Button
            icon={<EditOutlined />}
            onClick={() =>
              history.push(`${PATH.BILLING.LIST}/${value.id}/edit`)
            }
          /> */}
        </div>
      ),
    },
  ];

  const getApi = () => {
    setLoading(true);
    promotionClient
      .get('/order/admin/list')
      .then((res: any) => {
        if (res.data.total) {
          setData(res.data.list);
        } else {
          notification.error({
            message: 'Error',
            description: res.data.message,
          });
        }
      })
      .catch((err: any) => {
        notification.error({
          message: t('Error'),
          description: err,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Table
      scroll={{ x: 600 }}
      dataSource={data}
      columns={columnsUrl}
      pagination={false}
      loading={loading}
      rowKey={(record: any) => record.id}
      // pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30'] }}
    />
  );
}
