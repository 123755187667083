export enum METHODS_ENUM {
  authLogin = '/promo.vnpay.v1.AuthService/Login',
  bankList = '/promo.vnpay.v1.VnpayBankService/List',
  bankGet = '/promo.vnpay.v1.VnpayBankService/Get',
  blacklistLockCustomer = '/promo.vnpay.v1.BlacklistService/LockCustomer',
  blacklistUnlockCustomer = '/promo.vnpay.v1.BlacklistService/UnlockCustomerByPhone',
  blacklistGetCustomerHistories = '/promo.vnpay.v1.BlacklistService/GetCustomerHistories',
  blacklistGetBlacklistCustomer = '/promo.vnpay.v1.BlacklistService/GetBlacklistCustomer',
  blacklistLockTerminal = '/promo.vnpay.v1.BlacklistService/LockTerminal',
  blacklistSyncTerminal = '/promo.vnpay.v1.BlacklistService/SyncTerminal',
  blacklistUnlockTerminal = '/promo.vnpay.v1.BlacklistService/UnlockTerminal',
  blacklistGetBlacklistMerchant = '/promo.vnpay.v1.BlacklistService/GetBlacklistMerchant',
  blacklistGetBlacklistTerminal = '/promo.vnpay.v1.BlacklistService/GetBlacklistTerminal',
  blacklistGetBlacklistMerchantHistories = '/promo.vnpay.v1.BlacklistService/GetBlacklistMerchantHistories',
  campaignList = '/promo.vnpay.v1.CampaignService/List',
  campaignCreate = '/promo.vnpay.v1.CampaignService/Create',
  campaignReject = '/promo.vnpay.v1.CampaignService/Reject',
  campaignSubmitNoUpdate = '/promo.vnpay.v1.CampaignService/SubmitNoUpdate',
  campaignCancelSubmit = '/promo.vnpay.v1.CampaignService/CancelSubmit',
  campaignExposeList = '/promo.vnpay.v1.CampaignExposeService/List',
  campaignExposeGetDetail = '/promo.vnpay.v1.CampaignExposeService/GetDetail',
  campaignExposeGetTerminal = '/promo.vnpay.v1.CampaignExposeService/GetTerminal',
  campaignExposeAddCustomer = '/promo.vnpay.v1.CampaignExposeService/AddCustomer',
  campaignFullCreate = '/promo.vnpay.v1.CampaignFullService/Create',
  campaignFullEdit = '/promo.vnpay.v1.CampaignFullService/Edit',
  campaignFullSubmit = '/promo.vnpay.v1.CampaignFullService/Submit',
  campaignFullGet = '/promo.vnpay.v1.CampaignFullService/Get',
  campaignFullGetBudget = '/promo.vnpay.v1.CampaignFullService/GetBudgetUsedAmount',
  campaignFullExtendDetail = '/promo.vnpay.v1.CampaignFullService/ExtendDetail',
  campaignFullApprove = '/promo.vnpay.v1.CampaignFullService/Approve',
  campaignFullLock = '/promo.vnpay.v1.CampaignFullService/Lock',
  campaignFullUnlock = '/promo.vnpay.v1.CampaignFullService/Unlock',
  campaignFullStop = '/promo.vnpay.v1.CampaignFullService/Stop',
  campaignFullSyncMerchant = '/promo.vnpay.v1.CampaignFullService/SyncMerchant',
  campaignFullSyncTerminal = '/promo.vnpay.v1.CampaignFullService/SyncTerminal',
  campaignMerchantUpload = '/promo.vnpay.v1.CampaignMerchantService/Upload',
  campaignMerchantApply = '/promo.vnpay.v1.CampaignMerchantService/Apply',
  campaignMerchantApplyAll = '/promo.vnpay.v1.CampaignMerchantService/ApplyAll',
  campaignMerchantChangeStatusTerminal = '/promo.vnpay.v1.CampaignMerchantService/ChangeStatusCampaignTerminal',
  campaignMerchantGetFile = '/promo.vnpay.v1.CampaignMerchantService/GetGetFileProcessStatus',
  campaignMerchantGetTerminals = '/promo.vnpay.v1.CampaignMerchantService/GetCampaignTerminals',
  campaignMerchantDownload = '/promo.vnpay.v1.CampaignMerchantService/DownloadFileProcessResult',
  campaignMerchantListCustomers = '/promo.vnpay.v1.CampaignMerchantService/ListCustomers',
  campaignMerchantListCustomerTerminals = '/promo.vnpay.v1.CampaignMerchantService/ListCustomerTerminals',
  campaignMerchantChangeStatusCustomer = '/promo.vnpay.v1.CampaignMerchantService/ChangeStatusCampaignCustomer',
  healthLiveness = '/health.v1.HealthCheckService/Liveness',
  healthReadiness = '/health.v1.HealthCheckService/Readiness',
  merchantList = '/promo.vnpay.v1.VnpayMerchantService/List',
  userAdd = '/promo.vnpay.v1.UserService/Add',
  userGet = '/promo.vnpay.v1.UserService/Get',
  userGetUserInfo = '/promo.vnpay.v1.UserService/GetUserInfo',
  userUpdate = '/promo.vnpay.v1.UserService/Update',
  userDelete = '/promo.vnpay.v1.UserService/Delete',
  userList = '/promo.vnpay.v1.UserService/List',
}
