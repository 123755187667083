import _ from 'lodash';
import { IField } from './types';

/**
 * get all leafs of [nested] object
 * @param obj
 */
export const getLeafs = (obj: object): any[] => {
  const children = Object.values(obj);
  return children.reduce((acc, child) => {
    if (_.isPlainObject(child)) {
      acc.push(...getLeafs(child));
    } else {
      acc.push(child);
    }
    return acc;
  }, []);
};

export const getQueryStringFromObj = (obj: any) => {
  return Object.keys(
    _.pickBy(obj, value => ![undefined, '', null].includes(value))
  )
    .map(key => `${key}=${obj[key]}`)
    .join('&');
};

export const getObjFromQueryString = (str: string) => {
  const pairs = str.slice(1).split('&');
  const result: any = {};
  pairs.forEach(pair => {
    const newPair = pair.split('=');
    try {
      result[newPair[0]] = decodeURIComponent(newPair[1] || '');
    } catch (e) {
      result[newPair[0]] = newPair[1] || '';
    }
  });
  return result;
};

export const toObject = (fields: IField[]): object => {
  return fields.reduce((acc: any, { name, value }) => {
    acc[name] = value;
    return acc;
  }, {});
};

export const normalizeKeys = (
  obj: object,
  normalizer: (key: string) => string
) => {
  return Object.entries(obj).reduce((acc: any, [key, value]) => {
    acc[normalizer(key)] = _.isPlainObject(value)
      ? normalizeKeys(value, normalizer)
      : value;
    return acc;
  }, {});
};

export const removeKeysFromObject = (params: any, removeKeys: string[]) => {
  removeKeys.forEach(key => {
    delete params[key];
  });
};

export const hasValue = (value: any) => {
  return !!_.flatten([value]).join();
};
