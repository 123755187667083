import React, { useState } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import Loader from '../loader/Loader';
import { t } from 'i18n';
import './VerifyAccount.scss'
import { Button } from 'antd';
import Image from '../../assets/images/safeweb-app-tracking.jpeg'
import { promotionClient } from '../../pkg/request/clients';
import { PATH } from '../../routes/constants';
const VerifyAccount = (props: { match: { params: { id: any } } }) => {
  const [loading, setLoading] = useState<boolean>(false)
  const history = useHistory();
  function LoadingCP ()  {
    if (loading) {
      return (<Loader />)
    }
    return (<></>)
  }

  const onConfirm = () => {
    setLoading(true)
    promotionClient
      .post(`/user/activate`, {
        token: `${props.match.params.id}`,
      })
      .then(() => {
        history.push(PATH.LOGIN);
        setLoading(false)
      })
      .catch(err => alert(err));
  };
  return (
    <div className='verifyAccount'>
      <div className='overlay'>
        <h2>
          Please click <Button size={'large'}  type="primary" onClick={() => onConfirm()}>Confirm</Button> to activate your account{' '}
        </h2>
      </div>
      <LoadingCP />
    </div>
  );
};
export default VerifyAccount;
