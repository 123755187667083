import { Avatar } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLogin } from './hooks';

import { REGIONS } from 'pkg/localization/constants';
import {
  changeLanguage,
  getCurrentLanguage,
  LOCALIZATION,
} from 'pkg/localization/helpers';
import { browserHistory } from 'pkg/route/helper';
import { isLoggedIn } from 'pkg/user/repo';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { PATH } from 'routes/constants';
import Logo from '../../assets/images/android-chrome-128x128.png';
import FormLogin from './components/FormLogin';
import './Login.scss';
import { useHistory } from 'react-router-dom';
import useQuery from 'modules/checkout/hook/useQueryParams';
import { setCookie } from 'pkg/cookie/helpers';

const Login: React.FC = () => {
  const { isLoading, handleLogin } = useLogin();
  const onUpdateLanguage = (key: any) => {
    changeLanguage(key);
  };
  const query = useQuery();

  useEffect(() => {
    if (isLoggedIn()) {
      browserHistory.push(PATH.DASHBOARD_NEW);
    } else {
      const name = query.get('lang');
      if (name && name !== getCurrentLanguage()) {
        changeLanguage(name);
      }
    }
  }, []);

  return (
    <div className="login-form">
      <div className="login-img">
        <a href="/">
          <img src={Logo} />
        </a>
      </div>
      <h1 className="login-form__title mb-3">SAFEWEB.APP</h1>
      <span className="app-user d-flex justify-content-center">
        <div
          className="mr-05 mb-1 cursor-poiter"
          onClick={() => onUpdateLanguage('en')}
        >
          <Avatar src={REGIONS.en.flag} shape="square" />
        </div>
        <div className="cursor-poiter" onClick={() => onUpdateLanguage('vi')}>
          <Avatar src={REGIONS.vi.flag} shape="square" />
        </div>
        {/* <span className="name">{currentRegion && currentRegion.name}</span> */}
      </span>
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.REACT_APP_RECAPTCHA || ''}
      >
        <FormLogin isLoading={isLoading} handleLogin={handleLogin} />
      </GoogleReCaptchaProvider>
    </div>
  );
};

export default Login;
