import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { REGIONS, RESOURCES } from 'pkg/localization/constants';
import { getCurrentLanguage } from 'pkg/localization/helpers';
import { capitalJoin } from 'pkg/string/helpers';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: RESOURCES,
    lng: getCurrentLanguage(),
    fallbackLng: REGIONS.en.key,
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
export const t = (text: string, options?: any) => i18n.t(text, options);
export const translate = (...args: string[]) => {
  return capitalJoin(...args.map(t));
};
