import { Col, Row, Steps } from 'antd';
import { CHECKOUT } from 'config/price';
import { t } from 'i18n';
import { getCookie } from 'pkg/cookie/helpers';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import StepsFirst, { planName } from './UI/StepsFirst';
import StepsLast from './UI/StepsLast';
import StepsSecond from './UI/StepsSecond';
import { AlignType } from 'rc-table/lib/interface';
import { Breakpoint } from 'antd/lib/_util/responsiveObserve';

const getAdminId = () => {
  return getCookie('current_username');
};

const DISCOUNT_TYPE = {
  PERCENT: 'percent',
  PERCENT_MONEY: 'percent_money',
  MONEY: 'money',
  MONTH: 'month',
  MONEY_COND_MONTH: 'money_cond_month',
  PERCENT_COND_MONTH: 'percent_cond_month',
  MONTH_COND_MONTH: 'month_cond_month',
};
export type CheckoutContextType = {
  data: any;
  setData: (data: any) => void;
  setCurrent: (current: number) => void;
  dataSource: any;
};
export const CheckoutContextContent = createContext<CheckoutContextType>({
  data: null,
  setData: () => {},
  setCurrent: () => {},
  dataSource: [],
});

export const CheckoutContext = () => useContext(CheckoutContextContent);

const Checkout: React.FC = () => {
  const { Step } = Steps;
  const [current, setCurrent] = useState<number>(0);

  const [data, setData] = useState<any>({
    admin_id: getAdminId(),
    sub_total: CHECKOUT.PRICE * +CHECKOUT.QUANTITY,
    order_total: CHECKOUT.PRICE * +CHECKOUT.QUANTITY,
    order_info: 'free to standard', // free to premium
    product_name: 'standard monthly', // name == code
    product_code: 'standard',
    product_price: CHECKOUT.PRICE,
    product_unit: 'VND',
    product_discount: CHECKOUT.DISCOUNT,
    product_tax: CHECKOUT.VAT, // thuế
    product_quantity: CHECKOUT.QUANTITY,
    product_description: `Mua gói ${CHECKOUT.QUANTITY} tháng standand`,
    coupon: '',
    discountCode: '',
    discountCodeName: '',
    discountPercent: 0,
  });

  useEffect(() => {
    handleCheckDiscount(data.discountCode);
  }, [data.sub_total]);

  const _MS_PER_DAY = 1000 * 60 * 60 * 24;

  // a and b are javascript Date objects
  function dateDiffInDays(a: any, b: any) {
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
  }
  const handleCheckMinLeft = () => {
    let discount = data.product_discount;
    if (discount > data.product_quantity * data.product_price) {
      discount = data.product_quantity * data.product_price;
    }

    setData((prev: any) => ({
      ...prev,
      product_discount: 0,
      discountPercent: '',
      order_total: prev.order_total + discount,
    }));
  };

  const handleCheckDiscount = useCallback(
    (promo: any) => {
      const today = new Date();

      const checkDate = dateDiffInDays(today, new Date(promo.expired_at));

      if (checkDate < 0) {
        return 0;
      }

      const totalMoney = data.product_price * data.product_quantity;
      setData((prev: any) => ({
        ...prev,
        discountCode: promo,
        coupon: promo.code,
      }));
      switch (promo.type) {
        case DISCOUNT_TYPE.PERCENT:
          let totalDiscountPercent = (promo.percent * totalMoney) / 100;

          setData((prev: any) => ({
            ...prev,
            product_discount: totalDiscountPercent,
            discountPercent: promo.percent,
            order_total: totalMoney - totalDiscountPercent,
            discountCode: promo,
          }));
          break;
        case DISCOUNT_TYPE.PERCENT_COND_MONTH:
          if (data.product_quantity < promo.min) {
            handleCheckMinLeft();
            break;
          }
          let totalDiscountPercentCond = (promo.percent * totalMoney) / 100;

          setData((prev: any) => ({
            ...prev,
            product_discount: totalDiscountPercentCond,
            discountPercent: promo.percent,
            order_total: totalMoney - totalDiscountPercentCond,
            discountCode: promo,
          }));
          break;

        case DISCOUNT_TYPE.PERCENT_MONEY:
          let totalDiscount = (promo.percent * totalMoney) / 100;
          if (totalDiscount > +promo.money) totalDiscount = +promo.money;
          console.log('totalDiscount :>> ', totalDiscount);
          setData((prev: any) => ({
            ...prev,
            product_discount: totalDiscount,
            discountPercent: promo.percent,
            order_total: totalMoney - totalDiscount,
            discountCode: promo,
          }));
          break;

        case DISCOUNT_TYPE.MONEY:
          let totalDiscountMoney = +promo.money;
          if (totalDiscountMoney > totalMoney) totalDiscountMoney = totalMoney;
          setData((prev: any) => ({
            ...prev,
            product_discount: +promo.money,
            discountPercent: '',
            order_total: totalMoney - totalDiscountMoney,
            discountCode: promo,
          }));
          break;

        case DISCOUNT_TYPE.MONEY_COND_MONTH:
          if (data.product_quantity < promo.min) {
            handleCheckMinLeft();
            break;
          }
          let totalDiscountMoneyCondMonth = +promo.money;
          if (totalDiscountMoneyCondMonth > totalMoney)
            totalDiscountMoneyCondMonth = totalMoney;
          setData((prev: any) => ({
            ...prev,
            product_discount: +promo.money,
            discountPercent: '',
            order_total: totalMoney - totalDiscountMoneyCondMonth,
            discountCode: promo,
          }));
          break;

        case DISCOUNT_TYPE.MONTH:
          let totalDiscountMonth = promo.month * data.product_price;
          if (totalDiscountMonth > totalMoney) totalDiscountMonth = totalMoney;
          setData((prev: any) => ({
            ...prev,
            product_discount: promo.month * prev.product_price,
            discountPercent: promo.month,
            order_total: totalMoney - totalDiscountMonth,
            discountCode: promo,
          }));
          break;

        case DISCOUNT_TYPE.MONTH_COND_MONTH:
          if (data.product_quantity < promo.min) {
            handleCheckMinLeft();
            break;
          }
          let totalDiscountMonthCond = promo.month * data.product_price;
          if (totalDiscountMonthCond > totalMoney)
            totalDiscountMonthCond = totalMoney;
          setData((prev: any) => ({
            ...prev,
            product_discount: promo.month * prev.product_price,
            discountPercent: promo.month,
            order_total: totalMoney - totalDiscountMonthCond,
            discountCode: promo,
          }));
          break;

        default:
          return 0;
      }
    },
    [data]
  );
  const dataSource = [
    {
      key: '1',
      order_info: `${t('payment.upgrade.standard', {
        package: `${planName['standard']} `,
      })}`,
      product_quantity: data.product_quantity,
      product_price: formatMoney(data.product_price),
      order_total: formatMoney(data.sub_total),
      product_unit: data.product_unit,
    },
    {
      key: '2',
      order_info:
        t('payment.discount') +
        ` ${
          ['percent_cond_month', 'percent'].includes(data.discountCode.type)
            ? ``
            : data.discountCode.type === 'percent_money'
            ? `<b style="color: red">(` +
              t('payment.maxDiscount') +
              ' ' +
              formatMoney(+data.discountCode.money) +
              `đ)</b>`
            : ''
        }`,
      product_quantity:
        ['percent', 'percent_money', 'percent_cond_month'].includes(
          data.discountCode.type
        ) && data.discountPercent
          ? `${data.discountPercent}%`
          : data.discountPercent,
      product_price:
        data.discountCode.type === 'month' ||
        (data.discountCode.type === 'month_cond_month' &&
          data.product_quantity >= data.discountCode.min)
          ? formatMoney(data.product_price)
          : '',
      order_total: `-${formatMoney(data.product_discount)}`,
      product_unit: data.product_unit,
    },
    {
      key: '3',
      order_info: 'VAT',
      product_quantity: '',
      product_price: '',
      order_total: '',
      product_unit: '',
    },
    {
      key: '4',
      order_info: t('payment.total'),
      product_quantity: '',
      product_price: '',
      order_total: `${formatMoney(data.order_total)}`,
      product_unit: data.product_unit,
    },
  ];

  const steps = [
    {
      title: t('payment.service'),
      content: <StepsFirst />,
    },
    {
      title: t('payment.serviceDetail'),
      content: <StepsSecond handleCheckDiscount={handleCheckDiscount} />,
    },
    {
      title: t('payment.billingInformation'),
      content: <StepsLast data={data} setCurrent={setCurrent} />,
    },
  ];

  return (
    <div>
      <div className="container">
        <Row className="mt-3">
          <CheckoutContextContent.Provider
            value={{ data, setData, setCurrent, dataSource }}
          >
            <Col span={22} offset={1}>
              <Steps current={current}>
                {steps.map((item) => (
                  <Step key={item.title} title={item.title} />
                ))}
              </Steps>
              <div className="steps-content">{steps[current].content}</div>
              <div className="steps-action"></div>
            </Col>
          </CheckoutContextContent.Provider>
        </Row>
      </div>
    </div>
  );
};

export const formatMoney = (money: number) => {
  return money
    ?.toLocaleString('it-IT', { style: 'currency', currency: 'VND' })
    .slice(0, -3);
};

export default Checkout;

export const columns = [
  {
    title: t('payment.description'),
    dataIndex: 'order_info',
    key: 'order_info',
    fixed: true,
    render: (text: string) => (
      <div dangerouslySetInnerHTML={{ __html: text }}></div>
    ),
  },
  {
    title: t('payment.quantity'),
    dataIndex: 'product_quantity',
    key: 'product_quantity',
    align: 'right' as AlignType,
  },
  {
    title: t('payment.price'),
    dataIndex: 'product_price',
    key: 'product_price',
    align: 'right' as AlignType,
    responsive: ['md'] as Breakpoint[],
  },
  {
    title: t('payment.money'),
    dataIndex: 'order_total',
    key: 'order_total',
    align: 'right' as AlignType,
  },
  {
    title: t('payment.unit'),
    dataIndex: 'product_unit',
    key: 'product_unit',
    responsive: ['md'] as Breakpoint[],
  },
];
