import { DoubleRightOutlined, DownloadOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import { t } from 'i18n';
import { getCookie } from 'pkg/cookie/helpers';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { PATH } from 'routes/constants';
import Whitelist from './Whitelist';

const WhitelistGroup = () => {
  const history = useHistory();
  const getAuthor = () => {
    return getCookie('author_user');
  };
  return (
    <>
      {getAuthor() === 'standard' ? (
        <Whitelist isOpenFormLeft={false} />
      ) : (
        <Row justify="center" align="middle" style={{ marginTop: '30px' }}>
          <Col
            span={24}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <h2 style={{ textAlign: 'center' }}>
              {t('whiteList.noti.package')}
            </h2>
            <Button
              type="primary"
              size="large"
              onClick={() => history.push(PATH.BILLING.LIST)}
            >
              {t('whiteList.noti.btnUpgrade')}
              <DoubleRightOutlined />
            </Button>
          </Col>
        </Row>
      )}
    </>
  );
};

export default WhitelistGroup;
