import {
  Avatar,
  Card,
  Col,
  Form,
  notification,
  Row,
  Select,
  Switch,
} from 'antd';
import { t } from 'i18n';
import { getCookie } from 'pkg/cookie/helpers';
import { promotionClient } from 'pkg/request/clients';
import React, { useEffect, useState } from 'react';
import AddBlockApp from './AddBlockApp';
import BlockListDetail from './BlockListDetail';

import './style.scss';
import SelectDateComponent from './components/SelectDateComponent';
const ColorList = ['#f56a00', '#7265e6', '#7B68EE', '#00a2ae'];
const gridStyle: React.CSSProperties = {
  textAlign: 'center',
  width: '100%',
};

export default function Blockapp(props: any) {
  const { Option } = Select;
  const [runTimeList, setRunTimeList] = useState<any>([]);
  const [defaultActiveKey, setDefaultActiveKey] = useState(['0']);
  const [blockapps, setBlockapps] = useState([]);
  const [isResetForm, setIsResetForm] = useState(1);
  const [form] = Form.useForm();
  const [staffList, setStaffList] = useState([]);

  useEffect(() => {
    getMyblockList();
  }, []);

  useEffect(() => {
    getListStaffOfAdminId();
  }, [props.updateUserlist]);

  useEffect(() => {
    getBlockApp();
  }, []);

  const createBlacklistToUser = (values: any, type: any = 'normal') => {
    promotionClient
      .post('staff_app/blocklist/admin/add', {
        staff_id: values.staff,
        rule_id: values.blocklist,
      })
      .then((res) => {
        if (res.data.success) {
          notification.success({
            message: t('Success'),
            description: t('notification.blockApp.apply.success'),
          });
          if (type === 'timer') {
            getBlockApp();
          } else {
            getListStaffOfAdminId();
          }
        } else {
          notification.error({
            message: 'Error',
            description: res.data.message,
          });
        }
      })
      .catch((err: any) => {
        notification.error({
          message: t('Error'),
          description: err,
        });
      });
  };

  const getAdminId = () => {
    return getCookie('current_username');
  };

  const getBlockApp = () => {
    promotionClient
      .post('staff_timer/user/app/admin/list', {
        admin_id: getAdminId(),
      })
      .then((res: any) => {
        if (res.data.total) {
          setRunTimeList(res.data.users);
        } else {
          notification.error({
            message: 'Error',
            description: res.data.message,
          });
        }
      })
      .catch((err: any) => {
        notification.error({
          message: t('Error'),
          description: err,
        });
      });
  };

  const getListStaffOfAdminId = () => {
    promotionClient
      .post('/staff_app/admin/list', {
        admin_id: getAdminId(),
        token: '',
      })
      .then((res) => {
        if (res.data.total > 0) {
          setStaffList(res.data.staff_app);
        } else {
          setStaffList([]);
        }
      })
      .catch((err: any) => {
        notification.error({
          message: t('Error'),
          description: err,
        });
      });
  };

  const handleOptionChange = (status: any, staff: any) => {
    let vType = 'normal';

    switch (status) {
      case true:
        vType = 'blocklist';
        break;

      default:
        vType = 'normal';
        break;
    }

    promotionClient
      .post(`staff_app/${vType}/admin/set`, {
        staff_id: staff.staff_id,
        mode: vType,
      })
      .then((res) => {
        if (res.data.success) {
          notification.success({
            message: t('Success'),
            description: t('notification.tabMode.update.success', {
              type: t(`${vType}`),
            }),
          });
          getListStaffOfAdminId();
        } else {
          notification.error({
            message: 'Error',
            description: res.data.message,
          });
        }
      })
      .catch((err: any) => {
        notification.error({
          message: t('Error'),
          description: err,
        });
      });
  };
  const handleOptionChangeTimer = (status: any, staff: any) => {
    let vType = 'off';

    switch (status) {
      case true:
        vType = 'on';
        break;

      default:
        vType = 'off';
        break;
    }
    promotionClient
      .post(`staff_timer/app/admin/turn`, {
        staff_id: staff.staff_id,
        status: vType,
      })
      .then((res) => {
        if (res.data.success) {
          notification.success({
            message: t('Success'),
            description: t('notification.tabMode.update.success', {
              type: t(`${vType}`),
            }),
          });
          getBlockApp();
        } else {
          notification.error({
            message: 'Error',
            description: res.data.message,
          });
        }
      })
      .catch((err: any) => {
        notification.error({
          message: t('Error'),
          description: err,
        });
      });
  };

  const getMyblockList = () => {
    promotionClient
      .post('/app_rule/blocklist/admin/list', {
        admin_id: getAdminId(),
      })
      .then((res: any) => {
        if (res.data.total) {
          setBlockapps(res.data.rules);
        } else {
          notification.error({
            message: 'Error',
            description: res.data.message,
          });
        }
      })
      .catch((err: any) => {
        notification.error({
          message: t('Error'),
          description: err,
        });
      });
  };

  const AddBlacklistGroup = (values: any) => {
    promotionClient
      .post('/app_rule/blocklist/admin/create', {
        admin_id: getAdminId(),
        name: values.name,
        list: [],
      })
      .then((res: any) => {
        if (res.data.success) {
          getMyblockList();
          const newReset = isResetForm + 1;
          setIsResetForm(newReset);

          setDefaultActiveKey(['0']);

          notification.success({
            message: t('Success'),
            description: t('notification.create.success', {
              type: t('blocklist'),
            }),
          });
        } else {
          notification.error({
            message: 'Error',
            description: res.data.message,
          });
        }
      })
      .catch((err: any) => {
        notification.error({
          message: t('Error'),
          description: err || 'error',
        });
      });
  };

  return (
    <>
      <AddBlockApp
        setIsResetForm={isResetForm}
        AddBlacklistGroup={AddBlacklistGroup}
      />
      {blockapps &&
        blockapps.map((item, index) => (
          <BlockListDetail getApi={getMyblockList} key={index} item={item} />
        ))}

      <Row gutter={[16, 32]} style={{ marginTop: 30 }}>
        {staffList &&
          staffList.length > 0 &&
          staffList.map((user: any, index: number) => (
            <Col xs={24} sm={12} md={12} lg={8} xl={8} key={index}>
              <Card
                hoverable
                className="card-style"
                style={{
                  padding: '5px',
                  // background: '#045FAD',
                  borderRadius: '20px',
                  minHeight: '590px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  border: '5px solid #045FAD',
                }}
                bodyStyle={{ background: 'white' }}
                cover={
                  <div
                    style={{
                      background: 'white',
                      borderRadius: '15px 15px 0 0',
                    }}
                  >
                    <div style={gridStyle}>
                      <Avatar
                        size={{ xs: 80, md: 100, xxl: 120 }}
                        style={{
                          backgroundColor:
                            ColorList[
                              Math.floor(
                                0.5 *
                                  (user.full_name
                                    ? user.full_name.length
                                    : user.user_name.length)
                              )
                            ],
                          verticalAlign: 'middle',
                        }}
                      >
                        {user.full_name
                          ? user.full_name.charAt(0)
                          : user.user_name.charAt(0)}
                      </Avatar>
                    </div>
                    <div
                      style={{
                        ...gridStyle,
                        color: 'gray',
                        fontWeight: 'bold',
                        margin: '10px 0',
                      }}
                    >
                      {user.full_name ? user.full_name : user.user_name}
                    </div>
                    <div style={{ ...gridStyle, color: 'gray' }}>
                      {t('blockApp.list.name')}
                    </div>
                    <div style={{ ...gridStyle }}>
                      <Select
                        style={{ width: '70%' }}
                        value={user.blocklist_name}
                        onChange={(value: any) => {
                          const data = {
                            staff: user.staff_id,
                            blocklist: value,
                          };
                          createBlacklistToUser(data);
                        }}
                      >
                        {!user.blocklist_name && (
                          <Option value="" disabled>
                            <span style={{ color: 'red', fontWeight: 'bold' }}>
                              -- Select rule --
                            </span>
                          </Option>
                        )}
                        {blockapps &&
                          blockapps.map((item: any) => (
                            <Option value={item.id} key={item.id}>
                              {item.name}
                            </Option>
                          ))}
                      </Select>
                    </div>
                  </div>
                }
                actions={[
                  <Switch
                    style={{
                      transform: 'scale(1.7)',
                    }}
                    checked={user.run_mode === 'blocklist'}
                    onChange={(type: boolean) => {
                      handleOptionChange(type, user);
                    }}
                    checkedChildren={t(
                      'userManagement.blockApp.add.list.allowWeb'
                    )}
                    unCheckedChildren={t(
                      'userManagement.blockApp.add.list.normal'
                    )}
                  />,
                ]}
              >
                <div>{t('note.blockApp.adsTimer')}</div>
              </Card>
            </Col>
          ))}
        {runTimeList &&
          runTimeList.length > 0 &&
          runTimeList.map((user: any, index: number) => (
            <Col xs={24} sm={12} md={12} lg={8} xl={8} key={index}>
              <Card
                hoverable
                className="card-style"
                style={{
                  padding: '5px',
                  // background: '#045FAD',
                  borderRadius: '20px',
                  minHeight: '590px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  border: '5px solid #045FAD',
                }}
                bodyStyle={{ background: 'white' }}
                cover={
                  <div
                    style={{
                      background: 'white',
                      borderRadius: '15px 15px 0 0',
                    }}
                  >
                    <div style={gridStyle}>
                      <Avatar
                        size={{ xs: 80, md: 100, xxl: 120 }}
                        style={{
                          backgroundColor:
                            ColorList[
                              Math.floor(
                                0.5 *
                                  (user.full_name
                                    ? user.full_name.length
                                    : user.user_name.length)
                              )
                            ],
                          verticalAlign: 'middle',
                        }}
                      >
                        {user.full_name
                          ? user.full_name.charAt(0)
                          : user.user_name.charAt(0)}
                      </Avatar>
                    </div>
                    <div
                      style={{
                        ...gridStyle,
                        color: 'gray',
                        fontWeight: 'bold',
                        margin: '10px 0',
                      }}
                    >
                      {user.full_name ? user.full_name : user.user_name}
                    </div>
                    <div style={{ ...gridStyle, color: 'gray' }}>
                      {t('blockApp.list.name')}
                    </div>
                    <div style={{ ...gridStyle }}>
                      <Select
                        style={{ width: '70%' }}
                        value={user.blocklist_name}
                        onChange={(value: any) => {
                          const data = {
                            staff: user.staff_id,
                            blocklist: value,
                          };
                          createBlacklistToUser(data, 'timer');
                        }}
                      >
                        {blockapps &&
                          blockapps.map((item: any) => (
                            <Option value={item.id} key={item.id}>
                              {item.name}
                            </Option>
                          ))}
                      </Select>
                    </div>
                  </div>
                }
                actions={[
                  <Switch
                    style={{
                      transform: 'scale(1.7)',
                    }}
                    checked={user.power === 'on'}
                    onChange={(type: boolean) => {
                      handleOptionChangeTimer(type, user);
                    }}
                    checkedChildren={t('On')}
                    unCheckedChildren={t('OFF')}
                  />,
                ]}
              >
                <Row gutter={[16, 16]} align="middle">
                  <SelectDateComponent user={user} getBlockApp={getBlockApp} />
                </Row>

                {/* {user &&
                  user.timers &&
                  user.timers.length > 0 &&
                  user.timers.map((timer: any) => (
                    <Row gutter={[16, 16]} align="middle">
                      <Col span={8}>
                        {timer.status === 'on' ? t('startTime') : t('stopTime')}
                        :
                      </Col>
                      <Col span={16}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <DemoContainer components={['MobileTimePicker']}>
                            <DemoItem label="">
                              <MobileTimePicker
                                onAccept={(time: any) => {
                                  const timeString =
                                    moment(time).format('HH:mm');
                                  onChangeTime(user, timeString, timer.status);
                                }}
                                ampm={false}
                                views={['hours', 'minutes']}
                                format="HH:mm"
                                // value={moment(
                                //   `2023-01-01 ${timer.activation_time}`
                                // )}
                                defaultValue={moment(
                                  `2023-01-01 ${timer.activation_time}`
                                )}
                              />
                            </DemoItem>
                          </DemoContainer>
                        </LocalizationProvider>
                      </Col>
                    </Row>
                  ))} */}

                {/* <Row gutter={[16, 16]} align="middle">
                  <Col span={4}>Start:</Col>
                  <Col span={20}>
                    <TimePicker
                      style={{ width: '100%' }}
                      onChange={(time: Moment | null, timeString: string) => {
                        onChangeTime(time, timeString, 'start');
                      }}
                      value={moment(user.start_time, 'HH:mm:ss')}
                      defaultOpenValue={moment('00:00:00', 'HH:mm:ss')}
                    />
                  </Col>
                </Row>
                <Row gutter={[16, 16]} align="middle">
                  <Col span={4}>Stop:</Col>
                  <Col span={20}>
                    <TimePicker
                      style={{ width: '100%' }}
                      onChange={(time: Moment | null, timeString: string) => {
                        onChangeTime(time, timeString, 'stop');
                      }}
                      defaultOpenValue={moment('00:00:00', 'HH:mm:ss')}
                    />
                  </Col>
                </Row> */}
              </Card>
            </Col>
          ))}
      </Row>
    </>
  );
}
