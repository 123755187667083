export const getToday = () => {
  var d = new Date(),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
};

export const handleToDay = (number: number) => {
  var d = new Date();
  d.setDate(d.getDate() - number);

  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  let year = '' + d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  let result = [year, month, day].join('-');
  return result;
};
