import {
  AimOutlined,
  BankOutlined,
  BarChartOutlined,
  EyeInvisibleOutlined,
  GlobalOutlined,
  // AppstoreOutlined,
  LaptopOutlined,
  LockOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { t } from 'i18n';
import Application from 'modules/Application/Application';
import DetailBilling from 'modules/Billing/Detail';
import Subscription from 'modules/Billing/Index';
import BlacklistGroup from 'modules/Blacklist/BlackListGroup';
import BlockAppGroup from 'modules/Blockapp/BlockAppGroup';
import Dashboard from 'modules/Dashboard/Dashboard';
import User from 'modules/Dashboard/User/User';
import DashboardManagement from 'modules/DashboardNew/Index';
import Keywords from 'modules/Keyword';
import WhitelistGroup from 'modules/Whitelist/WhitelistGroup';
import ResultCkPay from 'modules/checkout/UI/ResultCkPay';
import DetailUser from 'modules/user/DetailUser';
import EditUser from 'modules/user/EditUser';
import { lazy } from 'react';
import Website from '../modules/website/Website';
import { PATH } from './constants';
import SafePayment from 'modules/Billing/safe';

// Home page
const Login = lazy(() => import('modules/login/Login'));
const NewPassword = lazy(() => import('modules/newPassword/NewPassword'));
const GetPassword = lazy(() => import('modules/getPassword/GetPassword'));
const AgentPage = lazy(() => import('modules/Dashboard/agentPage/AgentPage'));
const Register = lazy(() => import('modules/register/Register'));
const UserList = lazy(() => import('modules/user/User'));
const Campaign = lazy(() => import('modules/campaign/Campaigns'));
const VerifyAccount = lazy(() => import('modules/verifyAccount/VerifyAccount'));
const CampaignFormPreview = lazy(
  () => import('modules/campaign/Form/Preview/PreviewForm')
);
const CampaignFormEdit = lazy(
  () => import('modules/campaign/Form/Edit/EditForm')
);

// Blacklist
const CustomerBlacklist = lazy(
  () => import('modules/customerBlacklist/List/CustomerBlacklist')
);

const MerchantBlacklist = lazy(
  () => import('modules/merchantBlacklist/List/MerchantBlacklist')
);

const routes = [
  {
    exact: true,
    path: PATH.LOGIN,
    name: t('Login'),
    component: Login,
  },
  {
    exact: true,
    path: PATH.GET_PASSWORD,
    name: t('GetPassword'),
    component: GetPassword,
  },
  {
    exact: true,
    path: PATH.NEW_PASSWORD,
    name: t('NewPassword'),
    component: NewPassword,
  },
  {
    exact: true,
    path: PATH.USER_INFORMATION,
    name: t('Information'),
    component: DetailUser,
  },
  {
    exact: true,
    path: PATH.USER_INFORMATION_EDIT,
    name: t('InformationEdit'),
    component: EditUser,
  },
  {
    exact: true,
    path: PATH.REGISTER,
    name: t('Register'),
    component: Register,
  },
  {
    exact: true,
    path: PATH.AGENTPAGE,
    name: t('Agentpage'),
    component: AgentPage,
  },
  {
    exact: true,
    path: PATH.VERIFYACCOUNT,
    name: t('VerifyAccount'),
    component: VerifyAccount,
  },
  {
    exact: true,
    path: PATH.CAMPAIGN,
    name: t('campaign'),
    component: Campaign,
  },
  {
    exact: true,
    path: PATH.DASHBOARD_NEW,
    name: t('dashboardNew.titleMenu'),
    component: DashboardManagement,
    icon: BarChartOutlined,
  },
  {
    exact: true,
    path: PATH.DASHBOARD,
    name: t('campaign.Dashboard'),
    component: Dashboard,
    icon: LaptopOutlined,
  },
  {
    exact: true,
    path: PATH.USER.PAGE,
    name: t('userManager'),
    component: User,
    icon: UserOutlined,
  },
  {
    exact: true,
    path: PATH.BLACKLIST,
    name: t('menu.blacklist'),
    component: BlacklistGroup,
    icon: EyeInvisibleOutlined,
  },
  {
    exact: true,
    path: PATH.WHITELIST,
    name: t('menu.whitelist'),
    component: WhitelistGroup,
    icon: AimOutlined,
  },
  {
    exact: true,
    path: PATH.BLOCK_APP,
    name: t('menu.blockApp'),
    component: BlockAppGroup,
    icon: LockOutlined,
  },
  {
    exact: true,
    path: PATH.WEBSITE,
    name: t('campaign.website'),
    component: Website,
  },
  {
    exact: true,
    path: PATH.APPLICATION,
    name: t('campaign.application'),
    component: Application,
  },

  {
    exact: true,
    path: PATH.CAMPAIGN_DETAIL,
    name: t('campaign.detail'),
    component: CampaignFormPreview,
  },
  {
    exact: true,
    path: PATH.CAMPAIGNS.CREATE,
    name: t('PromotionCreate'),
    component: CampaignFormEdit,
  },
  {
    exact: true,
    path: PATH.CAMPAIGNS.EDIT,
    name: t('PromotionEdit'),
    component: CampaignFormEdit,
  },
  {
    exact: true,
    path: '/merchant-blacklist',
    name: t('MerchantBlacklist'),
    component: MerchantBlacklist,
  },
  {
    exact: true,
    path: '/customer-blacklist',
    name: t('CustomerBlacklist'),
    component: CustomerBlacklist,
  },
  {
    exact: true,
    path: PATH.USER.LIST,
    name: t('user.management'),
    component: UserList,
    icon: UserOutlined,
  },
  // {
  //   exact: true,
  //   path: PATH.CHECKOUT,
  //   name: t('checkout'),
  //   component: Checkout,
  // },
  {
    exact: true,
    path: PATH.CKPAY_RETURN_URL,
    name: t('ckpayReturn'),
    component: ResultCkPay,
  },
  {
    exact: true,
    path: PATH.KEYWORDS.LIST,
    name: t('menu.keywords'),
    component: Keywords,
    icon: GlobalOutlined,
  },
  {
    exact: true,
    path: PATH.BILLING.LIST,
    name: t('menu.billing'),
    component: Subscription,
    icon: BankOutlined,
  },
  {
    exact: true,
    path: PATH.BILLING.DETAIL,
    name: t('BillingDetail'),
    component: DetailBilling,
  },
  {
    exact: true,
    path: PATH.CHECKOUT_V1_QR_CODE,
    name: t('BillingDetail'),
    component: SafePayment,
  },
];

export default routes;
