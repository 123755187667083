import { Avatar, Card, Row, Tabs } from 'antd';
import { t } from 'i18n';
import { ColorList, gridStyle } from 'modules/Whitelist/Whitelist';
import useQuery from 'modules/checkout/hook/useQueryParams';
import { getCookie } from 'pkg/cookie/helpers';
import { promotionClient } from 'pkg/request/clients';
import React, { useEffect, useState } from 'react';
import './../style.scss';
import DashboardChartColumn from './graph';
type Props = {
  tab: string;
};

export const RenderTab = ({ user, active }: any) => {
  return (
    <Card
      hoverable
      className="card-style"
      style={{
        padding: '10px 20px',
        // background: '#045FAD',
        borderRadius: '20px',
        // minHeight: '250px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        border: '5px solid #FF8F72',
        background: active == user.user_name ? '#583D72' : 'white',
      }}
      bodyStyle={{
        background: 'red',
        padding: 0,
      }}
      cover={
        <div
          style={{
            background: active == user.user_name ? '#583D72' : 'white',
            borderRadius: '15px 15px 0 0',
          }}
        >
          <div style={gridStyle}>
            <Avatar
              size={{ xs: 60, md: 80, xxl: 100 }}
              style={{
                backgroundColor:
                  ColorList[
                    Math.floor(
                      0.5 *
                        (user.full_name
                          ? user.full_name.length
                          : user.user_name.length)
                    )
                  ],
                verticalAlign: 'middle',
              }}
            >
              {user.full_name
                ? user.full_name.charAt(0)
                : user.user_name.charAt(0)}
            </Avatar>
          </div>
          <div
            style={{
              ...gridStyle,
              color: active == user.user_name ? 'white' : 'gray',
              fontWeight: 'bold',
              margin: '10px 0',
            }}
          >
            {user.full_name ? user.full_name : user.user_name}
          </div>
          <div
            style={{
              ...gridStyle,
              color: active == user.user_name ? 'white' : 'gray',
            }}
          >
            {t('dashboardNew.card.webApplication')}: {user.web_application}
          </div>
          <div
            style={{
              ...gridStyle,
              color: active == user.user_name ? 'white' : 'gray',
            }}
          >
            {t('dashboardNew.card.appApplication')}: {user.app_application}
          </div>
        </div>
      }
    ></Card>
  );
};
const ChildrenDashboard = ({ tab }: Props) => {
  const [listStaff, setListStaff] = useState<any>([]);
  const [selectChildren, setSelectChildren] = useState<any>(listStaff[0]?.id);

  useEffect(() => {
    if (tab === '1') getListStaffOfAdminId();
  }, [tab]);

  const getAdminId = () => {
    return getCookie('current_username');
  };

  const getListStaffOfAdminId = () => {
    promotionClient
      .post('/staff_user/admin/list', {
        admin_id: getAdminId(),
      })
      .then((res) => {
        setListStaff(res.data.staff_list || []);
        setSelectChildren(res.data.staff_list[0].user_name);
      });
  };
  return (
    <div className="children-tab">
      <Row gutter={[16, 32]} style={{ marginTop: 30, padding: '0 20px' }}>
        <Tabs
          defaultActiveKey={selectChildren}
          activeKey={selectChildren}
          onChange={(tab: string) => setSelectChildren(tab)}
        >
          {listStaff &&
            listStaff.length > 0 &&
            listStaff.map((user: any, index: number) => (
              <Tabs.TabPane
                tab={<RenderTab user={user} active={selectChildren} />}
                key={user.user_name}
              >
                <DashboardChartColumn userId={selectChildren} user={user} />
              </Tabs.TabPane>
            ))}
        </Tabs>
      </Row>
    </div>
  );
};

export default ChildrenDashboard;
