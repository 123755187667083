import { Col, Progress, Row, Spin } from 'antd';
import { promotionClient } from 'pkg/request/clients';
import React, { useEffect, useState } from 'react';

const ChartClickDomain = ({
  handleToDay,
  getTimeZone,
  getAdminId,
  userId,
  dateSelected,
}: any) => {
  const [dataSourceChartRequest, setDataSourceChartRequest] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getApiChartRequest(userId, dateSelected);
  }, [userId, dateSelected]);

  const getApiChartRequest = (user_id: string, dateSelected: number = 0) => {
    setLoading(true);
    promotionClient
      .post(`/url_request/admin/user_chart`, {
        admin_id: getAdminId(),
        user_id,
        sort: 'desc',
        date: handleToDay(dateSelected),
        top: 50,
        timezone: getTimeZone(),
      })
      .then((res: any) => {
        if (+res.data.total > 0) {
          const max = res.data.urls.reduce((prev: any, current: any) =>
            +prev.request_count > +current.request_count ? prev : current
          );
          const data = res.data.urls.map((item: any) => {
            item.request_count = +item.request_count;
            item.domain = item.domain;
            item.percent = (+item.request_count / +max.request_count) * 100;
            return item;
          });
          setDataSourceChartRequest(data);
        } else {
          setDataSourceChartRequest([]);
        }

        // getApiKeyword(userId, dateSelected);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const navigateUrl = (url: string) => {
    let element = document.createElement('a');

    if (url.startsWith('http://') || url.startsWith('https://')) {
      element.href = url;
    } else {
      element.href = 'https://' + url;
    }

    element.setAttribute('target', '_blank');

    element.click();
  };

  return (
    <Spin spinning={loading} tip="Loading...">
      {!!dataSourceChartRequest.length ? (
        dataSourceChartRequest.map((item: any) => {
          return (
            <div style={{ marginBottom: 20 }} key={item.domain}>
              <Row justify="space-between">
                <Col span={24}>
                  <a onClick={() => navigateUrl(item.domain)} target="_blank">
                    {item.domain}
                  </a>
                  <span
                    style={{
                      color: '#ccc',
                      fontSize: 10,
                      marginLeft: 10,
                    }}
                  >
                    {item.start_time}
                  </span>
                </Col>
              </Row>
              <Progress
                percent={item.percent}
                status="active"
                format={(percent) => `${item.request_count} click`}
                strokeWidth={12}
              />
            </div>
          );
        })
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center' }}>No data</div>
      )}
    </Spin>
  );
};

export default ChartClickDomain;
