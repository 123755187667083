import { Button, Form, notification, Select } from 'antd';
import { t } from 'i18n';
import { promotionClient } from 'pkg/request/clients';
import React, { useEffect, useState } from 'react';

const AddStaffByAgent = (props: any) => {
  const [listAgent, setListAgent] = useState([]);
  const { Option } = Select;
  const [form] = Form.useForm();
  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };
  const tailLayout = {
    wrapperCol: { offset: 0, span: 16 },
  };

  const onReset = () => {
    form.resetFields();
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  useEffect(() => {
    getListAgent();
  }, []);

  const getListAgent = () => {
    promotionClient
      .post(`/agent_access/admin/list`, {
        admin_id: props.getAdminId(),
      })
      .then((res: any) => {
        if (res.data.total) {
          setListAgent(res.data.agent_list);
          localStorage.setItem(
            'listAgent',
            JSON.stringify(res.data.agent_list)
          );
        }
      });
  };

  const AddStaffByAgent = (item: any) => {
    promotionClient
      .post('staff_agent/admin/create', {
        admin_id: props.getAdminId(),
        agent_id: item.agent_id,
        staff_id: item.staff_id,
        enable: true,
        token: '',
      })
      .then((res: any) => {
        if (res.data.success) {
          notification.success({
            message: t('Success'),
            description: t('notification.staff.create.success'),
          });
          onReset();
          props.getListStaffOfAgent();
          props.setCount(Math.random());
        } else {
          notification.error({
            message: 'Error',
            description: res.data.message,
          });
        }
      });
  };
  return (
    <>
      <Form
        {...layout}
        form={form}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={AddStaffByAgent}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label={t('AddUserToAgent_child')}
          name="staff_id"
          className="staff-item"
          rules={[
            {
              required: true,
              message: t('notification.input.select.staff'),
            },
          ]}
        >
          <Select style={{ width: '100%' }}>
            {props.listStaff.map((staff: any) => (
              <Option value={staff.user_name}>{staff.full_name}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={t('AddUserToAgent_agentList')}
          name="agent_id"
          // className='staff-item'
          rules={[
            {
              required: true,
              message: t('notification.input.select.agent'),
            },
          ]}
        >
          <Select style={{ width: '100%' }}>
            {listAgent.map((agent: any) => (
              <Option key={agent.agent_id} value={agent.agent_id}>
                {agent.agent_name || agent.agent_id}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item className="staff-item mt-10" {...tailLayout}>
          <Button type={'primary'} htmlType="submit">
            {t('AddUserToAgent_add')}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default AddStaffByAgent;
