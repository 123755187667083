import React from 'react';
import { Empty } from 'antd';
import { t } from 'i18n';

const EmptyBlock: React.FC = () => {
  return (
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('NoResult')} />
  );
};

export default EmptyBlock;
