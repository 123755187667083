import { AxiosResponse } from 'axios';
import { HTTP_CODE } from './constants';
import { AnyObject } from 'pkg/object/types';
import { denyAccess, logout } from 'pkg/user/repo';
import { notification } from 'antd';
import { updateQueryStringParameter } from 'pkg/string/helpers';
import moment from 'moment';
import { t } from 'i18n';

export const getData = (res: AxiosResponse<any>) => res.data;

const { CODE_401, CODE_403 } = HTTP_CODE;

export const handleResponseError = (error: AnyObject) => {
  const status = error && error.response && error.response.status;
  switch (status) {
    case CODE_401:
      logout();
      break;
    case CODE_403:
      denyAccess();
      notification.error({
        message: t('Error'),
        description: t('UsernameOrPasswordWrong'),
      });
      break;
    default:
      // Handle error message from API response
      let message = null;
      if (error.response && error.response.data) {
        const { data } = error.response;
        message = data.message;
      }
      notification.error({
        message: t('Error'),
        description: t(message) || t('SomethingWentWrong'),
      });
      break;
  }
};

export const getResult = (response: AnyObject) => response.data.result;

export const disableCacheRequestUrl = (path: string) => {
  return updateQueryStringParameter(path, 'timestamp', moment().unix());
};
