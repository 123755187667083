import { getData } from 'pkg/request/helper';
import {
  KEY_ACCESS_TOKEN,
  KEY_CURRENT_USER_ID,
  KEY_CURRENT_USERNAME,
  KEY_REFRESH_TOKEN,
} from 'pkg/cookie/constants';
import { deleteCookie, getCookie } from 'pkg/cookie/helpers';
import { promotionClient } from 'pkg/request/clients';
import { PATH } from 'routes/constants';
import { browserHistory } from 'pkg/route/helper';
import { IUser } from 'modules/user/types';

export const isLoggedIn = () => {
  return !!getAccessToken();
  // todo pass login
  // return false;
};

export const logout = () => {
  deleteCookie(KEY_ACCESS_TOKEN);
  deleteCookie(KEY_REFRESH_TOKEN);
  deleteCookie(KEY_CURRENT_USER_ID);
  browserHistory.push(PATH.LOGIN);
};

export const getAccessToken = () => {
  return getCookie(KEY_ACCESS_TOKEN);
};

export const getFullUserInfo: () => Promise<IUser> = async () => {
  const currentUsername = getCookie(KEY_CURRENT_USERNAME);
  const currentUserId = getCookie(KEY_CURRENT_USER_ID);
  if (currentUsername === '' && currentUserId === '') {
    return promotionClient.defaults;
  }
  // if (currentUserId !== '') {
  //   return promotionClient
  //     .get(`/user/${currentUserId}`)
  //     .then(res => getData(res));
  // }

  if (currentUsername !== '') {
    return promotionClient.get(`/user/info`).then((res) => getData(res));
  }
  return promotionClient
    .get(`/user/${currentUserId}`)
    .then((res) => getData(res));
};

export const denyAccess = () => {
  browserHistory.push('/403');
};
