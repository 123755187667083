import { getCookie, setCookie } from '../cookie/helpers';
import { REGIONS } from './constants';

export const LOCALIZATION = 'sc_localization';

export const getCurrentLanguage = () => {
  return getCookie(LOCALIZATION) || REGIONS.en.key;
};

export const changeLanguage = (newLanguage: string) => {
  setCookie(LOCALIZATION, newLanguage, 365);
  const pathName = window.location.pathname;
  if (pathName === '/signup' || pathName === '/signin') {
    const queryData = new URLSearchParams(window.location.search.slice(1));
    queryData.set('lang', newLanguage);
    const newUrl = new URL(window.location.href);
    newUrl.search = queryData as any;
    window.location.href = newUrl as any;
  } else {
    window.location.reload();
  }
};
