import {
  ChromeOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons';
import {
  Avatar,
  Button,
  Card,
  Col,
  Collapse,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Switch,
  Table,
  notification,
} from 'antd';
import { t } from 'i18n';
import { getCookie } from 'pkg/cookie/helpers';
import { promotionClient } from 'pkg/request/clients';
import React, { useEffect, useRef, useState } from 'react';
import '../Blacklist/Blacklist.scss';
import CreateWhitelist from './CreateWhitelist';

let originData: any[] = [];

function randomIntFromInterval(min: number, max: number) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}
export const ColorList = ['#f56a00', '#7265e6', '#7B68EE', '#00a2ae'];
export const gridStyle: React.CSSProperties = {
  textAlign: 'center',
  width: '100%',
};
const randomListWebsite = () => {
  let numberRamdom = randomIntFromInterval(0, 3);
  const arr = [
    `https://baophapluat.vn/`,
    'https://facebook.com/',
    'https://zalo.com/',
    'https://safeweb.app/',
  ];
  return arr[numberRamdom];
};

for (let i = 0; i < 3; i++) {
  originData.push({
    key: i.toString(),
    name: randomListWebsite(),
  });
}

export default function Whitelist(props: any) {
  const [loadingStaffActive, setLoadingStaffActive] = useState(false);
  const { Option } = Select;
  const [form] = Form.useForm();

  useEffect(() => {
    getWhitelist();
  }, []);

  useEffect(() => {
    getBlacklistApiOfStaff();
  }, []);
  const [data, setData] = useState([]);
  const [dataOffUser, setDataOffUser] = useState([]);

  const AddBlacklistGroup = (values: any) => {
    const nameValue = values.name.toLowerCase();
    promotionClient
      .post('/web_rule/whitelist/admin/create', {
        admin_id: getAdminId(),
        name: nameValue,
        list: [],
      })
      .then((res: any) => {
        if (res.data.success) {
          notification.success({
            message: t('Success'),
            description: t('notification.create.success', {
              type: t('whitelist'),
            }),
          });
          getWhitelist();
          onReset();
        } else {
          notification.error({
            message: 'Error',
            description: res.data.message,
          });
        }
      })
      .catch((err: any) => {
        notification.error({
          message: t('Error'),
          description: err || 'error',
        });
      });
  };

  const getWhitelist = () => {
    promotionClient
      .post('/web_rule/whitelist/admin/list', {
        admin_id: getAdminId(),
      })
      .then((res: any) => {
        if (res.data.total) {
          setData(res.data.rules);
        } else {
          notification.error({
            message: 'Error',
            description: res?.data?.message,
          });
        }
      })
      .catch((err: any) => {
        notification.error({
          message: t('Error'),
          description: err,
        });
      });
  };

  const getAdminId = () => {
    return getCookie('current_username');
  };

  const onReset = () => {
    form.resetFields();
  };

  const getBlacklistApiOfStaff = () => {
    promotionClient
      .post('/staff_web/whitelist/admin/list', {
        admin_id: getAdminId(),
      })
      .then((res: any) => {
        if (res.data.total) {
          if (res?.data?.staff_web?.length) {
            const dataTable = res.data.staff_web.map(
              (val: any, index: number) => {
                return {
                  index: index,
                  ...val,
                };
              }
            );
            setDataOffUser(dataTable);
          } else {
            setDataOffUser([]);
          }
        } else {
          notification.error({
            message: 'Error',
            description: res.data.message,
          });
        }
      })
      .catch((err: any) => {
        notification.error({
          message: t('Error'),
          description: err,
        });
      });
  };
  const handleOptionChange = (status: any, staff: any) => {
    setLoadingStaffActive(true);
    let vType = 'normal';

    switch (status) {
      case true:
        vType = 'whitelist';
        break;

      default:
        vType = 'normal';
        break;
    }

    promotionClient
      .post(`staff_web/${vType}/admin/set`, {
        staff_id: staff.staff_id,
        mode: vType,
      })
      .then((res) => {
        if (res.data.success) {
          notification.success({
            message: t('Success'),
            description: t('notification.tabMode.update.success', {
              type: vType !== 'blacklist' ? t(`${vType}`) : t('blacklistType'),
            }),
          });
          getBlacklistApiOfStaff();
        } else {
          notification.error({
            message: 'Error',
            description: res.data.message,
          });
        }
      })
      .catch((err: any) => {
        notification.error({
          message: t('Error'),
          description: err,
        });
      })
      .finally(() => setLoadingStaffActive(false));
  };

  const createBlacklistToUser = (staff_id: any, rule_id: any) => {
    promotionClient
      .post('staff_web/whitelist/admin/add', {
        staff_id,
        rule_id,
      })
      .then((res: any) => {
        if (res.data.success) {
          notification.success({
            message: t('Success'),
            description: t('notification.whitelist.apply.success'),
          });
          getBlacklistApiOfStaff();
        } else {
          notification.error({
            message: 'Error',
            description: res.data.message,
          });
        }
      })
      .catch((err: any) => {
        notification.error({
          message: t('Error'),
          description: err,
        });
      });
  };
  return (
    <>
      <div>
        <CreateWhitelist AddBlacklistGroup={AddBlacklistGroup} />
        {data &&
          data.map((item: any, index: any) => (
            <WhitelistDatail getApi={getWhitelist} item={item} key={index} />
          ))}
      </div>
      <Row gutter={[16, 32]} style={{ marginTop: 30 }}>
        {dataOffUser &&
          dataOffUser.length > 0 &&
          dataOffUser.map((user: any, index: number) => (
            <Col xs={24} sm={12} md={6} key={index}>
              <Card
                hoverable
                className="card-style"
                style={{
                  padding: '5px',
                  // background: '#045FAD',
                  borderRadius: '20px',
                  minHeight: '400px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  border: '5px solid #045FAD',
                }}
                bodyStyle={{ background: 'white', padding: 0 }}
                cover={
                  <div
                    style={{
                      background: 'white',
                      borderRadius: '15px 15px 0 0',
                    }}
                  >
                    <div style={gridStyle}>
                      <Avatar
                        size={{ xs: 80, md: 100, xxl: 120 }}
                        style={{
                          backgroundColor:
                            ColorList[
                              Math.floor(
                                0.5 *
                                  (user.full_name
                                    ? user.full_name.length
                                    : user.user_name.length)
                              )
                            ],
                          verticalAlign: 'middle',
                        }}
                      >
                        {user.full_name
                          ? user.full_name.charAt(0)
                          : user.user_name.charAt(0)}
                      </Avatar>
                    </div>
                    <div
                      style={{
                        ...gridStyle,
                        color: 'gray',
                        fontWeight: 'bold',
                        margin: '10px 0',
                      }}
                    >
                      {user.full_name ? user.full_name : user.user_name}
                    </div>
                    <div style={{ ...gridStyle, color: 'gray' }}>
                      {t('blockApp.list.name')}
                    </div>
                    <div style={{ ...gridStyle }}>
                      <Select
                        style={{ width: '70%' }}
                        value={user.whitelist_name}
                        onChange={(value: any) => {
                          if (value)
                            createBlacklistToUser(user.staff_id, value);
                        }}
                      >
                        {!user.whitelist_name && (
                          <Option value="" disabled>
                            <span style={{ color: 'red', fontWeight: 'bold' }}>
                              -- Select rule --
                            </span>
                          </Option>
                        )}
                        {data &&
                          data.map((item: any) => (
                            <Option value={item.id} key={item.id}>
                              {item.name}
                            </Option>
                          ))}
                      </Select>
                    </div>
                  </div>
                }
                actions={[
                  <Switch
                    loading={loadingStaffActive}
                    style={{
                      transform: 'scale(1.7)',
                    }}
                    checked={user.run_mode === 'whitelist'}
                    onChange={(type: boolean) => {
                      handleOptionChange(type, user);
                    }}
                    checkedChildren={t(
                      'userManagement.whiteList.add.list.allowWebCart'
                    )}
                    unCheckedChildren={t(
                      'userManagement.blockApp.add.list.normal'
                    )}
                  />,
                ]}
              >
                {/* <div>{t('note.blockApp.adsTimer')}</div> */}
              </Card>
            </Col>
          ))}
      </Row>
    </>
  );
}

function WhitelistDatail(props: any) {
  const [dataWhiteList, setDataWhiteList] = useState(originData);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingKey, setEditingKey] = useState('');
  useEffect(() => {
    convertArr();
  }, []);
  const convertArr = () => {
    let array = [];
    for (let i = 0; i < props.item.list.length; i++) {
      array.push({
        key: i,
        name: props.item.list[i],
      });
    }
    setDataWhiteList(array);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const isEditing = (record: any) => record.key === editingKey;

  const [form] = Form.useForm();
  const { Panel } = Collapse;
  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleDelete = (record: any) => {
    let findArray = dataWhiteList.filter((item: any) => {
      return item.key !== record.key;
    });
    let updateData = findArray.map((item: any, index) => {
      item.key = index;
      return item;
    });

    let arrString: any = [];
    arrString = updateData.map((item: any) => item.name);

    setDataWhiteList(updateData);

    promotionClient
      .post('/web_rule/whitelist/admin/edit', {
        id: props.item.id,
        list: arrString,
      })
      .then((res: any) => {
        if (res.data.success) {
          props.getApi();
          notification.success({
            message: t('Success'),
            description: t('notification.whitelist.delete.success'),
          });
        } else {
          notification.error({
            message: 'Error',
            description: res.data.message,
          });
        }
      })
      .catch((err: any) => {
        notification.error({
          message: t('Error'),
          description: err || 'error',
        });
      });
  };

  const EditableCell = (props: any) => {
    const [errorMessage, setErrorMessage] = useState('');
    const combineDomainUrl = (eventChange: any) => {
      const value = eventChange.target.value;
      const domainRegex =
        /(?:https?:\/\/)?(?:www\.)?([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})/;
      const match = value.match(domainRegex);
      if (match) {
        console.log('match', match);
        setErrorMessage('');
        props.data.forEach((element: any) => {
          if (element.name === value) {
            console.log('zzoooo');
            setErrorMessage(t('duplicate'));
            return;
          }
        });
      } else {
        console.log('no match', match);
        setErrorMessage(t('url.incorrect'));
      }
    };
    return (
      <td key={props.key} {...props}>
        {props.editing ? (
          <>
            <Form.Item
              name={props.dataIndex}
              style={{
                margin: 0,
              }}
              rules={[
                {
                  required: true,
                  message: `${t('notification.input.please', {
                    name: props.title,
                  })}`,
                },
              ]}
            >
              <Input onChange={combineDomainUrl} />
            </Form.Item>
            <span style={{ color: 'red' }}>{errorMessage}</span>
          </>
        ) : (
          props.children
        )}
      </td>
    );
  };
  const columns = [
    {
      title: `${t('userManagement.blockApp.add.list.stt')}`,
      dataIndex: 'id',
      key: 'id',
      width: 45,
      render: (value: any, staff: any, index: any) => <span>{index + 1}</span>,
    },
    {
      title: `${t('userManagement.whiteList.createWebsite.name')}`,
      dataIndex: 'name',
      editable: true,
      render: (value: any, staff: any, index: any) => {
        const domainRegex =
          /(?:https?:\/\/)?(?:www\.)?([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})/;
        const match = value.match(domainRegex);
        return (
          <div>
            {value}
            {!match && <div style={{ color: 'red' }}>{t('url.incorrect')}</div>}
            {staff.isDuplicate && (
              <div style={{ color: 'red' }}>{t('duplicate')}</div>
            )}
          </div>
        );
      },
      sorter: (a: any, b: any) => a.name.length - b.name.length,
    },
    {
      title: `${t('userManagement.whiteList.createWebsite.action')}`,
      dataIndex: 'operation',
      width: '25%',
      render: (value: any, record: { key: any }) => {
        const editable = isEditing(record);
        return (
          <div>
            {editable ? (
              <span>
                <Button
                  type="primary"
                  ghost
                  href="javascript:;"
                  onClick={() => save(record, 'edit')}
                  style={{
                    marginRight: '10px',
                  }}
                >
                  {t('userManagement.whiteList.createWebsite.btnSave')}
                </Button>
                <Button
                  href="javascript:;"
                  onClick={cancel}
                  style={{
                    marginRight: '10px',
                    marginTop: '10px',
                  }}
                >
                  {t('userManagement.whiteList.createWebsite.btnCancel')}
                </Button>
                {/* <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                        <a>Cancel</a>
                      </Popconfirm> */}
              </span>
            ) : (
              <Button
                disabled={editingKey !== ''}
                onClick={() => edit(record)}
                style={{
                  marginRight: '10px',
                }}
              >
                {t('userManagement.whiteList.createWebsite.btnEdit')}
              </Button>
            )}

            <Button
              disabled={false}
              style={{ marginTop: '10px' }}
              onClick={() => handleDelete(record)}
              danger
            >
              {t('userManagement.whiteList.createWebsite.btnDelete')}
            </Button>
          </div>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col: any) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: any) => ({
        record,
        inputType: col.dataIndex === 'time' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        data: dataWhiteList,
      }),
    };
  });
  const EditBlackList = (value: any) => {
    promotionClient
      .post('/web_rule/whitelist/admin/set_name', {
        id: props.item.id,
        name: value.blacklistName,
      })
      .then((res: any) => {
        if (res.data.success) {
          notification.success({
            message: t('Success'),
            description: t('notification.update.success', {
              type: t('whitelist'),
            }),
          });
          props.getApi();
          setIsModalVisible(false);
        } else {
          notification.error({
            message: t('Error'),
            description: res.message,
          });
        }
      })
      .catch((err: any) => {
        notification.error({
          message: t('Error'),
          description: err || 'error',
        });
      });
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const isAdd = useRef(false);
  const save = async (record: any, isDelete: string) => {
    let arrString: any = [];
    try {
      const row = await form.validateFields();
      const newData: any = [...dataWhiteList];
      const index = newData.findIndex((item: any) => record.key === item.key);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setDataWhiteList(newData);
        setEditingKey('');

        arrString = newData.map((item: any) => item.name);

        promotionClient
          .post('/web_rule/whitelist/admin/edit', {
            id: props.item.id,
            list: arrString,
          })
          .then((res: any) => {
            if (res.data.success) {
              props.getApi();
              notification.success({
                message: t('Success'),
                description: isAdd.current
                  ? t('notification.whitelist.addApp.success')
                  : t('notification.whitelist.updateApp.success'),
              });
            } else {
              notification.error({
                message: 'Error',
                description: res.data.message,
              });
            }
          })
          .catch((err: any) => {
            notification.error({
              message: t('Error'),
              description: err || 'error',
            });
          })
          .finally(() => {
            isAdd.current = false;
          });
      } else {
        newData.push(row);
        setDataWhiteList(newData);
        setEditingKey('');
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const cancel = () => {
    setEditingKey('');
  };

  const edit = (record: any) => {
    form.setFieldsValue({
      name: '',
      ...record,
    });
    setEditingKey(record.key);
  };
  const handleAdd = () => {
    isAdd.current = true;
    const newData = {
      key: dataWhiteList.length + 1 || 1,
      name: `example.com`,
    };
    let arr = [newData, ...dataWhiteList];
    setDataWhiteList(arr);
    edit(newData);
  };
  const handleDeleteUser = () => {
    promotionClient
      .post('/web_rule/whitelist/admin/delete', {
        id: props.item.id,
      })
      .then((res: any) => {
        if (res.data.success) {
          notification.success({
            message: t('Success'),
            description: t('notification.delete.success', {
              type: 'whitelist',
            }),
          });
          props.getApi();
        } else {
          notification.error({
            message: t('Error'),
            description: res.message,
          });
        }
      })
      .catch((err: any) => {
        notification.error({
          message: t('Error'),
          description: err || 'error',
        });
      });
  };
  const mapData: any = (data: any) => {
    const output = data.map((item: any, index: any, arr: any) => {
      const isDuplicate = arr.some(
        (obj: any, i: any) => obj.name === item.name && i !== index
      );
      return { ...item, isDuplicate };
    });

    return output;
  };
  return (
    <>
      <div style={{ marginTop: '10px' }} className={'blacklist-detail-main'}>
        <div className={'blacklist-detail-main-ab'}>
          <Popconfirm
            placement="topRight"
            title={t('notification.delete.title', { type: t('whitelist') })}
            onConfirm={() => handleDeleteUser()}
            okText={t('notification.delete.yes')}
            cancelText={t('notification.delete.no')}
          >
            <DeleteOutlined style={{ color: 'red', fontSize: 25 }} />
          </Popconfirm>
        </div>
        <div className={'blacklist-detail-edit'}>
          <EditOutlined
            onClick={() => showModal()}
            style={{ fontSize: '150%' }}
          />
        </div>
        <Collapse
          defaultActiveKey={'0'}
          accordion
          expandIcon={({ isActive }) => (
            <ChromeOutlined
              twoToneColor="#52c41a"
              style={{ fontSize: '120%' }}
              rotate={isActive ? 130 : 0}
            />
          )}
        >
          <Panel header={props.item.name} key="1">
            <Form form={form} className={'mt-3'} component={false}>
              <Button
                onClick={handleAdd}
                type="primary"
                style={{
                  marginBottom: 16,
                }}
              >
                {t('userManagement.whiteList.createWebsite.title')}
              </Button>
              <Table
                scroll={{ x: 600 }}
                components={{
                  body: {
                    cell: EditableCell,
                  },
                }}
                bordered
                dataSource={mapData(dataWhiteList)}
                columns={mergedColumns}
                rowClassName="editable-row"
                pagination={{
                  onChange: cancel,
                }}
              />
            </Form>
          </Panel>
        </Collapse>

        <Modal
          title={t('userManagement.whiteList.update.name')}
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={null}
        >
          <Form
            form={form}
            name="basic"
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 14 }}
            initialValues={props.item.name}
            onFinish={(value: any) => EditBlackList(value)}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label={t('userManagement.whiteList.update.label')}
              name="blacklistName"
              rules={[
                {
                  required: true,
                  message: t('notification.input.blacklist.nameEdit', {
                    name: 'whitelist',
                  }),
                },
              ]}
            >
              <Input value={props.item.name} />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 6, span: 18 }}>
              <Button type="primary" className="mt-1" htmlType="submit">
                {t('userManagement.whiteList.update.btnUpdate')}
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </>
  );
}
